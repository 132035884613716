import { Button, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { required, TextInput, useInput, useTranslate } from 'react-admin';
import { PartsInputProps, SinglePart } from '../../types';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(0.5),
    height: '55px',
    borderWidth: '1px',
    borderColor: theme.palette.primary.dark,
    borderStyle: 'dashed',
  },
  removeButton: {
    width: '10%',
  },
  row: {
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  contact: {
    width: '90%',
  },
  number: {
    width: '60%',
  },
  description: {
    width: '90%',
    paddingBottom: theme.spacing(1),
  },
  quantity: {
    width: '20%',
  },
}));

const partDefault = {
  number: '',
  description: '',
  quantity: 1,
};

export const PartsInput = ({
  source,
  onChange: propsOnChange,
}: PartsInputProps) => {
  const {
    input: { onChange, value },
  } = useInput({ source, onChange: propsOnChange });

  const classes = useStyles();
  const translate = useTranslate();

  const [parts, setParts] = useState<SinglePart[]>([partDefault]);

  useEffect(() => {
    setParts(value.parts ?? [partDefault]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setPartByIndex = useCallback(
    ({
      number,
      description,
      quantity,
      index,
    }: {
      number: string;
      description: string;
      quantity: number;
      index: number;
    }) => {
      const newPart: SinglePart = {
        number,
        description,
        quantity,
      };
      const allParts = [...parts];
      allParts[index] = newPart;
      setParts(allParts);
    },
    [parts],
  );

  useEffect(() => {
    onChange({ ...value, parts });
  }, [parts]); // eslint-disable-line react-hooks/exhaustive-deps

  const addPart = useCallback(() => {
    const newParts = [...parts];
    newParts.push(partDefault);
    setParts(newParts);
  }, [parts, setParts]);

  useEffect(() => {
    if (!parts || parts.length === 0) {
      addPart();
    }
  }, [parts, addPart]);

  const deletePart = (index: number) => {
    const newParts = [...parts];
    newParts.splice(index, 1);
    setParts(newParts);
  };

  const handleNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { description, quantity } = parts[index];
    setPartByIndex({
      number: event.target.value as string,
      description,
      quantity,
      index,
    });
  };

  const handleDescriptionChange = (
    event: ChangeEvent<{ value: unknown }>,
    index: number,
  ) => {
    const { number, quantity } = parts[index];
    setPartByIndex({
      number,
      description: event.target.value as string,
      quantity,
      index,
    });
  };

  const handleQuantityChange = (
    event: ChangeEvent<{ value: unknown }>,
    index: number,
  ) => {
    const { number, description } = parts[index];
    setPartByIndex({
      number,
      description,
      quantity: event.target.value as number,
      index,
    });
  };

  const isRequired = required();
  return (
    <div className={classes.column}>
      {parts?.map(
        ({ number, description, quantity }: SinglePart, index: number) => {
          return (
            <div className={classes.column} key={index}>
              <div className={classes.row}>
                <Button
                  className={`${classes.removeButton} ${classes.button}`}
                  onClick={() => deletePart(index)}
                >
                  <CloseIcon />
                </Button>
                <TextInput
                  source={`${source}.parts[${index}].number`}
                  label={translate('parts.number')}
                  className={classes.number}
                  variant="outlined"
                  value={number}
                  validate={isRequired}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleNumberChange(event, index)
                  }
                />
                <TextInput
                  source={`${source}.parts[${index}].quantity`}
                  type="number"
                  variant="outlined"
                  label={translate('parts.quantity')}
                  className={classes.quantity}
                  value={quantity ?? 1}
                  validate={isRequired}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleQuantityChange(event, index)
                  }
                />
              </div>
              <TextInput
                source={`${source}.parts[${index}].description`}
                label={translate('parts.description')}
                className={classes.description}
                variant="outlined"
                value={description}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleDescriptionChange(event, index)
                }
              />
            </div>
          );
        },
      )}

      <div>
        <Button className={classes.button} onClick={addPart}>
          <AddIcon />
          <Typography>{translate('parts.addPart')}</Typography>
        </Button>
      </div>
    </div>
  );
};
