import {
  Collapse,
  Divider,
  List,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as React from 'react';
import { FC, Fragment, ReactElement } from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '14px',
    margin: '12px 12px',
    padding: '12px 16px',
    borderRadius: '4px',
    transition: '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transitionProperty: 'color, background-color',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.primary.contrastText} !important`,
    '& > .MuiListItemIcon-root': {
      fill: `${theme.palette.primary.contrastText} !important`,
    },
  },
  icon: {
    color: theme.palette.text.primary,
    minWidth: theme.spacing(5),
    transition: '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transitionProperty: 'color, background-color',
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
}));

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  sidebarIsOpen: boolean;
}

export const SubMenu: FC<Props> = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  const header = (
    <MenuItem
      className={classNames(classes.button, { [classes.active]: isOpen })}
      dense={dense}
      button
      onClick={handleToggle}
    >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="inherit">{translate(name)}</Typography>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </Fragment>
  );
};
