import React from 'react';
import { Edit, TextInput, SimpleForm, required, EditProps } from 'react-admin';

export function QRCodeEdit({ id, basePath, resource }: EditProps) {
  return (
    <Edit basePath={basePath} resource={resource} id={id}>
      <SimpleForm>
        <TextInput source="serial" validate={required()} />
        <TextInput source="category" validate={required()} />
        <TextInput source="build_year" type={'number'} validate={required()} />
        <TextInput source="model" validate={required()} />
      </SimpleForm>
    </Edit>
  );
}
