import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import { SubmitTriggerContext } from './create';
import { get } from 'lodash';


const useStyles = makeStyles((theme) => ({
    anchor: {
        position: 'relative',
        top: -30,
      },
      error: {},
    }));

export const ScrollOnError = (props: { source: string }) => {
    const { source } = props;
    const classes = useStyles();
    const { errors, touched } = useFormState();
    const anchorRef = useRef(null);
    const { trigger } = useContext(SubmitTriggerContext);
  
    const isScrollTarget = useMemo(() => {
      for (const key in touched) {
        if (key === 'spec') continue;
        if (touched[key] && get(errors, key, null)) {
          if (key.startsWith(source)) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    }, [errors, source, touched]);
  
    useEffect(() => {
      if (isScrollTarget) {
        anchorRef.current?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);
  
    return <div className={classes.anchor} ref={anchorRef} />;
  };
  