import React from 'react';
import {
  Create,
  FormTab,
  required,
  TextInput,
  useTranslate,
  TabbedForm,
  useNotify,
  useRefresh,
  useRedirect,
} from 'react-admin';


export function GroupCreate() {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(
      'ra.notification.created',
      {
          type: 'info',
          messageArgs: { smart_count: 1 },
          undoable: true,
      }
    );
    redirect('/inspections/groups');
    refresh();
  };

  return (
    <Create
      basePath={'/inspections/groups'}
      resource={'groups'}
      onSuccess={onSuccess}
    >
      <TabbedForm>
        <FormTab
          label={translate('resources.groups.group')}
        >
          <TextInput source="serial" validate={required()} />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
