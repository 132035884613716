import { CardContent, CardMedia } from '@material-ui/core';
import React from 'react';
import { useQuery, useTranslate } from 'react-admin';
import { DataObject, DetailObject } from '../../types.inspections';
import { getInspectionMainImage, usersQuery } from '../../utils';
import { Assignee } from './assignee';
import { SingleDetail } from './single-detail';
import { createAssigneeEmails, inspectionKeys } from './utils';

export const Details = ({
  record,
  record: { assignee, inspection_details, updatedAt, createdBy },
}: {
  record: DataObject;
}) => {
  const translate = useTranslate();
  const { data: users } = useQuery(usersQuery);

  const assigneeEmails = createAssigneeEmails(assignee ?? [createdBy], users);

  return (
    <div className="media-data-wrapper">
      <div className="media-container">
        <CardMedia
          className="card-media-picture"
          image={getInspectionMainImage(record)}
        />
      </div>
      <div className="data-container">
        <CardContent>
          {inspectionKeys?.map((key: string) => (
            <SingleDetail
              label={translate(`resources.inspections.fields.${key}`)}
              value={(record as unknown as { [key: string]: string })[key]}
              key={key}
            />
          ))}
          <Assignee assigneeEmails={assigneeEmails} />
          {inspection_details?.map(({ label, value }: DetailObject) => (
            <SingleDetail label={label} value={value} key={label} />
          ))}
          <SingleDetail
            label={translate('resources.inspections.fields.updatedAt')}
            value={updatedAt.split('T')[0]}
          />
        </CardContent>
      </div>
    </div>
  );
};
