import { Button, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { required, TextInput, useInput, useTranslate } from 'react-admin';
import { CheckboxesInputProps } from '../../types';

const checkboxesDefault = '';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(0.5),
    height: '55px',
    borderWidth: '1px',
    borderColor: theme.palette.primary.dark,
    borderStyle: 'dashed',
  },
  addButton: {
    width: '30%',
  },
  removeButton: {
    width: '10%',
  },
  row: {
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  value: {
    width: '80%',
  },
}));

export const CheckboxesInput = ({ source }: CheckboxesInputProps) => {
  const classes = useStyles();
  const {
    input: { onChange, value },
  } = useInput({ source });

  const translate = useTranslate();
  const [checkboxes, setCheckboxes] = useState<string[]>([checkboxesDefault]);

  useEffect(() => {
    setCheckboxes(value.checkboxes ?? [checkboxesDefault]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setCheckboxByIndex = useCallback(
    ({ value, index }: { value: string; index: number }) => {
      const allCheckboxes = [...checkboxes];
      allCheckboxes[index] = value;
      setCheckboxes(allCheckboxes);
    },
    [checkboxes],
  );

  useEffect(() => {
    onChange({ ...value, checkboxes });
  }, [checkboxes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checkboxes || checkboxes.length === 0) {
      addCheckbox();
    }
  }, [checkboxes]); // eslint-disable-line react-hooks/exhaustive-deps

  const addCheckbox = () => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes.push(checkboxesDefault);
    setCheckboxes(newCheckboxes);
  };

  const deleteCheckbox = (index: number) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes.splice(index, 1);
    setCheckboxes(newCheckboxes);
  };

  const handleValueChange = (
    event: ChangeEvent<{ value: unknown }>,
    index: number,
  ) => {
    setCheckboxByIndex({ value: event.target.value as string, index });
  };

  const isRequired = required();
  return (
    <div className={classes.column}>
      {checkboxes?.map((value: string, index: number) => {
        return (
          <div className={classes.row}>
            <Button
              className={`${classes.removeButton} ${classes.button}`}
              onClick={() => deleteCheckbox(index)}
            >
              <CloseIcon />
            </Button>
            <TextInput
              source={`${source}.checkboxes[${index}]`}
              label={translate('checkboxes.value')}
              className={classes.value}
              variant="outlined"
              defaultValue={value}
              value={value}
              validate={isRequired}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleValueChange(event, index)
              }
            />
          </div>
        );
      })}

      <Button
        className={`${classes.addButton} ${classes.button}`}
        onClick={addCheckbox}
      >
        <AddIcon />
        <Typography>{translate('checkboxes.addCheckbox')}</Typography>
      </Button>
    </div>
  );
};
