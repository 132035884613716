import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { PartsInputTypesEnum } from '../../types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface PartsListSelectProps {
  onChange: (newPartsColNum: { [key: string]: number }) => any;
  cols: string[];
}

export const PartsListSelect = ({ onChange, cols }: PartsListSelectProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [partsColNum, setPartsColNum] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    onChange({});
    return () => {
      onChange({});
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const types = Object.entries(PartsInputTypesEnum)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ id: value, name: translate(`parts.${value}`) }));

  const handleChangePartCol = (
    event: ChangeEvent<{ value: any }>,
    typeId: string,
  ) => {
    let currentPartsColNum = { ...partsColNum };
    if (event.target.value === -1) {
      delete currentPartsColNum[typeId];
    } else {
      currentPartsColNum[typeId] = event.target.value;
    }
    onChange(currentPartsColNum);
    setPartsColNum(currentPartsColNum);
  };

  let key = 0;

  return (
    <div>
      {types.map(({ id, name }) => (
        <FormControl className={classes.formControl} key={key++}>
          <InputLabel id={`populate-parts-list-${id}`}>{name}</InputLabel>
          <Select
            defaultValue=""
            labelId={`populate-parts-list-${id}`}
            id={`populate-parts-list-select-${id}`}
            value={partsColNum[id] ?? -1}
            onChange={(event) => handleChangePartCol(event, id)}
          >
            {cols.map((col) => (
              <MenuItem
                key={cols.indexOf(col).toString()}
                value={cols.indexOf(col)}
              >
                {col}
              </MenuItem>
            ))}
            <MenuItem key="parts_type_undefined" value={-1}>
              {translate(`parts.empty`)}
            </MenuItem>
          </Select>
        </FormControl>
      ))}
    </div>
  );
};
