import React from 'react';
import { useTranslate } from 'react-admin';
import ReactPlayer from 'react-player/lazy';
import { INSPECTION_SERVER_URL } from '../../../../../config';

interface SingleMediaProps {
  reference: string;
  id: number;
  isVideo?: boolean;
}

export const SingleMedia = ({ reference, id, isVideo }: SingleMediaProps) => {
  const translate = useTranslate();
  const newRef = reference.split('.')[0];
  const extension = isVideo ? '.mp4' : '.jpg';
  const url = `${INSPECTION_SERVER_URL}${id}/${newRef}${extension}`;

  return (
    <div className="audit-gallery-container">
      {isVideo ? (
        <ReactPlayer url={url} controls width="500px" />
      ) : (
        <img
          key={reference}
          src={url}
          alt={reference || translate('specification.nodata')}
          className="audit-gallery-fotos"
        />
      )}
    </div>
  );
};
