import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  dialogButton: {
    width: '100%',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '13px',
    marginTop: '8px',
    marginBotton: '8px',
    '&:hover': {
      background: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  dialogLabel: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '8px',
  },
}));

interface SharedDialogButtonProps {
  onClick: () => void;
  icon: React.ReactElement;
  label: string;
  disabled?: boolean;
}

export function SharedDialogButton({
  icon,
  label,
  disabled,
  onClick,
}: SharedDialogButtonProps) {
  const classes = useStyles();

  return (
    <Button
      className={classes.dialogButton}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
      <span className={classes.dialogLabel}>{label}</span>
    </Button>
  );
}
