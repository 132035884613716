import { Card, makeStyles, ThemeProvider } from '@material-ui/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { LoginComponent, useRedirect } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { useNotify, userLogin, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import {
  changePassword,
  getRoleFromUser,
  login,
  LoginParams,
  logout,
  setDepartment,
} from './auth';
import { ChangePasswordForm } from './change-password-form';
import { LoginForm } from './login-form';
import { SelectDepartmentForm } from './select-department-form';
import { ResendPasswordForm } from './resend-password-form';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: 'url(img/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(8),
  },
  card: {
    overflow: 'visible',
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[3],
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(-4, -1, 4),
    padding: theme.spacing(2),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}));

type StepType =
  | 'login'
  | 'change_password'
  | 'resend_password'
  | 'select_department';

export const Login: LoginComponent = ({ theme }) => {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [step, setStep] = useState<StepType>('login');
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const redirect = useRedirect();

  const dispatch = useDispatch();
  const dispatchLogin = (user: CognitoUser | { error: any }) =>
    dispatch(userLogin(user, ''));

  useEffect(() => {
    const checkUserandRedirect = async () => {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      if (user) {
        const role = getRoleFromUser(user);
        if (role !== 'role.dealeruser') {
          setUser(user);
          setStep('select_department');
        }
      }
    };
    checkUserandRedirect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePassword = async ({ password }: { password: string }) => {
    setLoading(true);
    try {
      const result = await changePassword({ user, password });
      dispatchLogin(result);
    } catch (error) {
      notify(`${translate('auth.reset_password_error')}: ${error}`, 'warning');
    }
    setLoading(false);
  };

  const handleLogin = async (auth: LoginParams | CognitoUser) => {
    setLoading(true);
    try {
      const user = await login(auth);
      if (getRoleFromUser(user) === 'role.dealeruser') {
        notify('Permission Denied', 'warning');
      } else if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(user);
        setStep('change_password');
      } else {
        dispatchLogin(user);
      }
    } catch (error) {
      dispatchLogin({ error });
    }
    setLoading(false);
  };

  const handleSelect = async (department: { id: number | '' }) => {
    await setDepartment(department);
    redirect('inspection-templates', '');
  };

  const handleLogout = async () => {
    await logout();
    setStep('login');
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <Card className={classes.card}>
          {step === 'login' ? (
            <>
              <LoginForm
                login={handleLogin}
                isLoading={isLoading}
                onGoToResendPassword={() => setStep('resend_password')}
              />
            </>
          ) : step === 'change_password' ? (
            <ChangePasswordForm changePassword={handleChangePassword} />
          ) : step === 'resend_password' ? (
            <ResendPasswordForm onCancel={() => setStep('login')} />
          ) : (
            <SelectDepartmentForm select={handleSelect} logout={handleLogout} />
          )}
        </Card>
      </div>
    </ThemeProvider>
  );
};
