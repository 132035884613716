import TreeItem from '@material-ui/lab/TreeItem';
import * as React from 'react';
import { SpecificationInt } from '../../inspections/types.inspections';
import { ConfigShow } from './config';
import { TitleText } from './title-text';

interface SpecsShowProps {
  specs: SpecificationInt[];
  id: number;
}

export const SpecsShow = ({ specs, id }: SpecsShowProps) => (
  <>
    {specs.map(
      ({
        task_id,
        label,
        spec,
        helper_text,
        type,
        config,
      }: SpecificationInt) => (
        <TreeItem nodeId={String(task_id)} label={String(label)}>
          {spec ? (
            <SpecsShow specs={spec} id={id} />
          ) : (
            <TitleText
              id={id}
              type={type}
              helperText={helper_text}
              helperType={config?.helperType}
              helperImages={config?.helperImages}
            >
              <ConfigShow id={id} type={type} config={config} />
            </TitleText>
          )}
        </TreeItem>
      ),
    )}
  </>
);
