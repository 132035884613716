import { Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslate } from 'react-admin';
import { ItemShowProps } from './types';

export const StatusShow = ({ config }: ItemShowProps) => {
  const translate = useTranslate();
  const { status } = config ?? {};
  return (
    <>
      {status?.map((statusNote) => {
        const [infoType, value] = Object.entries(statusNote)[0];
        return (
          <Typography variant="subtitle2">
            {'\u00A0 \u00A0'}- {translate(`status.${infoType}`)}: {value}
          </Typography>
        );
      })}
    </>
  );
};
