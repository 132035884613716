import { textBoxCheckOutline as TextBoxCheckOutline } from 'img/icons';
import React, { FC } from 'react';
import { Box, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';

const useStyles = makeStyles((_) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    width: '100%',
  },
}));

interface InspectionShowSectionTitleProps {
  className?: string;
  loading: boolean;
  loaded: boolean;
  title: string;
  error?: any;
}

export const InspectionShowSectionTitle: FC<
  InspectionShowSectionTitleProps
> = ({ className, loading, loaded, error, title }) => {
  const classes = useStyles();
  return (
    <Box className={classNames(classes.container, className)}>
      <TextBoxCheckOutline fontSize="large" />
      {loading && <LinearProgress className={classes.progress} />}
      {loaded && !loading && (
        <Typography
          gutterBottom
          variant="h6"
          color="textSecondary"
          component="h6"
        >
          {title}
        </Typography>
      )}
      {error && (
        <ErrorIcon
          aria-errormessage={error.message ?? error}
          color="error"
          fontSize="small"
        />
      )}
    </Box>
  );
};
