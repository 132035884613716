// Controls the buttons like save, random, upload
export function regularButton(
  color: string,
  colorText: string,
  backgroundColor: string,
  backgroundText: string,
) {
  return {
    contained: {
      backgroundColor: color,
      color: colorText,
      border: `1px solid ${color}`,

      '&$disabled': {
        color: color,
        opacity: '0.5',
      },

      '&:hover': {
        backgroundColor: 'transparent',
        // backgroundColor: backgroundColor,
        color: backgroundText,
        border: `1px solid ${color}`,
      },
    },
  };
}

// Controls the border about the main information area
export function regularCard(color: string) {
  return {
    root: {
      // background: color,
      // border: `1px solid ${color}`,
      padding: '20px',
    },
  };
}

// Controls the background color of the sidebar
export function regularDrawer(color: string) {
  return {
    root: {
      background: color,
    },
  };
}

// Controls the text color of the default text in the forms for profile and creation
export function regularFormLabel(backgroundText: string) {
  return {
    root: {
      color: backgroundText,
    },
  };
}

// Controls the color of a lot of the SVG images eg thye tick boxes and the top left menu button
export function regularIconButton(color: string) {
  return {
    root: {
      fill: `${color} !important`,
    },
  };
}

// Controls the color of the list times in the side bar and the drop down
// Along with hover for the icon
export function regularListItem(
  color: string,
  backgroundColor: string,
  colorText: string,
  backgroundText: string,
) {
  return {
    root: {
      background: backgroundColor,
      color: `${backgroundText} !important`,
      '&:hover': {
        background: `${color} !important`,
        color: `${colorText} !important`,
        '& > .MuiListItemIcon-root': {
          fill: `${colorText} !important`,
        },
      },
    },
  };
}

// Controls the color of the Icons in the dropdown and the sidebar
export function regularListItemIcon(
  color: string,
  backgroundColor: string,
  colorText: string,
  backgroundText: string,
) {
  return {
    root: {
      fill: `${backgroundText} !important`,
    },
  };
}

// Controls the color of the main information area and the icons in the inspection
// template create drop down menu
export function regularPaper(
  color: string,
  backgroundColor: string,
  shadow: string,
) {
  return {
    root: {
      backgroundColor: backgroundColor,
    },
    elevation1: {
      boxShadow: shadow,
      '& .MuiSvgIcon-root': {
        fill: `${color}`,
        paddingRight: '10px',
      },
    },
  };
}

// Although this doesn't change the color directly, not setting it to "" breaks it
export function regularSvgIcon(color: string) {
  return {
    root: {
      fill: '',
    },
  };
}

// Controls the text and icon color of the table headings
export function regularTableSortLabel(color: string, colorText: string) {
  return {
    root: {
      color: `${colorText} !important`,
      '&:hover': {
        color: `${color} !important`,
      },
      '& > .MuiSvgIcon-root': {
        fill: `${color} !important`,
      },
    },
  };
}

// Controls the text color in the inspection reports
export function regularTypography(backgroundText: string) {
  return {
    colorTextSecondary: {
      color: `${backgroundText}`,
    },
  };
}
