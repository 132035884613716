import { Button, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React, { MouseEvent } from 'react';

const useStyles = makeStyles((theme) => ({
  imageLink: {
    borderRadius: theme.spacing(0.5),
    padding: '0',
    overflow: 'auto',
  },
  image: {
    height: '10rem',
    width: '10rem',
    objectFit: 'cover',
  },
  imageOverlay: {
    display: 'flex',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    opacity: 0,
    transition: theme.transitions.create('opacity'),

    '&:hover': {
      opacity: 1,
    },
  },
  editIcon: {
    margin: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.error.main,
  },
}));

interface ImageButtonProps {
  handleEdit: () => void;
  handleDelete: (event: MouseEvent) => void;
  alt: string;
  src?: string;
}

export const ImageButton = ({
  handleEdit,
  handleDelete,
  alt,
  src,
}: ImageButtonProps) => {
  const classes = useStyles();

  return (
    <Button component="div" className={classes.imageLink} onClick={handleEdit}>
      <img className={classes.image} alt={alt} src={src} />
      <div className={classes.imageOverlay}>
        <EditIcon className={classes.editIcon} />
        <IconButton className={classes.closeIcon} onClick={handleDelete}>
          <CloseIcon />
        </IconButton>
      </div>
    </Button>
  );
};
