import { CardContent } from '@material-ui/core';
import React from 'react';
import { useGetOne, useTranslate } from 'react-admin';
import { DataObject, SpecificationInt } from '../../types.inspections';
import { InspectionShowSectionTitle } from '../shared/section-title';
import { DataTable } from './data-table';

export const Data = ({ record }: { record: DataObject }) => {
  const translate = useTranslate();
  const {
    loaded,
    error,
    data: template,
  } = useGetOne('inspection-templates', record.inspection_template_id);
  return (
    <CardContent>
      <InspectionShowSectionTitle
        title={`${translate('inspections.quickcheck')} ${translate(
          'inspections.overview',
        )}`}
        loading={!loaded}
        loaded={loaded}
        error={error}
      />
      {loaded &&
        !error &&
        template?.spec?.map((template: SpecificationInt, index: number) => (
          <DataTable key={index} {...template} record={record} />
        ))}
    </CardContent>
  );
};
