import React, { FC } from 'react';
import {
  Datagrid,
  EditButton,
  List,
  ListProps,
  ReferenceField,
  ShowButton,
  TextField,
  useQuery,
  useTranslate,
} from 'react-admin';
import { inspectionsQuery } from '../../utils';
import { makeMachinesWithInspections } from '../utils';
import { DeleteMachineButton } from './delete-machine-button';
import { MachineFilter } from './filter';

interface MachinePropsBase {
  basePath: string;
  resource: string;
}
interface MachineListProps extends MachinePropsBase {
  class: string;
  record?: any;
  hasCreate: boolean;
}

export const MachineList: FC<MachineListProps & ListProps> = ({
  basePath,
  resource,
  className,
  hasCreate,
  record,
}) => {
  const translate = useTranslate();
  const { data: inspections } = useQuery(inspectionsQuery);
  const machinesWithInspections = makeMachinesWithInspections(inspections);

  return (
    <List
      basePath={basePath}
      resource={resource}
      bulkActionButtons={false}
      hasCreate={hasCreate}
      filters={<MachineFilter />}
    >
      <Datagrid className={className || 'defaultDataGrid'}>
        <TextField source="id" />
        <TextField source="manufacturer" />
        <TextField source="category" />
        <TextField source="model" />
        <ReferenceField
          source="inspection_template_id"
          reference="inspection-templates"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="department_id"
          label={translate('resources.departments.department')}
          reference="departments"
          emptyText={translate('resources.departments.default')}
        >
          <TextField source="name" />
        </ReferenceField>
        <ShowButton />
        <EditButton />
        <DeleteMachineButton
          machinesWithInspections={machinesWithInspections}
          record={record}
        />
      </Datagrid>
    </List>
  );
};
