import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

export const ChangePasswordForm = ({
  changePassword,
  isLoading = false,
}: {
  classes?: any;
  changePassword?: any;
  isLoading?: boolean;
}) => {
  const [password, setPassword] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changePassword({ password });
      }}
    >
      <div>
        <p>Please change your password to continue signing in.</p>
        <div>
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            margin="dense"
            disabled={isLoading}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
      </div>
      <CardActions className="button-container">
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={isLoading}
          fullWidth
        >
          {isLoading && <CircularProgress size={25} thickness={2} />}
          Change Password
        </Button>
      </CardActions>
    </form>
  );
};
