import { Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslate } from 'react-admin';
import { DamageConfig } from '../../inspections/types.inspections';
import { ItemShowProps } from './types';

export function DamagesShow({ config: { damages } }: ItemShowProps) {
  const translate = useTranslate();
  const { maxDamages, type_options } = damages as DamageConfig;
  return (
    <>
      <Typography variant="body2">
        {'\u00A0 \u00A0'}
        {translate('damages.max_damages')}: {maxDamages}
      </Typography>
      {type_options && (
        <Typography variant="body2">
          {'\u00A0 \u00A0'}
          {translate('damages.type_options')}:
          {type_options.map((option: string) => (
            <Typography variant="body2">
              {'\u00A0 \u00A0 \u00A0'} - {option}
            </Typography>
          ))}
        </Typography>
      )}
    </>
  );
}
