import React from 'react';
import { Filter, TextInput, useTranslate } from 'react-admin';

export const MachineFilter = (props: JSX.IntrinsicAttributes) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput
        label={translate('resources.machines.fields.category')}
        source="category"
      />
      <TextInput
        label={translate('resources.machines.fields.manufacturer')}
        source="manufacturer"
      />
      <TextInput
        label={translate('resources.machines.fields.model')}
        source="model"
      />
    </Filter>
  );
};
