import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
import { ItemShowProps, SinglePart } from './types';

const useStyles = makeStyles({
  partContainer: { paddingTop: '10px' },
});

export const PartsShow = ({ config }: ItemShowProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { parts = [] } = config ?? {};
  return (
    <>
      {parts.map(({ number, description, quantity }: SinglePart) => (
        <div className={classes.partContainer}>
          <Typography variant="subtitle2">
            {'\u00A0'} {translate(`parts.number`)} : {number}
          </Typography>
          <Typography variant="subtitle2">
            {'\u00A0'} {translate(`parts.quantity`)} : {quantity}
          </Typography>
          <Typography variant="subtitle2">
            {'\u00A0'} {translate(`parts.description`)} : {description}
          </Typography>
        </div>
      ))}
    </>
  );
};
