import React from 'react';
import {
  ArrayField,
  ChipField,
  FunctionField,
  Show,
  ShowProps,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useTranslate,
} from 'react-admin';


export const UserShow = (props: ShowProps) => {
  const { basePath, id, resource } = props;
  const translate = useTranslate();

  return (
    <Show
      className="machine-show-container"
      basePath={basePath}
      resource={resource}
      hasEdit={false}
      hasList={true}
      id={id}
    >
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="email" />
        <FunctionField
          label="role"
          render={(record) => translate(record['role'])}
        />
        <ArrayField source="departments">
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
