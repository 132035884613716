import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import classnames from 'classnames';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';

export function Error() {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <Card>
      <Box textAlign="center" m={1}>
        <div className={classnames('flexbox-center container-icon')}>
          <ErrorOutlineIcon />
        </div>
        <Typography variant="h4" paragraph>
          {translate('errors.title')}
        </Typography>
        <Typography variant="body1" gutterBottom paragraph>
          {translate('errors.subtitle')}
        </Typography>
        <Button variant="contained" onClick={history.goBack}>
          {translate('errors.back')}
        </Button>
      </Box>
    </Card>
  );
}
