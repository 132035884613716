import React from 'react';
import { Button, Show, ShowProps, SimpleShowLayout, TopToolbar, useDataProvider } from 'react-admin';
import { IconTextField } from '../../../components';
import { CloneButton } from '../clone-button';
import { exportInspections } from '../utils';
import { DetailsShow } from './details';

export const TemplateShow = (props: ShowProps) => {
  const dataProvider = useDataProvider();
  const { basePath, id } = props;
  return (
    <Show
      actions={
        <TopToolbar>
          <CloneButton id={id} basePath={basePath} />
          <Button
            label="action.export"
            onClick={() => exportInspections(dataProvider, [parseInt(id)])}
          />
        </TopToolbar>
      }
      className="machine-show-container"
      {...props}
    >
      <SimpleShowLayout>
        <IconTextField iconType="id" source="id" />
        <IconTextField iconType="name" source="name" />
        <IconTextField iconType="date" fieldType="date" source="createdAt" />
        <IconTextField iconType="date" fieldType="date" source="updatedAt" />
        <DetailsShow source="spec" />
      </SimpleShowLayout>
    </Show>
  );
};
