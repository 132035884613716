import React from 'react';
import { useTranslate } from 'react-admin';
import { DataInstance } from '../../types.inspections';
import { getStatusInfo } from 'pages/inspections/utils';

export const Status = ({ data }: { data: DataInstance }) => {
  const translate = useTranslate();
  const { statusClass, statusText } = getStatusInfo(data);
  return <span className={statusClass}>{translate(statusText)}</span>;
};
