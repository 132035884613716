import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

export interface LoginParams {
  username: string;
  password: string;
  error?: any;
}

export const login = (
  params: LoginParams | CognitoUser,
): Promise<CognitoUser | any> => {
  if (params instanceof CognitoUser) {
    return Promise.resolve(params);
  }

  if (params?.error) {
    return Promise.reject(params.error);
  }
  return Auth.signIn(params?.username, params?.password);
};

export const logout = (): Promise<void> => {
  return Auth.signOut({ global: true });
};

const checkAuth = async (): Promise<void> => {
  let session;
  try {
    session = await Auth.currentSession();
  } catch (error) {
    session = null;
  }
  if (!session) {
    return Promise.reject('auth.no_auth');
  }
  return session;
};

const checkError = async (params: any): Promise<any> => {
  return Promise.resolve();
};

export const getRoleFromUser = (user: CognitoUser) =>
  user?.getSignInUserSession()?.getIdToken()?.payload['cognito:groups'][0];

export const getPermissions = async (params: any): Promise<any> => {
  const authUser: CognitoUser = await Auth.currentAuthenticatedUser();
  try {
    const role = getRoleFromUser(authUser);
    const departmentId = getCurrentDepartmentId(authUser);
    // Dealer Admins must login a department
    if (role === 'role.dealeradmin' && !departmentId) {
      return null;
    }
    return {
      role: getRoleFromUser(authUser),
      departmentId: getCurrentDepartmentId(authUser),
    }
  } catch (e) {
    return null;
  }
};

export const changePassword = (params: any): Promise<any> => {
  return Auth.completeNewPassword(params?.user, params?.password, {});
};

export const setDepartment = async (department: { id: number | '' }) => {
  const authUser: CognitoUser = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(authUser, {
    'custom:department_id': department.id.toString(),
  });
};

export const getCurrentDepartmentId =  (authUser: CognitoUser | any) => {
  const id = authUser.attributes['custom:department_id'];
  return (id && parseInt(id, 10)) ?? 0;
};

export const AuthProvider = {
  login,
  logout,
  checkAuth,
  checkError,
  getPermissions,
};

export const TopLevelAdmin = ['role.centraladmin', 'role.devadmin'];

export const departmentsQuery = {
  type: 'getList',
  resource: 'departments',
  payload: {
    pagination: { page: 1, perPage: 500000000 },
    sort: {},
    filter: {},
  },
};
