import { AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useTranslate } from 'react-admin';
import { iconLabelsToObjects, IconRenderer } from '../../../../components';

interface AccordionCoverProps {
  slug: string;
  label: string;
  icon?: string;
}

export const AccordionCover = ({ slug, label, icon }: AccordionCoverProps) => {
  const translate = useTranslate();

  let formattedIcon;
  if (icon) {
    const iconArray = iconLabelsToObjects([icon], translate);
    formattedIcon = iconArray[0];
  }
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon color="primary" />}
      aria-controls={`panel-${slug}-content`}
      id={`panel-${slug}-header`}
      className="panel-summary"
    >
      <Typography
        color="textSecondary"
        component="span"
        className="accordion-content"
      >
        {formattedIcon ? (
          <IconRenderer record={formattedIcon} noText={true} />
        ) : null}
        {label}
      </Typography>
    </AccordionSummary>
  );
};
