import React from 'react';
import { DateField, FieldTitle, TextField } from 'react-admin';
import { IconWrapper } from './icon-wrapper';

export const IconTextField = ({ iconType, fieldType, ...rest }: any) => {
  return (
    <div className="icon-textfield-data">
      <div className="info-textfield-container">
        <IconWrapper iconName={iconType} />
        <FieldTitle {...rest} />
      </div>
      {fieldType === 'date' ? (
        <DateField {...rest} className="data-textfield" />
      ) : (
        <TextField {...rest} className="data-textfield" />
      )}
    </div>
  );
};
