import mime from 'mime';
import { Button } from 'ra-ui-materialui';
import React from 'react';
import { useGetOne, useQuery } from 'react-admin';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { INSPECTION_TEMPLATE_SERVER_URL } from '../../config';

function blobToFile(
  buffer: ArrayBuffer,
  fileName: string,
  type: string | false,
): File {
  const file = new File([buffer], fileName, {
    lastModified: Date.now(),
    type: type || 'image/jpeg',
  });

  return file;
}

async function makeClonableSpecs(specs?: any[], id?: string) {
  if (!specs) {
    return specs;
  }

  return Promise.all(
    specs.map(async (spec) => {
      if (spec.config?.images) {
        spec.config.images = await Promise.all(
          spec.config.images.map((image) => convertImageToFile(image, id)),
        );
      }
      if (spec.config?.helperImages) {
        spec.config.helperImages = await Promise.all(
          spec.config.helperImages.map((image) =>
            convertImageToFile(image, id),
          ),
        );
      }
      if (spec.spec) {
        spec.spec = await makeClonableSpecs(spec.spec, id);
      }
      return spec;
    }),
  );
}

const convertImageToFile = async (image: any, recordId: string) => {
  if (!image.placeholder) {
    return image;
  }
  const { ref, extension = 'jpg' } = image.placeholder;
  const url = `${INSPECTION_TEMPLATE_SERVER_URL}/${recordId}/${ref}.${extension}`;
  const mimeType = mime.getExtension(url);
  try {
    const response = await fetch(url, { cache: 'no-cache' });
    const buffer = await response.arrayBuffer();
    image.placeholder = blobToFile(
      buffer,
      `${uuidv4()}.${extension}`,
      mimeType,
    );
  } catch (err) {
    console.log('Error fetching file', err);
  }
  return image;
};

export const CloneButton: React.FC<CloneButtonProps> = ({
  basePath = '',
  label = 'ra.action.clone',
  record,
  id,
}) => {
  const history = useHistory();
  const {
    data: fullRecord,
    loading,
    error,
  } = useQuery({
    type: 'getOne',
    resource: 'inspection-templates',
    payload: { id: record?.id ?? id },
  });

  const handleClone = async () => {
    const path = `${basePath}/create`;
    try {
      fullRecord.spec = await makeClonableSpecs(fullRecord.spec, fullRecord.id);
      const state = { record: omitId(fullRecord) };
      history.push(path, state);
    } catch (err) {
      history.push(path);
    }
  };

  return (
    <Button
      custom-test-id={record?.name}
      component={Link}
      label={label}
      onClick={handleClone}
      disabled={loading && !error}
    />
  );
};

const omitId = ({ id, ...rest }: any) => rest;

interface Props {
  basePath?: string;
  record?: any;
  icon?: React.ReactElement;
}

type CloneButtonProps = Props & Record<string, any>;
