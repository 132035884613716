import { Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import PLACEHOLDER_IMAGE from '../../../../img/no_picture_placeholder.png';
import { ImagesInputProps, ImagesInputValue } from '../../types';
import { ImageButton } from './image-button';
import { ImageDialog } from './image-dialog';
import { convertHeicPlaceholders } from './utils';

const useStyles = makeStyles((theme) => ({
  images: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 10rem)',
    gap: theme.spacing(2, 2),
    paddingBottom: theme.spacing(2),
  },
  addLink: {
    borderRadius: theme.spacing(0.5),
    height: '10rem',
    width: '10rem',
    borderWidth: '1px',
    borderColor: theme.palette.primary.dark,
    borderStyle: 'dashed',
  },
}));

interface Current {
  index: number;
  value: ImagesInputValue;
}

export const ImagesInput = ({
  source,
  onChange: propsOnChange,
  imagesKey = 'images',
  limit = 20,
  defaultValue = { [imagesKey]: [{}] },
}: ImagesInputProps) => {
  const {
    input: { onChange, value },
  } = useInput({
    source,
    onChange: propsOnChange,
  });
  const classes = useStyles();
  const translate = useTranslate();

  const [placeholders, setPlaceholders] = useState<(string | undefined)[]>([]);
  const [current, setCurrent] = useState<Current | undefined>();

  useEffect(() => {
    if (defaultValue && (!value[imagesKey] || value[imagesKey].length === 0)) {
      onChange({ ...value, ...defaultValue });
    }
  }, [defaultValue, value[imagesKey]]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newPlaceholders = value[imagesKey]?.map(({ placeholder }) =>
      placeholder && placeholder instanceof File
        ? URL.createObjectURL(placeholder)
        : PLACEHOLDER_IMAGE,
    );
    setPlaceholders(newPlaceholders);
  }, [setPlaceholders, value[imagesKey]]);

  useEffect(() => {
    const hasHeic =
      value[imagesKey]?.some(({ placeholder }: ImagesInputValue) => {
        return Boolean(
          placeholder &&
            placeholder instanceof File &&
            placeholder.type === 'image/heif',
        );
      }) ?? [];
    if (hasHeic && value[imagesKey]?.length) {
      convertHeicPlaceholders(value[imagesKey], (v) =>
        onChange({ ...value, ...v }),
      );
    }
  }, [onChange, value[imagesKey]]);

  const handleAdd = () => {
    onChange({
      ...value,
      [imagesKey]: [...(value[imagesKey] || []), {}],
    });
  };

  const handleEditClick = (index: number, value: ImagesInputValue) => {
    setCurrent({ index, value });
  };

  const handleDeleteClick = (index: number) => {
    const images = [...value[imagesKey]];
    images.splice(index, 1);
    onChange({ ...value, [imagesKey]: images });
  };

  const saveAndClose = (current?: Current) => {
    if (current) {
      const newImages = [...value[imagesKey]];
      newImages[current.index] = current.value;
      onChange({ ...value, [imagesKey]: newImages });
    }
    setCurrent(undefined);
  };

  const makeSrcFromIndex = (index: number) =>
    placeholders && placeholders[index] !== ''
      ? placeholders[index]
      : PLACEHOLDER_IMAGE;

  const makeAltFromImage = (image: ImagesInputValue) =>
    `${image.title ? image.title : translate('images.no_title')} - ${
      image.subtitle ? image.subtitle : translate('images.no_subtitle')
    }`;

  return (
    <div>
      <div className={classes.images}>
        {value[imagesKey]?.map((image: ImagesInputValue, index: number) => (
          <ImageButton
            key={index}
            handleEdit={() => handleEditClick(index, image)}
            handleDelete={(event) => {
              event.stopPropagation();
              handleDeleteClick(index);
            }}
            alt={makeAltFromImage(image)}
            src={makeSrcFromIndex(index)}
          />
        ))}
        {limit && value[imagesKey]?.length === limit ? null : (
          <Button className={classes.addLink} onClick={handleAdd}>
            <AddIcon />
          </Button>
        )}
      </div>
      <ImageDialog initial={current} saveAndClose={saveAndClose} />
    </div>
  );
};
