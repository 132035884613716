import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import {
  InspectionCreateData,
  Machine,
  MachineDetail,
} from '../types.inspections';

export const CreateToolbar = ({
  selectedMachine,
  ...rest
}: {
  selectedMachine: Machine;
}) => {
  const handleTransform = ({
    machine_id,
    assignee,
    inspection_details,
    serial,
    inspection_location,
    inspector,
  }: InspectionCreateData) => {
    const { machine_details, inspection_template_id } = selectedMachine;
    const newInspectionDetails = machine_details?.map(
      ({ label, type }: MachineDetail) => ({
        label,
        type,
        value: inspection_details?.[label] ?? '',
      }),
    );
    return {
      machine_id,
      inspection_template_id,
      inspection_details: newInspectionDetails,
      data: {},
      assignee,
      serial,
      operating_hours: 0,
      inspection_location,
      inspector,
    };
  };
  return (
    <Toolbar {...rest}>
      <SaveButton transform={handleTransform} submitOnEnter={false} />
    </Toolbar>
  );
};
