import { Dialog, Typography } from '@material-ui/core';
import { Button } from 'ra-ui-materialui';
import React from 'react';

interface DeleteDialogProps {
  hideDelete: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  open: boolean;
  text: string;
}

export const DeleteDialog = ({
  hideDelete,
  handleClose,
  handleDelete,
  open,
  text,
}: DeleteDialogProps) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="simple-dialog-title"
    open={open}
    PaperProps={{ className: 'deletion-dialog' }}
  >
    <div className="dialog-padding">
      <Typography gutterBottom variant="h5" component="h5">
        {text}
      </Typography>
      <div className="button-container">
        <Button label="ra.action.cancel" onClick={handleClose} />
        <Button
          label="ra.action.delete"
          onClick={handleDelete}
          style={hideDelete ? { display: 'none' } : {}}
        />
      </div>
    </div>
  </Dialog>
);
