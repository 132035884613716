import { CRUD_UPDATE, useNotify, useRefresh } from 'ra-core';
import { Button } from 'ra-ui-materialui';
import React from 'react';
import { useUnselectAll, useUpdateMany } from 'react-admin';

interface BulkArchiveToggleControllerProps extends BulkArchiveToggleProps {
  archived: boolean;
}

const useBulkArchiveToggleController = ({
  selectedIds,
  resource,
  archived,
}: BulkArchiveToggleControllerProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const [bulkArchive, { loading }] = useUpdateMany(
    resource,
    selectedIds,
    { archived },
    {
      action: CRUD_UPDATE,
      onSuccess: () => {
        notify(
          archived ? 'notification.archived' : 'notification.unarchived',
          'info',
          { smart_count: 1 },
        );
        unselectAll(resource);
        refresh();
      },
      onFailure: (error: any) => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        );
      },
    },
  );

  return {
    bulkArchive,
    loading,
  };
};

interface BulkArchiveToggleProps {
  selectedIds: number[];
  resource: string;
  areArchived?: boolean;
  bulkExportCallback?: (ids:number[]) => any;
}

export const BulkArchiveToggleButton = ({
  selectedIds,
  resource,
  areArchived,
  bulkExportCallback,
}: BulkArchiveToggleProps) => {
  const { bulkArchive: bulkArchiveToggle } = useBulkArchiveToggleController({
    selectedIds,
    resource,
    archived: !areArchived,
  });
  const exportButton = bulkExportCallback ? <Button
    label="action.export"
    onClick={() => bulkExportCallback(selectedIds)}
  /> : undefined;
  return (
    <>
      <Button
        label={areArchived ? 'action.unarchive' : 'action.archive'}
        onClick={bulkArchiveToggle}
      />
      { exportButton }
    </>
  );
};
