import { createTheme, Theme } from '@material-ui/core/styles';
import * as utils from './theme-utils';

export const applyOverrides = (theme: Theme): Theme =>
  createTheme({
    ...theme,
    overrides: themeOverrides(theme),
  });

export const themeOverrides = (baseTheme: Theme) => ({
  //* React-Admin overrides
  RaAutocompleteSuggestionItem: {
    root: {
      '&:hover': {
        '& .MuiSvgIcon-root': {
          fill: `${baseTheme.palette.secondary.contrastText} !important`,
        },
      },
    },
  },
  RaBulkActionsToolbar: {
    toolbar: {
      background: 'transparent !important',
    },
  },
  RaLayout: {
    content: {
      backgroundColor: baseTheme.palette.background.default,
      padding: '20px !important',
    },
  },
  RaBulkDeleteWithUndoButton: {
    deleteButton: {
      border: `1px solid ${baseTheme.palette.primary.main}`,
      color: baseTheme.palette.primary.main,
      background: baseTheme.palette.primary.contrastText,
      '&:hover': {
        background: `${baseTheme.palette.primary.main} !important`,
        color: baseTheme.palette.primary.contrastText,
      },
    },
  },
  RaButton: {
    button: {
      background: baseTheme.palette.primary.contrastText,
      border: `1px solid ${baseTheme.palette.primary.main}`,
      color: baseTheme.palette.primary.main,
      margin: '0 0.5rem',
      '&:hover': {
        background: baseTheme.palette.primary.main,
        color: baseTheme.palette.primary.contrastText,
      },
    },
    label: {
      padding: '0 0.5rem',
    },
    smallIcon: {
      display: 'none',
    },
  },
  //Controls the Icon color in the Inpection template viewer
  RaCardContentInner: {
    root: {
      '& .MuiSvgIcon-root': {
        fill: `${baseTheme.palette.primary.main} !important`,
        paddingRight: '10px',
      },
    },
  },
  RaDatagrid: {
    headerCell: {
      background: 'transparent',
      backgroundColor: 'transparent',
      padding: '12px',
    },
    rowCell: {
      background: 'transparent',
      backgroundColor: 'transparent',
      padding: '12px',
    },
  },
  RaDeleteWithConfirmButton: {
    deleteButton: {
      border: `1px solid ${baseTheme.palette.primary.main}`,
      color: baseTheme.palette.primary.main,
      background: baseTheme.palette.primary.contrastText,
      '&:hover': {
        background: `${baseTheme.palette.primary.main} !important`,
        color: baseTheme.palette.primary.contrastText,
      },
    },
  },
  RaDeleteWithUndoButton: {
    deleteButton: {
      border: `1px solid ${baseTheme.palette.primary.main}`,
      color: baseTheme.palette.primary.main,
      background: baseTheme.palette.primary.contrastText,
      '&:hover': {
        background: `${baseTheme.palette.primary.main} !important`,
        color: baseTheme.palette.primary.contrastText,
      },
    },
  },
  //Controls the background color of the Top Toolbar in Mobile View
  RaListToolbar: {
    toolbar: {
      alignItems: 'center',
      backgroundColor: 'transparent !important',
    },
  },
  RaMenuItemLink: {
    root: {
      color: baseTheme.palette.secondary.contrastText,
      fontSize: '14px',
      margin: '12px 12px',
      padding: '12px 16px',
      borderRadius: '4px',
      transition: '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      transitionProperty: 'color, background-color',

      '&:hover': {
        backgroundColor: baseTheme.palette.primary.main,
        color: baseTheme.palette.primary.contrastText,

        '& $icon': {
          color: baseTheme.palette.primary.contrastText,
        },
      },
    },
    active: {
      backgroundColor: baseTheme.palette.primary.main,
      color: `${baseTheme.palette.primary.contrastText} !important`,
      '& .MuiListItemIcon-root': {
        fill: `${baseTheme.palette.primary.contrastText} !important`,
      },
    },
  },
  RaNotification: {
    // error: {
    // backgroundColor: baseTheme.palette.secondary.main,
    // color: baseTheme.palette.primary.main,
    // border: `1px solid ${baseTheme.palette.primary.main}`,
    // },
    primary: {
      backgroundColor: baseTheme.palette.primary.main,
      color: baseTheme.palette.primary.contrastText,
      border: `1px solid ${baseTheme.palette.primary.contrastText}`,
    },
    // undo: {
    // backgroundColor: baseTheme.palette.secondary.main,
    // color: baseTheme.palette.primary.main,
    // border: `1px solid ${baseTheme.palette.primary.main}`,
    // },
  },
  RaPlaceholder: {
    root: {
      backgroundColor: 'transparent',
    },
  },
  RaSaveButton: {
    button: {
      background: baseTheme.palette.primary.contrastText,
      border: `1px solid ${baseTheme.palette.primary.main}`,
      color: baseTheme.palette.primary.main,
      '&:hover': {
        background: baseTheme.palette.primary.main,
        color: baseTheme.palette.primary.contrastText,
      },
    },
    leftIcon: {
      display: 'none',
    },
  },
  // Controls the background color of the sidebar when in mobile view
  RaSidebar: {
    fixed: {
      width: 'inherit',
      height: 'inherit',
      background: `${baseTheme.palette.secondary.main} !important`,
    },
    drawerPaper: {
      position: 'relative',
      marginTop: '0 !important', // could go
      background: `${baseTheme.palette.secondary.main} !important`,
    },
  },
  RaSimpleFormIterator: {
    root: {
      marginTop: '20px',
    },
  },
  RaToolbar: {
    desktopToolbar: {
      background: 'transparent',
      marginTop: 0,
    },
    mobileToolbar: {
      background: baseTheme.palette.secondary.main,
    },
  },
  // Controls the Background Color of the top toolbar icon in moblie view
  RaTopToolbar: {
    root: {
      marginTop: '0 !important',
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      marginBottom: '20px !important',
      backgroundColor: 'transparent !important',
    },
  },

  //* Material UI overrides
  MuiButton: utils.regularButton(
    baseTheme.palette.primary.main,
    baseTheme.palette.primary.contrastText,
    baseTheme.palette.secondary.main,
    baseTheme.palette.secondary.contrastText,
  ),
  MuiCard: utils.regularCard(baseTheme.palette.secondary.main),
  MuiDrawer: utils.regularDrawer(baseTheme.palette.secondary.main),
  MuiFormLabel: utils.regularFormLabel(
    baseTheme.palette.secondary.contrastText,
  ),
  MuiFormHelperText: utils.regularFormLabel(
    baseTheme.palette.secondary.contrastText,
  ),
  MuiIconButton: utils.regularIconButton(baseTheme.palette.primary.main),
  MuiListItem: utils.regularListItem(
    baseTheme.palette.primary.main,
    baseTheme.palette.secondary.main,
    baseTheme.palette.primary.contrastText,
    baseTheme.palette.secondary.contrastText,
  ),
  MuiListItemIcon: utils.regularListItemIcon(
    baseTheme.palette.primary.main,
    baseTheme.palette.secondary.main,
    baseTheme.palette.primary.contrastText,
    baseTheme.palette.secondary.contrastText,
  ),
  MuiPaper: utils.regularPaper(
    baseTheme.palette.primary.main,
    baseTheme.palette.secondary.main,
    baseTheme.subtleShadow,
  ),
  MuiSvgIcon: utils.regularSvgIcon(baseTheme.palette.secondary.contrastText),
  MuiTableSortLabel: utils.regularTableSortLabel(
    baseTheme.palette.primary.main,
    baseTheme.palette.secondary.contrastText,
  ),
  MuiTypography: utils.regularTypography(
    baseTheme.palette.secondary.contrastText,
  ),
  MuiOutlinedInput: {
    root: {
      marginTop: baseTheme.spacing(-1),
      paddingBottom: baseTheme.spacing(2),
    },
    inputMarginDense: {
      minHeight: baseTheme.spacing(3),
      paddingTop: baseTheme.spacing(2),
      paddingBottom: '0px',
    },
  },
  MuiFilledInput: {
    root: {
      borderRadius: baseTheme.shape.borderRadius,
      '&::before': {
        borderBottom: `none !important`,
      },
    },
    input: {
      borderRadius: baseTheme.shape.borderRadius,
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
        {
          ' -webkit-box-shadow': `0 0 0px 1000px ${baseTheme.inputAutoFillColor} inset`,
          boxShadow: `0 0 0px 1000px ${baseTheme.inputAutoFillColor} inset`,
          transition: 'background-color 5000s ease-in-out 0s',
          '-webkit-text-fill-color': `${baseTheme.inputAutoFillTextColor}`,
        },
    },
  },
  MuiToolbar: {
    root: {
      marginTop: '0px !important',
    },
    regular: {
      minHeight: '0px !important',
    },
  },
  RaFilterForm: {
    form: {
      minHeight: '0px !important',
    },
  },
  RaShow: {
    main: {
      marginTop: '0px !important',
    },
  },
  RaEdit: {
    main: {
      marginTop: '0px !important',
    },
  },
});
