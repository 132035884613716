import {
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { PdfContext } from '../context/pdf-context';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  formControl: {
    width: '100%',
  },
  inputLabel: {
    top: 5,
    left: 5,
  },
}));

const imageQualityOptions = [
  { label: 'pdf.low', value: 0 },
  {
    label: 'pdf.medium',
    value: 1,
  },
  {
    label: 'pdf.high',
    value: 2,
  },
];

export const PdfSettingsControls = () => {
  const classes = useStyles();

  const translate = useTranslate();
  const pdfState = useContext(PdfContext);
  const [pdfSettings, setPdfSettings] = useState({
    includeImages: true,
    imageQuality: imageQualityOptions[2],
  });

  useEffect(() => {
    pdfState.setState({
      ...pdfState,
      imageCompression:
        pdfSettings.imageQuality.value === 0
          ? 'high'
          : pdfSettings.imageQuality.value === 1
          ? 'medium'
          : 'low',
      includeImages: pdfSettings.includeImages,
    });
  }, [pdfSettings]);

  const onIncludeImagesChanged = (_: any) => {
    setPdfSettings({
      ...pdfSettings,
      includeImages: !pdfSettings.includeImages,
    });
  };

  const onImageQualityChanged = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const selectedValue = event.target.value as number;
    const selectedQuality = imageQualityOptions.find(
      (opt) => opt.value === selectedValue,
    );

    setPdfSettings({
      ...pdfSettings,
      imageQuality: selectedQuality!,
    });
  };

  return (
    <Box className={classes.container}>
      <FormControl
        key={'include-images-form'}
        className={classes.formControl}
        size="small"
      >
        <InputLabel
          className={classes.inputLabel}
          id="include-image-select-label-id"
        >{`${translate('pdf.includeImages')}:`}</InputLabel>
        <Select
          value={+pdfSettings.includeImages}
          onChange={onIncludeImagesChanged}
          variant="filled"
          labelId="include-image-select-label-id"
        >
          <MenuItem value={1} key={'1'}>
            {translate('pdf.yes')}
          </MenuItem>
          <MenuItem value={0} key={'0'}>
            {translate('pdf.no')}
          </MenuItem>
        </Select>
      </FormControl>
      {pdfSettings.includeImages && (
        <FormControl
          key={'image-quality-form'}
          className={classes.formControl}
          size="small"
        >
          <InputLabel
            id="quality-image-select-label-id"
            className={classes.inputLabel}
          >
            {`${translate('pdf.imageQuality')}:`}
          </InputLabel>
          <Select
            value={pdfSettings.imageQuality.value}
            onChange={onImageQualityChanged}
            variant="filled"
            labelId="quality-image-select-label-id"
          >
            {imageQualityOptions.map(({ label, value }) => (
              <MenuItem value={value} key={label}>
                {translate(label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};
