import React from 'react';

export const ErrorCodes = ({ errorCodes }: { errorCodes: string[] }) => (
  <>
    {errorCodes?.map((code: string, index: number) => (
      <p key={`${index}-${code}`}>
        {index + 1}. {code}
      </p>
    ))}
  </>
);
