import { ThemeProvider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Amplify } from 'aws-amplify';
import { DepartmentCreate } from 'pages/departments/create';
import { DepartmentEdit } from 'pages/departments/edit';
import { DepartmentList } from 'pages/departments/list';
import React, { Suspense } from 'react';
import {
  Admin,
  Resource,
  RouteWithoutLayout,
  useTranslate,
  WithPermissions,
} from 'react-admin';
import { useCookies } from 'react-cookie';
import { Route } from 'react-router-dom';
import { getDashboardTheme } from 'themes/theme';
import { ThemeMode } from 'themes/theme-modes';
import { AuthProvider, Login } from './auth';
import { CustomLayout, ErrorPage } from './components';
import { checkEnv, dashboardConfig, history } from './config';
import dataProvider from './data-provider';
import { i18nProvider } from './i18n';
import {
  AccountDeleteRequest,
  GroupCreate,
  GroupEdit,
  GroupList,
  InspectionArchive,
  InspectionCreate,
  InspectionList,
  InspectionShow,
  MachineCreate,
  MachineEdit,
  MachineList,
  MachineShow,
  QRCodeCreate,
  QRCodeEdit,
  QRCodeList,
  QRCodeShow,
  TemplateArchive,
  TemplateCreate,
  TemplateList,
  TemplateShow,
  UserCreate,
  UserEdit,
  UserList,
  UserShow,
} from './pages';

checkEnv();

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEBCLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

const customRoutes = [
  <Route
    key="inspection-templates-archive"
    path="/inspection-templates/archive"
    render={({ match }) => (
      <WithPermissions match={match} component={TemplateArchive} />
    )}
    exact
  />,
  <Route
    key="inspections-archive"
    path="/inspections/archive"
    render={({ match }) => (
      <WithPermissions match={match} component={InspectionArchive} />
    )}
    exact
  />,
  <Route
    key="inspections-groups"
    path="/inspections/groups"
    render={({ match }) => (
      <WithPermissions match={match} component={GroupList} />
    )}
    exact
  />,
  <Route
    key="inspections-groups-create"
    path="/inspections/groups/create"
    render={({ match }) => (
      <WithPermissions match={match} component={GroupCreate} />
    )}
    exact
  />,
  <Route
    key="inspections-groups-update"
    path="/inspections/groups/:id"
    render={({ match }) => (
      <WithPermissions match={match} component={GroupEdit} />
    )}
    exact
  />,
  <RouteWithoutLayout
    key="delete-account"
    path="/delete-account"
    component={AccountDeleteRequest}
  />,
];

const ThemeWrapper = ({ children }) => {
  const defaultMode: ThemeMode = 'LIGHT';
  const [cookies, setCookie] = useCookies();
  const theme = getDashboardTheme({
    savedThemeMode: cookies.themeMode,
    defaultMode,
  });
  if (!cookies.themeMode) {
    setCookie('themeMode', defaultMode, { path: '/' });
  }
  if (!cookies.themeHistory) {
    const emptyArray = new Array(10);
    setCookie('themeHistory', emptyArray, { path: '/' });
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

function Main(): JSX.Element {
  const translate = useTranslate();
  const theme = useTheme();
  return (
    <Admin
      authProvider={AuthProvider}
      catchAll={ErrorPage}
      customRoutes={customRoutes}
      dataProvider={dataProvider}
      history={history}
      i18nProvider={i18nProvider}
      loginPage={Login}
      theme={theme}
      layout={CustomLayout}
      title={translate('app.title')}
    >
      <Resource name="login" key="login-resource" />
      <Resource
        name="inspection-templates"
        create={TemplateCreate}
        list={TemplateList}
        show={TemplateShow}
        key="inspection-templates-resource"
      />
      <Resource
        name="machines"
        create={MachineCreate}
        edit={MachineEdit}
        list={MachineList}
        show={MachineShow}
        key="machines-resource"
      />
      <Resource
        name="inspections"
        create={
          (dashboardConfig.assignInspectionFlag ? InspectionCreate : null)!
        }
        list={InspectionList}
        show={InspectionShow}
        key="inspections-resource"
      />
      <Resource
        name="qr-codes"
        create={QRCodeCreate}
        list={QRCodeList}
        show={QRCodeShow}
        edit={QRCodeEdit}
        key="qr-codes-resource"
      />
      <Resource
        name="users"
        show={UserShow}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        key="users-resource"
      />
      <Resource
        name="groups"
        list={GroupList}
        create={GroupCreate}
        edit={GroupEdit}
        key="groups-resource"
      />
      <Resource
        name="departments"
        list={DepartmentList}
        create={DepartmentCreate}
        edit={DepartmentEdit}
        key="departments-resource"
      />
      <Resource name="roles" key="roles-resource" />
    </Admin>
  );
}

export default function App(): JSX.Element {
  return (
    <Suspense fallback="loading">
      <ThemeWrapper>
        <Main />
      </ThemeWrapper>
    </Suspense>
  );
}
