import React from 'react';
import { Media } from '../../../types.inspections';
import { MediaText } from './media-text';
import { SingleMedia } from './single-media';

interface MediaWithTextProps {
  image: Media;
  id: number;
  isVideo?: boolean;
}

export const MediaWithText = ({
  image: { ref, type, comment },
  id,
  isVideo,
}: MediaWithTextProps) =>
  Boolean(ref) ? (
    Boolean(comment || type) ? (
      <MediaText type={type} comment={comment} isVideo={isVideo}>
        <SingleMedia reference={ref} id={id} isVideo={isVideo} />
      </MediaText>
    ) : (
      <SingleMedia reference={ref} id={id} isVideo={isVideo} />
    )
  ) : null;
