import { Button } from 'ra-ui-materialui';
import React, { useState } from 'react';
import { useMutation, useRefresh, useTranslate } from 'react-admin';
import { DeleteDialog } from '../../../components';
import { TemplatesWith } from '../utils';

interface DeleteTemplateButtonProps {
  templatesWithMachines: TemplatesWith;
  templatesWithInspections: TemplatesWith;
  record: { id: number };
}

export const DeleteTemplateButton = ({
  templatesWithMachines,
  templatesWithInspections,
  record: { id },
}: DeleteTemplateButtonProps) => {
  const [deleteTemplate] = useMutation({
    type: 'delete',
    resource: 'inspection-templates',
    payload: { id },
  });
  const refresh = useRefresh();

  const translate = useTranslate();

  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);

  const handleDelete = async () => {
    await deleteTemplate();
    toggleOpen();
    refresh();
  };

  const inspections = templatesWithInspections[id];
  const machines = templatesWithMachines[id];

  const deletePossible = Boolean(!inspections && !machines);

  const textHandle = deletePossible
    ? 'resources.inspection-templates.deletion.confirmation'
    : Boolean(inspections)
    ? Boolean(machines)
      ? 'resources.inspection-templates.deletion.hasBoth'
      : 'resources.inspection-templates.deletion.hasInspection'
    : 'resources.inspection-templates.deletion.hasMachine';

  const textVariables = {
    id: id.toString(),
    inspections: inspections?.join(', '),
    machines: machines?.join(', '),
  };
  return (
    <>
      <Button label="ra.action.delete" onClick={toggleOpen} />
      <DeleteDialog
        hideDelete={!deletePossible}
        handleClose={toggleOpen}
        handleDelete={handleDelete}
        open={open}
        text={translate(textHandle, textVariables)}
      />
    </>
  );
};
