import { CRUD_UPDATE, Record, useNotify, useRefresh, useUpdate } from 'ra-core';
import { Button } from 'ra-ui-materialui';
import React, { useCallback } from 'react';

interface ArchiveToggleProps {
  resource?: string;
  record?: Record;
}

const useArchiveToggleController = ({
  resource,
  record,
}: ArchiveToggleProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const isArchived = (record as Record).archived;
  const [toggleArchive, { loading }] = useUpdate(
    resource as string,
    (record as Record).id,
    { ...record, archived: !isArchived },
    record,
    {
      action: CRUD_UPDATE,
      onSuccess: () => {
        notify(
          isArchived ? 'notification.unarchived' : 'notification.archived',
          'info',
          { smart_count: 1 },
        );
        refresh();
      },
      onFailure: (error: any) => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        );
      },
    },
  );

  const handleArchiveToggle = useCallback(() => {
    toggleArchive({
      payload: { id: (record as Record).id, previousData: record },
    });
  }, [toggleArchive, record]);

  return {
    handleArchiveToggle,
    loading,
  };
};

export const ArchiveToggleButton = ({
  record,
  resource,
}: ArchiveToggleProps) => {
  const { handleArchiveToggle, loading } = useArchiveToggleController({
    resource,
    record,
  });

  const isArchived = (record as Record).archived;
  const label = isArchived ? 'action.unarchive' : 'action.archive';

  return (
    <Button label={label} onClick={handleArchiveToggle} disabled={loading} />
  );
};
