/**
 * Change event emitted by DamagesInput
 */
export interface DamagesInputChangeEvent {
  damages: DamagesInputValue;
}

interface ImgDmgDefaultType {
  images?: ImagesInputValue[];
  damages?: { maxDamages: number; type_options: string[] };
}
/**
 * Props of DamagesInput
 */
export interface DamagesInputProps {
  className?: string;
  resource?: string;
  source?: string;
  onChange?: (event: DamagesInputChangeEvent) => void;
  value?: any;
  minDamages?: number;
  defaultValue?: ImgDmgDefaultType;
}

/**
 * Value saved by DamagesInput
 */
interface DamagesInputValue {
  maxDamages: number;
}

/**
 * Change event emmited by ImagesInput
 */
export interface ImagesInputChangeEvent {
  images: ImagesInputValue[];
}

/**
 * Change event emmited by ImagesInput
 */
export interface HelperImagesInputChangeEvent {
  helperImages?: ImagesInputValue[];
}

/**
 * Props of ImagesInput
 */
export interface ImagesInputProps {
  className?: string;
  resource?: string;
  source?: string;
  imagesKey?: string;
  onChange?: (event: ImagesInputChangeEvent) => void;
  value?: any;
  defaultValue?: ImgDmgDefaultType;
  limit?: number;
}

/**
 * Value saved by ImagesInput
 */
export interface ImagesInputValue {
  title?: string;
  subtitle?: string;
  placeholder?: { ref: string } | File;
}

/**
 * Change event emitted by ImagesDamagesInput
 */
export type ImagesDamagesInputChangeEvent = ImagesDamagesInputValue;

/**
 * Props of ImagesDamagesInput
 */
export interface ImagesDamagesInputProps {
  className?: string;
  resource?: string;
  source?: string;
  onChange?: (event: ImagesDamagesInputChangeEvent) => void;
  value?: any;
  defaultValue?: ImgDmgDefaultType;
}

/**
 * Value saved by ImagesDamagesInput
 */
type ImagesDamagesInputValue = DamagesInputChangeEvent & ImagesInputChangeEvent;

/**
 * StepType enum
 */
export enum StepTypeEnum {
  Images = 'images',
  Damages = 'damages',
  ImagesDamages = 'images-damages',
  Status = 'status',
  ErrorCodes = 'error-codes',
  Rating = 'rating',
  Parts = 'parts',
  Checkboxes = 'checkboxes',
  Signature = 'signature',
  QRCodes = 'qr-codes',
  Tutorial = 'tutorial',
  Videos = 'videos',
  StatusParts = 'status-parts',
  NestedSpec = 'nested-spec',
  FreeInput = 'free-input',
}

/**
 * Change event emitted by SpecComponent
 */
export interface SpecComponentChangeEvent {
  label: string;
  icon: string;
  type: StepTypeEnum | '';
  config?: (
    | DamagesInputChangeEvent
    | ImagesDamagesInputChangeEvent
    | ImagesInputChangeEvent
    | StatusInputChangeEvent
    | PartsInputChangeEvent
    | CheckboxesInputChangeEvent
    | TutorialInputChangeEvent
    | null
  ) &
    HelperImagesInputChangeEvent;
  spec?: SpecComponentChangeEvent[];
}

export type ConfigImagesChangeEvent = (
  | ImagesDamagesInputChangeEvent
  | ImagesInputChangeEvent
) &
  HelperImagesInputChangeEvent;

export interface StatusInputProps {
  className?: string;
  resource?: string;
  source?: string;
  onChange?: (event: StatusInputChangeEvent) => void;
  value?: StatusInputValue;
  defaultValue?: StatusInputValue;
}

export interface StatusInputChangeEvent {
  status: StatusInputValue[];
}

export type StatusInputType = 'code' | 'note' | 'url';

export interface StatusInputValue {
  [key: string]: string;
}

export interface PopulateSettingValues {
  type: StepTypeEnum;
  labelColNum: number;
  helperTextColNum: number;
  config?: { parts: { [key: string]: number } };
  spec?: PopulateSettingValues;
}

export interface PartsInputProps {
  className?: string;
  resource?: string;
  source?: string;
  onChange?: (event: PartsInputChangeEvent) => void;
  value?: SinglePart[];
  defaultValue?: SinglePart[];
}

export interface PartsInputChangeEvent {
  parts: SinglePart[];
}

export interface SinglePart {
  number: string;
  description: string;
  quantity: number;
}

export interface CheckboxesInputProps {
  className?: string;
  resource?: string;
  source?: string;
  onChange?: (event: CheckboxesInputChangeEvent) => void;
  value?: string[];
  defaultValue?: string[];
}

export interface CheckboxesInputChangeEvent {
  checkboxes: string[];
}

export interface FreeInputsInputProps {
  className?: string;
  resource?: string;
  source?: string;
  onChange?: (event: FreeInputsInputChangeEvent) => void;
  value?: string[];
  defaultValue?: string[];
}

export interface FreeInputsInputChangeEvent {
  freeInputs: string[];
}

export interface TutorialInputProps {
  className?: string;
  resource?: string;
  source?: string;
  onChange?: (event: TutorialInputChangeEvent) => void;
  value?: any;
  defaultValue?: TutorialInputChangeEvent;
}

interface TutorialInputChangeEvent {
  tutorial: string;
  images: ImagesInputValue[];
}

export enum PartsInputTypesEnum {
  Number = 'number',
  Quantity = 'quantity',
  Description = 'description',
}

export interface ItemProps {
  type: StepTypeEnum;
  label: string;
  helper_text?: string;
  config?: { [key: string]: any };
  spec?: {
    type: StepTypeEnum;
    label: string;
    helper_text?: string;
    config?: { [key: string]: any };
  }[];
}

export type StatusPartsInputChangeEvent = StatusPartsInputValue;

export interface StatusPartsInputProps {
  className?: string;
  resource?: string;
  source?: string;
  onChange?: (event: StatusPartsInputChangeEvent) => void;
  value?: any;
  defaultValue?: StatusPartsInputValue;
}

type StatusPartsInputValue = PartsInputChangeEvent & ImagesInputChangeEvent;

export const helperFieldNames = ['helperText', 'helperType', 'helperImages'];

export const imagesFields = ['images', 'helperImages'] as const;
