import BusinessIcon from '@material-ui/icons/Business';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CategoryIcon from '@material-ui/icons/Category';
import ClassIcon from '@material-ui/icons/Class';
import DetailsIcon from '@material-ui/icons/Details';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import MailIcon from '@material-ui/icons/Mail';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import {
  accountStar as AccountStar,
  barcodeScan as BarcodeScan,
  clipboardListOutline as ClipboardListOutline,
  idCard as IdCard,
} from 'img/icons';
import React from 'react';

export const IconWrapper = ({ iconName }: { iconName?: string }) => {
  switch (iconName) {
    case 'manufacturer':
      return <HomeWorkIcon fontSize="large" className="textfield-icon" />;
    case 'category':
      return <CategoryIcon fontSize="large" className="textfield-icon" />;
    case 'model':
      return <DetailsIcon fontSize="large" className="textfield-icon" />;
    case 'template':
      return (
        <ClipboardListOutline fontSize="large" className="textfield-icon" />
      );
    case 'date':
      return <CalendarTodayIcon fontSize="large" className="textfield-icon" />;
    case 'id':
      return <IdCard fontSize="large" className="textfield-icon" />;
    case 'version':
      return <ClassIcon fontSize="large" className="textfield-icon" />;
    case 'region':
      return <PublicIcon fontSize="large" className="textfield-icon" />;
    case 'company':
      return <BusinessIcon fontSize="large" className="textfield-icon" />;
    case 'contact':
      return (
        <PermContactCalendarIcon fontSize="large" className="textfield-icon" />
      );
    case 'email':
      return <MailIcon fontSize="large" className="textfield-icon" />;
    case 'phone':
      return <PhoneIcon fontSize="large" className="textfield-icon" />;
    case 'allowed_serial':
      return <BarcodeScan fontSize="large" className="textfield-icon" />;
    case 'name':
      return <AccountStar fontSize="large" className="textfield-icon" />;
    default:
      return <HomeWorkIcon fontSize="large" className="textfield-icon" />;
  }
};
