import { Button, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { required, TextInput, useInput, useTranslate } from 'react-admin';
import { FreeInputsInputProps } from '../../types';

const freeInputsDefault = '';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(0.5),
    height: '55px',
    borderWidth: '1px',
    borderColor: theme.palette.primary.dark,
    borderStyle: 'dashed',
  },
  addButton: {
    width: '30%',
  },
  removeButton: {
    width: '10%',
  },
  row: {
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  value: {
    width: '80%',
  },
}));

export const FreeInputsInput = ({ source }: FreeInputsInputProps) => {
  const classes = useStyles();
  const {
    input: { onChange, value },
  } = useInput({ source });

  const translate = useTranslate();
  const [freeInputs, setFreeInputs] = useState<string[]>([freeInputsDefault]);

  useEffect(() => {
    setFreeInputs(value.freeInputs ?? [freeInputsDefault]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setFreeInputByIndex = useCallback(
    ({ value, index }: { value: string; index: number }) => {
      const allFreeInputs = [...freeInputs];
      allFreeInputs[index] = value;
      setFreeInputs(allFreeInputs);
    },
    [freeInputs],
  );

  useEffect(() => {
    onChange({ ...value, freeInputs });
  }, [freeInputs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!freeInputs || freeInputs.length === 0) {
      addFreeInput();
    }
  }, [freeInputs]); // eslint-disable-line react-hooks/exhaustive-deps

  const addFreeInput = () => {
    const newFreeInputs = [...freeInputs];
    newFreeInputs.push(freeInputsDefault);
    setFreeInputs(newFreeInputs);
  };

  const deleteFreeInput = (index: number) => {
    const newFreeInputs = [...freeInputs];
    newFreeInputs.splice(index, 1);
    setFreeInputs(newFreeInputs);
  };

  const handleValueChange = (
    event: ChangeEvent<{ value: unknown }>,
    index: number,
  ) => {
    setFreeInputByIndex({ value: event.target.value as string, index });
  };

  const isRequired = required();
  return (
    <div className={classes.column}>
      {freeInputs?.map((value: string, index: number) => {
        return (
          <div className={classes.row}>
            <Button
              className={`${classes.removeButton} ${classes.button}`}
              onClick={() => deleteFreeInput(index)}
            >
              <CloseIcon />
            </Button>
            <TextInput
              source={`${source}.free_input[${index}]`}
              label={translate('free-input.value')}
              className={classes.value}
              variant="outlined"
              defaultValue={value}
              value={value}
              validate={isRequired}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleValueChange(event, index)
              }
            />
          </div>
        );
      })}

      <Button
        className={`${classes.addButton} ${classes.button}`}
        onClick={addFreeInput}
      >
        <AddIcon />
        <Typography>{translate('free-input.addFreeInput')}</Typography>
      </Button>
    </div>
  );
};
