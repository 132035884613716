import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { ImagesInputValue } from '../../types';

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
  fileInput: {
    display: 'none',
  },
}));

interface Current {
  index: number;
  value: ImagesInputValue;
}

type CurrentInput = {
  [key: string]: string | { ref: string } | File | undefined;
};

interface ImageDialogProps {
  initial?: Current;
  saveAndClose: (current?: Current) => void;
}

export const ImageDialog = ({ initial, saveAndClose }: ImageDialogProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [current, setCurrent] = useState<Current | undefined>(initial);

  useEffect(() => {
    setCurrent(initial);
  }, [initial]);

  const handleClose = () => {
    saveAndClose(undefined);
  };

  const handleSaveEdit = () => {
    saveAndClose(current);
  };

  const updateCurrent = (valueUpdate: CurrentInput) => {
    setCurrent({
      ...(current as Current),
      value: { ...(current as Current).value, ...valueUpdate },
    });
  };

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) =>
    updateCurrent({ title: event.target.value });

  const handleSubtitleChange = (event: ChangeEvent<HTMLInputElement>) =>
    updateCurrent({ subtitle: event.target.value });

  const handleImageSelect = (event: ChangeEvent<HTMLInputElement>) =>
    updateCurrent({
      placeholder: event.target.files?.length
        ? event.target.files[0]
        : undefined,
    });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="edit-dialog-title"
      open={!!initial}
    >
      <DialogTitle id="edit-dialog-title">
        {translate('images.edit_image')}
      </DialogTitle>
      <DialogContent>
        {current ? (
          <>
            <TextField
              className={classes.input}
              variant="outlined"
              label={translate('images.title')}
              fullWidth
              defaultValue={(current as Current).value.title}
              onChange={handleTitleChange}
            />
            <TextField
              className={classes.input}
              variant="outlined"
              label={translate('images.subtitle')}
              fullWidth
              defaultValue={(current as Current).value.subtitle}
              onChange={handleSubtitleChange}
            />
            <input
              accept="image/*, .HEIC"
              className={classes.fileInput}
              id="edit-file"
              type="file"
              onChange={handleImageSelect}
            />
            <label htmlFor="edit-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                fullWidth
              >
                {translate('images.upload')}
              </Button>
            </label>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('ra.action.cancel')}
        </Button>
        <Button onClick={handleSaveEdit} color="primary" variant="outlined">
          {translate('ra.action.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
