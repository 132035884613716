import {
  Button,
  Card,
  CardContent,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import dataProvider from 'data-provider';
import React, { useState } from 'react';

export const AccountDeleteRequest = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [done, setDone] = useState(false);
  const [error, setError] = useState(undefined);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    dataProvider
      .create('delete-account', {
        data: {
          email,
        },
      })
      .then(() => {
        setError(undefined);
        setDone(true);
      })
      .catch((err) => {
        setError(
          err?.body?.message ?? 'something went wrong during submission',
        );
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Account Deletion Request
          </Typography>

          {!done && (
            <>
              <TextField
                label="Enter your email address"
                type="email"
                value={email}
                onChange={handleEmailChange}
                fullWidth
                margin="normal"
              />
              <Typography variant="body2" color="error">
                Warning: Submitting this request will delete your account and
                all related data.
              </Typography>
              {error && (
                <Typography variant="body2" color="error">
                  Error: {error}
                </Typography>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                style={{ marginTop: '16px' }}
              >
                Submit Request
              </Button>
            </>
          )}
          {done && (
            <Typography variant="body2" color="initial">
              Request has been sent for email {email}.
            </Typography>
          )}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};
