import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { INSPECTION_TEMPLATE_SERVER_URL } from '../../../config';
import PLACEHOLDER_IMAGE from '../../../img/no_picture_placeholder.png';
import { ImageConfig } from '../../inspections/types.inspections';
import { ItemShowProps } from './types';

const useStyles = makeStyles((theme) => ({
  images: {
    position: 'relative',
    width: 150,
    height: 150,
  },
  titles: {
    padding: theme.spacing(2),
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  placeholder: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
}));

interface ImageShowProps extends ItemShowProps {
  title?: string;
}

export function ImagesShow({ id, config: { images } }: ImageShowProps) {
  const classes = useStyles();

  const srcFromImage = (image: ImageConfig) => {
    if (!image.placeholder) {
      return PLACEHOLDER_IMAGE;
    }
    const { ref, extension = 'jpg' } = image.placeholder;
    return `${INSPECTION_TEMPLATE_SERVER_URL}/${id}/${ref}.${extension}`;
  };

  const altFromImage = (image: ImageConfig) =>
    `${image.title ?? ''} - ${image.subtitle ?? ''}`;

  return (
    <>
      {images?.map((image: ImageConfig) => (
        <div className={classes.images}>
          <img
            className={classes.placeholder}
            src={srcFromImage(image)}
            alt={altFromImage(image)}
          />
          <div className={classes.titles}>
            <Typography variant="body1">{image.title ?? ''}</Typography>
            <Typography variant="body2">{image.subtitle ?? ''}</Typography>
          </div>
        </div>
      ))}
    </>
  );
}
