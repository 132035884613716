import { createContext } from 'react';

export interface PdfState {
  setState: (prop: PdfState) => void;
  imageCompression: 'low' | 'medium' | 'high';
  includeImages: boolean;
  renderingState: { loading: boolean; status?: string };
}

export const defaultPdfState: Omit<PdfState, 'setState'> = {
  imageCompression: 'low',
  includeImages: true,
  renderingState: { loading: false },
};

export const PdfContext = createContext<PdfState>({
  ...defaultPdfState,
  setState: () => {},
});
