export const inspectionsQuery = {
  type: 'getList',
  resource: 'inspections',
  payload: {
    pagination: { page: 1, perPage: 500000000 },
    sort: {},
    filter: {},
  },
};

export const machinesQuery = {
  type: 'getList',
  resource: 'machines',
  payload: {
    pagination: { page: 1, perPage: 500000000 },
    sort: {},
    filter: {},
  },
};
