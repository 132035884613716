import { TopLevelAdmin, getPermissions } from 'auth/auth';
import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  EditButton,
  List,
  ListProps,
  TextField,
  refreshView,
} from 'react-admin';
import { useDispatch } from 'react-redux';

export const DepartmentList = ({
  basePath,
  resource,
  hasCreate: hasCreateProp,
  className,
}: ListProps): JSX.Element => {
  const [hasCreate, setHasCreate] = useState(hasCreateProp);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshView());
  }, [dispatch]);

  useEffect(() => {
    const checkUserRole = async () => {
      const { role } = await getPermissions({});
      if (TopLevelAdmin.includes(role)) {
        setHasCreate(hasCreateProp);
      } else {
        setHasCreate(false);
      }
    };
    checkUserRole();
  }, [hasCreateProp]);

  return (
    <List
      basePath={basePath}
      resource={resource}
      bulkActionButtons={false}
      hasCreate={hasCreate}
    >
      <Datagrid className={className || 'defaultDataGrid'}>
        <TextField source="id" />
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
