import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { ImagesShow } from './images';
import { ItemShowProps } from './types';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2),
  },
}));
export const TutorialShow = ({
  id,
  config,
  config: { tutorial = '' } = {},
}: ItemShowProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.text} variant="subtitle2">
        {tutorial}
      </Typography>
      <ImagesShow id={id} config={config} />
    </>
  );
};
