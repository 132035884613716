import React, { Children, useContext, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useField, useFormState } from 'react-final-form';
import { useMeasure } from 'react-use';
import { useTranslate } from 'react-admin';
import { IconButton } from '@material-ui/core';
import * as Icons from '../../../img/icons';
import get from 'lodash/get';
import { SubmitTriggerContext } from './create';
import { ScrollOnError } from './scroll';

const useStyles = makeStyles((theme) => ({
  collapseButton: {
    marginLeft: 'auto',
    padding: '7px !important',
  },
  collapseIcon: {
    paddingRight: '0 !important',
  },
  collapseHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5em',
    marginLeft: '-0.5em',
    marginRight: '0.5em',
  },
  collapseHeaderType: {
    backgroundColor: theme.palette.background.default,
    padding: '0.5rem 0.5rem',
    marginRight: '5px',
    borderRadius: '5px',
  },
  collapseHeaderText: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '8px',
  },
  collapse: {
    overflow: 'hidden',
    transition: 'height 200ms',
    marginBottom: theme.spacing(2),
  },
  errorText: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    border: `2px solid ${theme.palette.error.main}`,
    padding: '0.5rem 0.5rem',
    marginRight: '5px',
    borderRadius: '5px',
  },
  error: {},
}));

const ChildrenMap = Children.map;
Children.map = function (children, fn) {
  if (
    Array.isArray(children) &&
    children[0] &&
    children[0].type === CollapseHackStub
  ) {
    return (
      <CollapseContainer source={children[0]}>
        {ChildrenMap.call(this, children.slice(1), fn)}
      </CollapseContainer>
    );
  }
  return ChildrenMap.call(this, children, fn);
};

export const useSectionError = (source: string) => {
  const { errors, touched } = useFormState();
  const translate = useTranslate();

  const error = useMemo(() => {
    for (const key in touched) {
      if (touched[key] && get(errors, key, null)) {
        if (key.startsWith(source)) {
          if (key.split('.').at(-1) === 'config') {
            continue;
          }
          if (!source.includes('].spec') && key.includes('].spec')) {
            return translate('resources.inspection-templates.fields.sub_spec');
          }
          if (key.includes('config.parts')) {
            return translate(`parts.${key.split('.').at(-1)}`);
          }
          if (key.includes('config.checkboxes')) {
            return translate('checkboxes.value');
          }
          if (key.includes('config.free_input')) {
            return translate('free-input.value');
          }
          return translate(
            `resources.inspection-templates.fields.${key.split('.').at(-1)}`,
          );
        }
      }
    }
    return null;
  }, [errors, touched, source, translate]);

  return error;
};

const CollapseContainer = (props: any) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const [ref, { height }] = useMeasure();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const { source } = props.children[0].props.input.props;
  const label = useField(`${source}.label`).input.value;
  const type = useField(`${source}.type`).input.value;
  const icon = useField(`${source}.icon`).input.value;
  const translate = useTranslate();

  const specSource = source?.match(/.*(?=\[)/i)?.[0];
  const spec = useField(specSource).input.value;
  const { trigger } = useContext(SubmitTriggerContext);

  const error = useSectionError(source);

  const id = Number(
    source
      ?.match(/.*(?=\])/i)[0]
      ?.split('[')
      .pop(),
  );

  const Icon = Icons[icon];
  const CollapseIcon = expanded ? Icons.collapseDown : Icons.collapseRight;

  useEffect(() => {
    if (spec?.length && spec.length > 0 && spec.length !== id + 1) {
      setExpanded(false);
    }
  }, [spec?.length, id]);

  useEffect(() => {
    if (error) {
      setExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // there are some bugs with mui Collapse, so used a div instead.
  return (
    <>
      <ScrollOnError source={source} />
      <div className={classes.collapseHeader}>
        <div className={classes.collapseHeaderText}>
          {Icon && <Icon className="MuiSvgIcon-root" />}
          {type && (
            <div className={classes.collapseHeaderType}>
              {translate('config.' + type)}{' '}
            </div>
          )}
          {label && <div> {label} </div>}
          {error && (
            <div className={classes.errorText}>Error: {error}, ...</div>
          )}
        </div>

        <IconButton
          className={classes.collapseButton}
          onClick={handleExpandClick}
        >
          <CollapseIcon className={classes.collapseIcon} />
        </IconButton>
      </div>
      <div
        className={classes.collapse}
        style={expanded ? { height } : { height: 0 }}
      >
        <div ref={ref}>{props.children}</div>
      </div>
    </>
  );
};

export const CollapseHackStub = () => <></>;
