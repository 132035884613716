import { FormControl, makeStyles } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect } from 'react';
import {
  useInput,
  useTranslate,
  TextInput,
  required,
  minValue,
  number,
} from 'react-admin';
import { dashboardConfig } from '../../../../config';
import { DamagesInputProps } from '../../types';
import { MAX_DAMAGE_TYPES } from './utils';

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  maxDamagesInput: {
    margin: theme.spacing(1, 0, 2),
  },
}));

export const DamagesInput = ({
  source,
  onChange: propsOnChange,
  defaultValue = { damages: { maxDamages: 1, type_options: [] } },
  minDamages = 1,
}: DamagesInputProps) => {
  const {
    input: { onChange, value },
  } = useInput({
    source,
    onChange: propsOnChange,
  });
  const classes = useStyles();
  const translate = useTranslate();

  useEffect(() => {
    if (!(value.damages?.maxDamages >= minDamages)) {
      onChange({
        ...value,
        damages: { ...value.damages, maxDamages: minDamages },
      });
    }
  }, [value.damages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultValue && !value.damages) {
      onChange({ ...value, ...defaultValue });
    }
  }, [defaultValue, value.damages]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTextChange = (event: any) => {
    const selected = Number(event.target.value);
    const maxDamages = (isNaN(selected) ? 1 : selected) ?? 1;
    onChange({
      ...value,
      damages: {
        ...value.damages,
        maxDamages,
      },
    });
  };

  const handleTypesChange = (type_options: string[]) => {
    if (type_options.length > MAX_DAMAGE_TYPES) {
      return value;
    }
    onChange({
      ...value,
      damages: {
        ...value.damages,
        type_options,
      },
    });
  };
  return (
    <div className={classes.root}>
      <TextInput
        source={`${source}.damages.maxDamages`}
        type="number"
        variant="filled"
        label={translate('damages.max_damages')}
        fullWidth
        className={classes.maxDamagesInput}
        defaultValue={value.damages?.maxDamages ?? minDamages}
        onChange={handleTextChange}
        validate={[required(), minValue(minDamages), number()]}
      />
      {dashboardConfig.damageTypesFlag ? (
        <FormControl fullWidth>
          <ChipInput
            variant="filled"
            label={translate('damages.type_options')}
            defaultValue={value?.damages?.type_options}
            onChange={handleTypesChange}
          />
        </FormControl>
      ) : null}
    </div>
  );
};
