import { CRUD_UPDATE, Record, useNotify, useRefresh, useUpdate } from 'ra-core';
import { Button } from 'ra-ui-materialui';
import React, { useCallback } from 'react';

interface UngroupProps {
  resource?: string;
  record?: Record;
}

const useUngroupController = ({
  resource,
  record,
}: UngroupProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [toggleUngroup, { loading }] = useUpdate(
    resource as string,
    (record as Record)?.id,
    { ...record, group_id: null },
    record,
    {
      action: CRUD_UPDATE,
      onSuccess: () => {
        notify(
          'notification.ungrouped',
          'info',
          { smart_count: 1 },
        );
        refresh();
      },
      onFailure: (error: any) => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        );
      },
    },
  );

  const handleUngroup = useCallback(() => {
    toggleUngroup({
      payload: { id: (record as Record)?.id, previousData: record },
    });
  }, [toggleUngroup, record]);

  return {
    handleUngroup,
    loading,
  };
};

export const UngroupButton = ({
  record,
  resource,
}: UngroupProps) => {
  const { handleUngroup, loading } = useUngroupController({
    resource,
    record,
  });

  const isGrouped = Boolean((record as Record)?.group_id);
  const label = 'action.ungroup';

  return (
    <Button label={label} onClick={handleUngroup} disabled={loading || !isGrouped} />
  );
};
