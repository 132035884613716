import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useState } from 'react';
import { useTranslate } from 'react-admin';
import { PopulateSettingValues, StepTypeEnum } from '../../types';
import { filterModules } from '../../utils';
import { PartsListSelect } from './parts-list-select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface PopulateOptionSelectProps {
  cols: string[];
  onChange: (newSetting: PopulateSettingValues) => void;
}

export const NestedListSelect = ({
  cols,
  onChange,
}: PopulateOptionSelectProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [setting, setSetting] = useState<PopulateSettingValues>({
    type: StepTypeEnum.Images,
    labelColNum: 0,
    helperTextColNum: -1,
  });

  const handleChangeType = (event: ChangeEvent<{ value: unknown }>) => {
    let currentSetting = { ...setting };
    currentSetting.type = event.target.value as StepTypeEnum;
    onChange(currentSetting);
    setSetting(currentSetting);
  };

  const handleChangeLabelCol = (event: ChangeEvent<{ value: unknown }>) => {
    let currentSetting = { ...setting };
    currentSetting.labelColNum = event.target.value as number;
    onChange(currentSetting);
    setSetting(currentSetting);
  };

  const handleChangeHelperTextCol = (
    event: ChangeEvent<{ value: unknown }>,
  ) => {
    let currentSetting = { ...setting };
    currentSetting.helperTextColNum = event.target.value as number;
    onChange(currentSetting);
    setSetting(currentSetting);
  };

  const changePartsColNum = (newPartsColNum: { [key: string]: number }) => {
    if (Object.values(newPartsColNum).length !== 0) {
      let currentSetting = { ...setting };
      currentSetting.config = { parts: {} };
      currentSetting.config.parts = newPartsColNum;
      onChange(currentSetting);
      setSetting(currentSetting);
    }
  };

  const types = Object.entries(StepTypeEnum)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ id: value, name: translate(`config.${value}`) }));

  const choices = filterModules(types).filter(
    ({ id }) => id !== StepTypeEnum.NestedSpec,
  );

  const showPartsSelect = setting.type === StepTypeEnum.Parts;

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="populate-type">
          {translate('resources.inspection-templates.fields.sub_type')}
        </InputLabel>
        <Select
          defaultValue={setting.type}
          labelId="populate-type"
          id="populate-type-select"
          value={setting.type}
          onChange={handleChangeType}
        >
          {choices.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel id="populate-label">
          {translate('resources.inspection-templates.fields.sub_label')}
        </InputLabel>
        <Select
          defaultValue={setting.labelColNum}
          labelId="populate-label"
          id="populate-label-select"
          value={setting.labelColNum}
          onChange={handleChangeLabelCol}
        >
          {cols.map((col) => (
            <MenuItem
              key={cols.indexOf(col).toString()}
              value={cols.indexOf(col)}
            >
              {col}
            </MenuItem>
          ))}
          <MenuItem key="empty" value={-1}>
            {translate('csv_import.empty')}
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl required={true} className={classes.formControl}>
        <InputLabel id="populate-helper-text">
          {translate('resources.inspection-templates.fields.helper_text')}
        </InputLabel>
        <Select
          labelId="populate-helper-text"
          id="populate-helper-text-select"
          value={setting.helperTextColNum}
          onChange={handleChangeHelperTextCol}
        >
          {cols.map((col) => (
            <MenuItem
              key={cols.indexOf(col).toString()}
              value={cols.indexOf(col)}
            >
              {col}
            </MenuItem>
          ))}
          <MenuItem key="empty" value={-1}>
            {translate('csv_import.empty')}
          </MenuItem>
        </Select>
      </FormControl>

      {showPartsSelect && (
        <PartsListSelect onChange={changePartsColNum} cols={cols} />
      )}
    </div>
  );
};
