import React from 'react';
import { PartsInput } from './parts';
import { ImagesInput } from './images';
import { StatusPartsInputProps } from '../../types';

export const StatusPartsInput = (props: StatusPartsInputProps) => {
  const { source } = props;

  return (
    <div>
      <ImagesInput source={source} />
      <PartsInput source={source} />
    </div>
  );
};
