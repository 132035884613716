import React from 'react';
import {
  DateField,
  DeleteButton,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin';
import { Archive, ArchiveToggleButton } from '../../../components';

export const InspectionArchive = (props: {
  match: any;
  options: object;
  class: string;
}): JSX.Element => {
  const translate = useTranslate();
  return (
    <Archive resource="inspections" {...props}>
      <TextField source="id" />
      <TextField source="serial" />
      <TextField source="group_id"/>
      <ReferenceField
        source="machine_id"
        reference="machines"
        label={translate('resources.machines.fields.manufacturer')}
      >
        <TextField source="manufacturer" />
      </ReferenceField>
      <ReferenceField
        source="machine_id"
        reference="machines"
        label={translate('resources.machines.fields.category')}
      >
        <TextField source="category" />
      </ReferenceField>
      <ReferenceField
        source="machine_id"
        reference="machines"
        label={translate('resources.machines.fields.model')}
      >
        <TextField source="model" />
      </ReferenceField>
      <ReferenceField
        source="inspection_template_id"
        reference="inspection-templates"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" sortBy="created_at" />
      <ArchiveToggleButton />
      <DeleteButton undoable={false} redirect={false} />
    </Archive>
  );
};
