import { makeStyles, Typography } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import * as React from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  config: {
    padding: theme.spacing(1),
  },
}));

interface DetailProps {
  label: string;
  type: string;
  example: string;
}

export const Detail = ({ label, type, example }: DetailProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <TreeItem nodeId={label} label={label} key={label}>
      <div className={classes.config}>
        <Typography variant="subtitle2">
          {translate(`resources.machines.fields.${type}`)}: e.g. "{example}"
        </Typography>
      </div>
    </TreeItem>
  );
};
