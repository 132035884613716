import { Box, Button } from '@material-ui/core';
import React, { FC } from 'react';
import QRCode from 'react-qr-code';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

export interface QRProps {
  id: string;
  data: string;
  size?: number;
  allowDownload?: boolean;
  downloadedFileName?: string;
}

export const QR: FC<QRProps> = ({
  id,
  data,
  size = 256,
  allowDownload = false,
  downloadedFileName = id,
}) => {
  const styles = useStyles({ size: size });
  const translate = useTranslate();

  const downloadQR = () => {
    const svg = document.getElementById(id);
    const svgData = new XMLSerializer().serializeToString(svg!);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx!.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = downloadedFileName;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  };

  return (
    <Box className={styles.qrContainer}>
      <QRCode id={id} value={data} size={size} />
      {allowDownload && (
        <Button
          variant="contained"
          type="submit"
          fullWidth
          onClick={downloadQR}
        >
          {translate('resources.qr-codes.qr.download')}
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme, { size: number }>((theme) => ({
  qrContainer: {
    width: ({ size }) => size,
  },
}));
