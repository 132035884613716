import { Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FormEvent, useState } from 'react';
import { Notification, useTranslate } from 'react-admin';
import { LoginParams } from './auth';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 250,
  },
  submitButton: {
    marginTop: theme.spacing(1),
    height: 45,
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
}));

interface LoginFormProps {
  login: (auth: LoginParams) => void;
  isLoading?: boolean;
  onGoToResendPassword?: () => void;
}

export const LoginForm = ({
  login,
  isLoading,
  onGoToResendPassword,
}: LoginFormProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [notification, enableNotification] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    login({ username, password });
    enableNotification(true);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        id="username"
        name="username"
        label={translate('ra.auth.username')}
        margin="dense"
        disabled={isLoading}
        variant="filled"
        fullWidth
        onChange={(event) => setUsername(event.target.value)}
      />
      <TextField
        id="password"
        name="password"
        label={translate('ra.auth.password')}
        type="password"
        margin="dense"
        disabled={isLoading}
        variant="filled"
        fullWidth
        onChange={(event) => setPassword(event.target.value)}
      />
      <Button
        className={classes.submitButton}
        variant="contained"
        type="submit"
        disabled={isLoading}
        fullWidth
      >
        {isLoading && (
          <CircularProgress
            size={25}
            thickness={2}
            className={classes.spinner}
          />
        )}
        {translate('ra.auth.sign_in')}
      </Button>
      <Button
        className={classes.submitButton}
        variant="outlined"
        type="button"
        color="primary"
        onClick={onGoToResendPassword}
        fullWidth
      >
        {translate('action.resend_password')}
      </Button>
      {notification && <Notification />}
    </form>
  );
};
