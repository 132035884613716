import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { PopulateSettingValues, StepTypeEnum } from '../../types';
import { filterModules } from '../../utils';
import { NestedListSelect } from './nested-list-select';
import { PartsListSelect } from './parts-list-select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface PopulateOptionSelectProps {
  cols: string[];
  onConfirm: (newSetting: PopulateSettingValues) => void;
}

export const PopulateOptionSelect = ({
  cols,
  onConfirm,
}: PopulateOptionSelectProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [setting, setSetting] = useState<PopulateSettingValues>({
    type: StepTypeEnum.Images,
    labelColNum: 0,
    helperTextColNum: -1,
  });
  const [disableConfirm, setDisableConfirm] = useState<boolean>(true);
  const notify = useNotify();

  useEffect(() => {
    onConfirm({
      type: StepTypeEnum.Images,
      labelColNum: 0,
      helperTextColNum: -1,
    });
    return () => onConfirm({} as PopulateSettingValues);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (setting.type === undefined || setting.labelColNum === undefined) {
      setDisableConfirm(true);
      return;
    }
    setDisableConfirm(false);
  }, [setting]);

  const handleChangeType = (event: ChangeEvent<{ value: unknown }>) => {
    let currentSetting = { ...setting };
    if (setting.type === StepTypeEnum.NestedSpec) {
      currentSetting.helperTextColNum = -1;
    }
    currentSetting.type = event.target.value as StepTypeEnum;
    setSetting(currentSetting);
  };

  const handleChangeLabelCol = (event: ChangeEvent<{ value: unknown }>) => {
    let currentSetting = { ...setting };
    currentSetting.labelColNum = event.target.value as number;
    setSetting(currentSetting);
  };

  const handleChangeHelperTextCol = (
    event: ChangeEvent<{ value: unknown }>,
  ) => {
    let currentSetting = { ...setting };
    currentSetting.helperTextColNum = event.target.value as number;
    setSetting(currentSetting);
  };

  const changePartsColNum = (newPartsColNum: { [key: string]: number }) => {
    if (Object.values(newPartsColNum).length !== 0) {
      let currentSetting = { ...setting };
      currentSetting.config = { parts: {} };
      currentSetting.config.parts = newPartsColNum;
      setSetting(currentSetting);
    }
  };

  const changeNestedSetting = (newNestedSetting: PopulateSettingValues) => {
    let currentSetting = { ...setting };
    currentSetting.spec = newNestedSetting;
    setSetting(currentSetting);
  };

  const types = Object.entries(StepTypeEnum)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ id: value, name: translate(`config.${value}`) }));

  const choices = filterModules(types);

  const handleOnConfirm = () => {
    onConfirm(setting);
    notify('csv_import.notification.populated');
  };

  const showPartsSelect = setting.type === StepTypeEnum.Parts;
  const showNestedSelect = setting.type === StepTypeEnum.NestedSpec;
  const showHelperText = !showNestedSelect;
  return (
    <div>
      <FormControl required={true} className={classes.formControl}>
        <InputLabel id="populate-type">
          {translate('resources.inspection-templates.fields.type')}
        </InputLabel>
        <Select
          labelId="populate-type"
          id="populate-type-select"
          value={setting.type}
          onChange={handleChangeType}
        >
          {choices.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>
              {choice.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl required={true} className={classes.formControl}>
        <InputLabel id="populate-label">
          {translate('resources.inspection-templates.fields.label')}
        </InputLabel>
        <Select
          labelId="populate-label"
          id="populate-label-select"
          value={setting.labelColNum}
          onChange={handleChangeLabelCol}
        >
          {cols.map((col) => (
            <MenuItem
              key={cols.indexOf(col).toString()}
              value={cols.indexOf(col)}
            >
              {col}
            </MenuItem>
          ))}
          <MenuItem key="empty" value={-1}>
            {translate('csv_import.empty')}
          </MenuItem>
        </Select>
      </FormControl>

      {showHelperText && (
        <FormControl required={true} className={classes.formControl}>
          <InputLabel id="populate-helper-text">
            {translate('resources.inspection-templates.fields.helper_text')}
          </InputLabel>
          <Select
            labelId="populate-helper-text"
            id="populate-helper-text-select"
            value={setting.helperTextColNum}
            onChange={handleChangeHelperTextCol}
          >
            {cols.map((col) => (
              <MenuItem
                key={cols.indexOf(col).toString()}
                value={cols.indexOf(col)}
              >
                {col}
              </MenuItem>
            ))}
            <MenuItem key="empty" value={-1}>
              {translate('csv_import.empty')}
            </MenuItem>
          </Select>
        </FormControl>
      )}

      {showPartsSelect && (
        <PartsListSelect onChange={changePartsColNum} cols={cols} />
      )}

      {showNestedSelect && (
        <NestedListSelect onChange={changeNestedSetting} cols={cols} />
      )}
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOnConfirm}
          disabled={disableConfirm}
        >
          {translate('csv_import.confirm')}
        </Button>
      </div>
    </div>
  );
};
