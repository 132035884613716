import React, { ReactNode } from 'react';

interface MediaTextProps {
  type?: string[];
  comment?: string;
  isVideo?: boolean;
  children: ReactNode;
}

export const MediaText = ({
  type,
  comment,
  isVideo,
  children,
}: MediaTextProps) => (
  <div className={isVideo ? 'column' : 'damage-container'}>
    {children}
    <div
      className={isVideo ? 'column video-comment' : 'column padded-container'}
    >
      {type?.map((singleType: string, index: number) => (
        <p key={`${index}-${singleType}`}>
          <strong className="damage-type">- {singleType}</strong>
        </p>
      ))}
      <div className="image-comment">{comment}</div>
    </div>
  </div>
);
