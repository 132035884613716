import { Record } from 'ra-core';
import { GroupIdInput } from 'components/group-id-input';
import { UngroupButton } from 'components/ungroup-button';
import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  FunctionField,
  ShowButton,
  TextField,
  TextInput,
  useTranslate,
  EditButton,
} from 'react-admin';
import { useSelector } from 'react-redux';
import {
  ArchiveToggleButton,
} from '../../../components';
import { statuses } from '../../inspections/show/header/utils';

const StatusField = (props: any) => {
    const { record } = props;
    const status = statuses.find((s) => s.value === record.status);
    const translate = useTranslate();
    return (
      <TextField
        {...props}
        record={{ ...record, status: translate(status?.label) }}
      />
    );
  };

const GroupFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="serial" source="serial" alwaysOn />
    </Filter>
  );
};

export const GroupList = (props: {
  options: object;
  class: string;
}) : JSX.Element => {
  const { class: className } = props;
  const resource = 'groups'
  const basePath = "/inspections/groups"
  const resourceData = useSelector(
    (state: any) => state.admin.resources[resource],
  );

  return (
    resourceData ? 
      <List
        {...props}
        resource={resource}
        filters={<GroupFilter />}
        basePath={basePath}
        {...resourceData.props}
      >
        <Datagrid className={className || 'defaultDataGrid'} expand={<InspectionInGroupList/>}>
          <TextField source="id" />
          <TextField source="serial" />
          <EditButton basePath={basePath} />
        </Datagrid>
      </List> : <></>
  )
}

interface InspectionInGroupListProps{
  id?:string,
  record?:{
    id: string,
    serial: string,
  },
}

const InspectionInGroupList = (props:InspectionInGroupListProps) => {
  
  const {
    record,
  } = props

  const translate = useTranslate();
  return (
    <List 
      basePath='/inspections'
      resource='inspections' 
      filter={{group_id: record?.id, archived: false}}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="serial" />
        <FunctionField source='group_id' label='Group' render={
          (record:Record) => <GroupIdInput record={record} resource={'inspections'} />
          }
        />
        <ReferenceField
          source="machine_id"
          reference="machines"
          label={translate('resources.machines.fields.manufacturer')}
        >
          <TextField source="manufacturer" />
        </ReferenceField>
        <ReferenceField
          source="machine_id"
          reference="machines"
          label={translate('resources.machines.fields.category')}
        >
          <TextField source="category" />
        </ReferenceField>
        <ReferenceField
          source="machine_id"
          reference="machines"
          label={translate('resources.machines.fields.model')}
        >
          <TextField source="model" />
        </ReferenceField>
        <ReferenceField
          source="inspection_template_id"
          reference="inspection-templates"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" sortBy="created_at" />
        <StatusField source="status" />
        <ShowButton />
        <ArchiveToggleButton />
        <UngroupButton/>
      </Datagrid>
    </List>
  )
}
  