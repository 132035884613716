import { cloneDeep } from 'lodash';
import { DataProvider, DataProviderProxy } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';
import { dashboardConfig } from '../../config';
import {
  imagesFields,
  ImagesInputValue,
  ItemProps,
  PopulateSettingValues,
  SpecComponentChangeEvent,
  StepTypeEnum,
} from './types';

export interface TemplatesWith {
  [key: number]: number[];
}

export const makeTemplatesWith = (inputArray: any) => {
  const templatesWith: TemplatesWith = {};
  inputArray?.forEach(({ inspection_template_id, id }: any) => {
    if (
      inspection_template_id &&
      Object.keys(templatesWith).includes(inspection_template_id.toString())
    ) {
      templatesWith[inspection_template_id].push(id);
    } else {
      templatesWith[inspection_template_id] = [id];
    }
  });
  return templatesWith;
};

export const filterModules = (types: { id: string; name: string }[]) =>
  types
    .filter(({ id }) =>
      dashboardConfig.ratingFlag ? id : id !== StepTypeEnum.Rating,
    )
    .filter(({ id }) =>
      dashboardConfig.partsFlag ? id : id !== StepTypeEnum.Parts,
    )
    .filter(({ id }) =>
      dashboardConfig.signatureFlag ? id : id !== StepTypeEnum.Signature,
    )
    .filter(({ id }) =>
      dashboardConfig.checkboxesFlag ? id : id !== StepTypeEnum.Checkboxes,
    )
    .filter(({ id }) =>
      dashboardConfig.qrCodeFlag ? id : id !== StepTypeEnum.QRCodes,
    )
    .filter(({ id }) =>
      dashboardConfig.tutorialFlag ? id : id !== StepTypeEnum.Tutorial,
    )
    .filter(({ id }) =>
      dashboardConfig.statusPartsFlag ? id : id !== StepTypeEnum.StatusParts,
    )
    .filter(({ id }) =>
      dashboardConfig.videosFlag ? id : id !== StepTypeEnum.Videos,
    );

function convert26(num: number) {
  let str = '';
  while (num > 0) {
    let m = num % 26;
    if (m === 0) {
      m = 26;
    }
    str = String.fromCharCode(m + 64) + str;
    num = (num - m) / 26;
  }
  return str;
}

export const createCols = (data: any[]) => {
  if (data.length === 0 || data[0].length === 0) {
    return [];
  }
  const colNum = data[0].length;

  const colArray = [];
  for (let i = 0; i < colNum; i++) {
    colArray[i] = `Column ${convert26(i + 1)}`;
  }
  return colArray;
};

export const processData = (
  data: string[][],
  setting: PopulateSettingValues,
) => {
  const type = setting.type;
  const labelColNum = setting.labelColNum;
  const helperTextColNum = setting.helperTextColNum;
  let itemsList: ItemProps[] = [];
  let previousIndices: string[] = [];
  let dataSplitByLabel = {} as { [key: string]: string[][] };

  data.forEach((row) => {
    const currentIndex = row[labelColNum] ?? 'empty';
    if (previousIndices.includes(currentIndex)) {
      dataSplitByLabel[currentIndex].push(row);
    } else {
      if (row[helperTextColNum]) {
        const newItem = {
          type: type,
          label: indexToLabel(currentIndex),
          helper_text: row[helperTextColNum],
        };
        itemsList.push(newItem);
      } else {
        const newItem = { type: type, label: indexToLabel(currentIndex) };
        itemsList.push(newItem);
      }
      previousIndices.push(currentIndex);
      dataSplitByLabel[currentIndex] = [];
      dataSplitByLabel[currentIndex].push(row);
    }
  });

  if (type === StepTypeEnum.Parts && setting.config?.parts) {
    const configSetting = setting.config.parts;
    itemsList.forEach((item) => {
      item.config = { parts: [] as { [key: string]: string }[] };
      dataSplitByLabel[labelToIndex(item.label)].forEach((row) => {
        let newPart = {} as { [key: string]: string };
        Object.keys(configSetting).forEach((fieldName) => {
          newPart[fieldName] = row[configSetting[fieldName]];
        });
        item.config?.parts.push(newPart);
      });
    });
  }

  if (type === StepTypeEnum.NestedSpec && setting.spec) {
    itemsList.forEach((item) => {
      const specData = dataSplitByLabel[labelToIndex(item.label)];
      const specSetting = setting.spec as PopulateSettingValues;
      item.spec = processData(specData, specSetting);
    });
  }

  return itemsList;
};

const indexToLabel = (label: string) => {
  return label === 'empty' ? '' : label;
};

const labelToIndex = (label: string) => {
  return label === '' ? 'empty' : label;
};

export const exportInspections = async (
  dataProvider: DataProviderProxy<DataProvider>,
  ids: number[],
) => {
  const downloadJSON = (filename: string, data: any) => {
    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    // 2 space indented JSON for human readability
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json;charset=utf-8' });
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}.json`);
    fakeLink.click();
  };
  const { data } = await dataProvider.getMany('inspection-templates-export', {
    ids,
  });
  downloadJSON('inspection-templates', data);
};

const DRAFT_STORAGE_KEY = 'template.create.draft';

const dataURLtoBlob = (dataurl: string) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const blobToFile = (blob: Blob, fileName: string): File => {
  const file = new File([blob], fileName, { type: blob.type });
  return file;
};

export const getTemplateDraft = () => {
  const draft =
    JSON.parse(localStorage.getItem(DRAFT_STORAGE_KEY) || 'null') || undefined;

  draft?.spec?.forEach((spec: SpecComponentChangeEvent) => {
    for (const field of imagesFields) {
      if (spec?.config && field in spec.config) {
        spec.config[field].forEach((image: ImagesInputValue) => {
          if (image.placeholder && 'ref' in image.placeholder) {
            const file = blobToFile(
              dataURLtoBlob(image.placeholder.ref),
              `${uuidv4()}.jpg`,
            );
            image.placeholder = file as File;
          }
        });
      }
    }
  });

  return draft;
};

export const setTemplateDraft = (data?: any) => {
  const draftData = cloneDeep(data);
  if (draftData?.spec) {
    draftData.spec.forEach((spec: SpecComponentChangeEvent) => {
      for (const field of imagesFields) {
        if (spec?.config && field in spec.config) {
          spec.config[field].forEach((image: ImagesInputValue) => {
            if (
              image.placeholder instanceof File ||
              image.placeholder instanceof Blob
            ) {
              const reader = new FileReader();
              reader.readAsDataURL(image.placeholder);
              reader.onload = () => {
                image.placeholder = {
                  ref: reader.result as string,
                };
                setTemplateDraft(draftData);
              };
            }
          });
        }
      }
    });
  }
  localStorage.setItem(
    DRAFT_STORAGE_KEY,
    draftData ? JSON.stringify(draftData) : '',
  );
};
