import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useState } from 'react';
import {
  AppBar,
  AppBarProps,
  useLocale,
  usePermissions,
  UserMenu,
  useSetLocale,
  useTranslate,
} from 'react-admin';
import { DarkLightModeToggle } from './light-dark-toggle';
import { setDepartment } from 'auth/auth';
import { SpinnerModal } from 'components/spinner-modal';
import { DepartmentSelectInput } from 'components/department-select-input';

const useStyles = makeStyles((theme) => ({
  departmentSelectorContainer: {
    width: 'max-content',
    minWidth: '200px',
  },
  defaultAppbarContainer: {
    paddingRight: '0px !important',
    boxShadow: theme.subtleShadow,
  },

  defaultAppbarTitle: {
    flex: '1',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  languageSwitchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },

  languageSwitchButtonWrapper: {
    padding: '12px 4px',
  },

  languageSwitchButton: {
    background: 'transparent',
    border: '0',
    padding: '5px',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '14px',
    cursor: 'pointer',
    transition: '0.5s ease-in-out',
    '&.selected': {
      background: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '&:hover': {
        background: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        transition: '0.5s ease-in-out',
      },
    },
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      transition: '0.5s ease-in-out',
    },
  },

  languageSwitchSpacer: {
    height: '20px',
    background: theme.palette.primary.main,
    width: '1px',
    display: 'inline-block',
    position: 'relative',
    margin: '0 4px',
  },
}));

const CustomAppBar = (props: AppBarProps) => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  const translate = useTranslate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { permissions } = usePermissions();

  const switchLng = (lng: string) => {
    localStorage.setItem('ra-locale', lng);
    setLocale(lng);
  };

  const userMenu = (
    <UserMenu>
      <DarkLightModeToggle />
    </UserMenu>
  );

  return (
    <AppBar
      className={classes.defaultAppbarContainer}
      {...props}
      userMenu={userMenu}
      container={Fragment}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.defaultAppbarTitle}
      />
      <SpinnerModal open={loading} />
      <div className={classes.departmentSelectorContainer}>
        <DepartmentSelectInput
          value={permissions?.departmentId || 0}
          onChange={async (v) => {
            setLoading(true);
            try {
              await setDepartment({ id: v });
              window.location.reload();
            } catch (e) {
              setLoading(false);
              throw e;
            }
          }}
          variant="standard"
          size="small"
        />
      </div>
      <div className={classes.languageSwitchContainer}>
        <Tooltip title={translate('languages.german') as string}>
          <div className={classes.languageSwitchButtonWrapper}>
            <button
              onClick={() => switchLng('de')}
              className={`${classes.languageSwitchButton} ${
                locale === 'de' && 'selected'
              }`}
            >
              de
            </button>
          </div>
        </Tooltip>
        <span className={classes.languageSwitchSpacer}></span>
        <Tooltip title={translate('languages.english') as string}>
          <div className={classes.languageSwitchButtonWrapper}>
            <button
              onClick={() => switchLng('en')}
              className={`${classes.languageSwitchButton} ${
                locale === 'en' && 'selected'
              }`}
            >
              en
            </button>
          </div>
        </Tooltip>
      </div>
    </AppBar>
  );
};

export default CustomAppBar;
