import { Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCountDown } from 'hooks/useCountDown';
import { useResendPassword } from 'hooks/useResendPassword';
import React, { useEffect, useState } from 'react';
import { Notification, useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 250,
  },
  submitButton: {
    marginTop: theme.spacing(1),
    height: 45,
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
}));

export const ResendPasswordForm = ({ onCancel }: { onCancel: () => void }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const { mutateAsync, isLoading, isSuccess } = useResendPassword();
  const countdown = useCountDown(60);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (isSuccess ? countdown.start() : null), [isSuccess]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        mutateAsync(email);
      }}
      className={classes.form}
    >
      <TextField
        id="email"
        name="email"
        label="Email"
        margin="dense"
        variant="filled"
        fullWidth
        disabled={isLoading}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />

      <Button
        className={classes.submitButton}
        variant="contained"
        type="submit"
        color="primary"
        disabled={isLoading || countdown.isCounting}
        fullWidth
      >
        {isLoading && <CircularProgress size={25} thickness={2} />}
        {countdown.isCounting
          ? translate('action.resend_password') + ` (${countdown.timeLeft}s)`
          : translate('action.resend_password')}
      </Button>
      <Button
        className={classes.submitButton}
        variant="outlined"
        type="button"
        color="primary"
        fullWidth
        onClick={onCancel}
      >
        {translate('ra.action.cancel')}
      </Button>
      <Notification/>
    </form>
  );
};
