import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UsersIcon from '@material-ui/icons/AccountCircle';
import DepartmentIcon from '@material-ui/icons/Domain';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  MenuItemLink,
  setSidebarVisibility,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { dashboardConfig } from '../config';
import {
  archive as Archive,
  checkDecagram as CheckDecagram,
  clipboardListOutline as ClipboardListOutline,
  qrCode as QrCode,
  textBoxCheckOutline as TextBoxCheckOutline,
  tractorVariant as TractorVariant,
  shape as Shape,
} from '../img/icons';
import { Logo } from './logo';
import { SubMenu } from './submenu';

type MenuName = 'menuInspections' | 'menuTemplates' | 'userManagement';

const canUseTemplates = [
  'role.centraladmin',
  'role.dealeradmin',
  'role.devadmin',
];

const canUseDepartments = [
  'role.centraladmin',
  'role.devadmin',
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'inhreit',
  },
}));

interface MenuProps {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

type MenuState = {
  menuInspections: boolean;
  menuTemplates: boolean;
  menuGroups: boolean;
  userManagement: boolean;
};

export const BasicMenu: FC<MenuProps> = ({ dense }) => {
  const classes = useStyles();

  const [state, setState] = useState<MenuState>({
    menuInspections: false,
    menuTemplates: false,
    menuGroups: false,
    userManagement: false,
  });

  const translate = useTranslate();
  const match = useLocation();
  const { permissions } = usePermissions();

  const dispatch = useDispatch();
  const open: boolean = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const menuDefaultState: MenuState = useMemo(
    () => ({
      menuInspections: false,
      menuTemplates: false,
      menuGroups: false,
      userManagement: false,
    }),
    [],
  );

  useEffect(() => {
    if (Object.values(state).some((value) => !!value)) {
      dispatch(setSidebarVisibility(true));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (!open) {
      setState(menuDefaultState);
    }
  }, [open, menuDefaultState]);

  useEffect(() => {
    setState(() => ({
      menuInspections: match.pathname.indexOf('/inspections') === 0,
      menuTemplates: match.pathname.indexOf('/inspection-templates') === 0,
      userManagement: match.pathname.indexOf('/users') === 0,
      menuGroups: match.pathname.indexOf('/inspections/groups') === 0,
    }));
  }, [match]);

  const handleToggle = (menu?: MenuName) => {
    if (menu) {
      setState({
        ...state,
        [menu]: !state[menu],
      });
    } else {
      setState(menuDefaultState);
    }
  };

  return (
    <Box className={classes.container}>
      <Logo />
      <MenuItemLink
        to="/machines"
        primaryText={translate('menu.machines')}
        leftIcon={<TractorVariant />}
        onClick={() => handleToggle()}
      />
      {(canUseTemplates.includes(permissions?.role)) && (
        <SubMenu
          handleToggle={() => handleToggle('menuTemplates')}
          isOpen={state.menuTemplates}
          sidebarIsOpen={true}
          name="menu.inspectionTemplates"
          icon={<ClipboardListOutline />}
          dense={dense}
        >
          <MenuItemLink
            to="/inspection-templates"
            primaryText={translate('menu.published')}
            leftIcon={<CheckDecagram />}
            color="red"
            exact
          />
          <MenuItemLink
            to="/inspection-templates/archive"
            primaryText={translate('menu.archived')}
            leftIcon={<Archive />}
            exact
          />
        </SubMenu>
      )}
      <SubMenu
        handleToggle={() => handleToggle('menuInspections')}
        isOpen={state.menuInspections}
        sidebarIsOpen={true}
        name="menu.inspections"
        icon={<TextBoxCheckOutline />}
        dense={dense}
      >
        <MenuItemLink
          to="/inspections"
          primaryText={translate('menu.active')}
          leftIcon={<CheckDecagram />}
          exact
        />
        <MenuItemLink
          to="/inspections/archive"
          primaryText={translate('menu.archived')}
          leftIcon={<Archive />}
          exact
        />
        <MenuItemLink
          to="/inspections/groups"
          primaryText={translate('menu.groups')}
          leftIcon={<Shape />}
        />
      </SubMenu>

      {dashboardConfig.qrCodeResourceFlag && (
        <MenuItemLink
          to="/qr-codes"
          primaryText={'QR Codes'}
          leftIcon={<QrCode />}
          onClick={() => handleToggle()}
        />
      )}
      <MenuItemLink
        to="/users"
        primaryText={translate('menu.users')}
        leftIcon={<UsersIcon />}
        onClick={() => handleToggle()}
      />
      {canUseDepartments.includes(permissions?.role) && (
        <MenuItemLink
          to="/departments"
          primaryText={translate('menu.departments')}
          leftIcon={<DepartmentIcon />}
          onClick={() => handleToggle()}
        />
      )}
    </Box>
  );
};
