import * as React from 'react';
import {
  ArrayInput,
  Create,
  CreateProps,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';

export const UserCreate = (props: CreateProps) => {
  const { basePath, resource } = props;
  const translate = useTranslate();
  return (
    <Create basePath={basePath} resource={resource}>
      <SimpleForm>
        <TextInput source="email" validate={required()} />
        <ReferenceInput source="role" reference="roles">
          <SelectInput
            optionText={(record: any) => translate(record.id)}
            validate={required()}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) =>
            ['role.dealeruser', 'role.dealeradmin'].includes(
              formData?.role,
            ) && (
              <ArrayInput source="departments" validate={required()}>
                <SimpleFormIterator>
                  <ReferenceInput
                    label={translate('resources.departments.department')}
                    source="id"
                    reference="departments"
                  >
                    <SelectInput
                      optionText="name"
                      optionValue="id"
                      validate={required()}
                    />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
