import polyglotI18nProvider from 'ra-i18n-polyglot';
import { TranslationMessages } from 'ra-core';
import { resolveBrowserLocale } from 'react-admin';
import messages from './locales';

const resolveLocale = (defaultLocale?: string): string =>
  localStorage.getItem('ra-locale') || resolveBrowserLocale(defaultLocale);

export const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale] as TranslationMessages,
  resolveLocale(),
);

export default i18nProvider;
