import { Auth } from 'aws-amplify';
import mime from 'mime';
import jsonServerProvider from 'ra-data-json-server';
import {
  CreateParams,
  CreateResult,
  DataProvider,
  fetchUtils,
} from 'react-admin';
import { API_URL } from './config';
import {
  ConfigImagesChangeEvent,
  ImagesInputValue,
  SpecComponentChangeEvent,
  StatusInputChangeEvent,
  StatusInputValue,
} from './pages/templates';

const imagesFields = ['images', 'helperImages'] as const;

const dataProvider = jsonServerProvider(API_URL, async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers = new Headers(options.headers);
  let session;
  try {
    session = await Auth.currentSession();
  } catch (err) {
    console.log(err);
  }
  if (session)
    options.headers.set(
      'Authorization',
      `Bearer ${session.getIdToken().getJwtToken()}`,
    );
  return fetchUtils.fetchJson(url, options);
});

interface PrepObject {
  images: ImagesInputValue[];
  prepObj?: (PrepObject | null)[];
}

const createImagesMapFunction =
  (images: ImagesInputValue[]) => (image: ImagesInputValue) => {
    if (
      !image ||
      !(image.placeholder instanceof File || image.placeholder instanceof Blob)
    ) {
      return { ...image, placeholder: null };
    }

    images.push(image);

    const extension = mime.getExtension(image.placeholder.type);

    const placeholder = {
      ref: 'AUTO_UNIQUE_STRING',
      extension: extension === 'jpeg' ? 'jpg' : extension,
    };
    return { ...image, placeholder };
  };

const prepareImageUpload = (
  spec: SpecComponentChangeEvent,
): [SpecComponentChangeEvent, PrepObject | null] => {
  // If there are images, create:
  // - A new spec where the image refs are AUTO_UNIQUE_STRING
  // - An array of all the image Files
  const images: ImagesInputValue[] = [];
  const imagesMapFunction = createImagesMapFunction(images);
  const newSpec = { ...spec };

  const attachImageWithPlaceholder = (field: string) => {
    const Images = (spec.config as ConfigImagesChangeEvent)?.[field] ?? [];
    const imagesWithPlaceholders = Images.map(imagesMapFunction);
    newSpec.config = {
      ...newSpec.config,
      [field]: [...imagesWithPlaceholders],
    };
  };

  for (const field of imagesFields) {
    const hasImage = Boolean(
      (spec.config as ConfigImagesChangeEvent)?.[field]?.length > 0,
    );

    if (hasImage) {
      attachImageWithPlaceholder(field);
    }
  }

  if (newSpec?.spec?.length) {
    // If there is a nested spec, go through each of the sub specs:
    // - Run this function on each subspec
    // - Gather all the results together and return them
    const [nestedSpecs, prepObj] = newSpec.spec.reduce(
      (acc: [SpecComponentChangeEvent[], (PrepObject | null)[]], cur) => {
        const v = prepareImageUpload(cur);
        return [
          [...acc[0], v[0]],
          [...acc[1], v[1]],
        ];
      },
      [[], []],
    );
    newSpec.spec = nestedSpecs;
    return [
      newSpec,
      {
        images,
        prepObj,
      },
    ];
  } else {
    // If there are no nested spec, just return the spec with images
    return [newSpec, { images }];
  }
};

const createUploadImage =
  (resource: string, inspectionTemplate: any, prepObj: PrepObject | null) =>
  async (image: ImagesInputValue, index: number) => {
    const file = prepObj?.images[index]?.placeholder;
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    if (
      !(
        image.placeholder instanceof File || image.placeholder instanceof Blob
      ) &&
      (file instanceof File || file instanceof Blob)
    ) {
      const formData = new FormData();
      formData.append('file', file);

      await fetch(
        `${API_URL}/${resource}/${inspectionTemplate.data.id}/upload/${image.placeholder?.ref}`,
        {
          method: 'PUT',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ).catch((e) => console.error(e));
    } else {
      console.log('error with files');
    }
  };

const uploadWithPrep = async (
  resource: string,
  inspectionTemplate: any,
  spec: SpecComponentChangeEvent,
  prepObj: PrepObject | null,
) => {
  // If there are images, create the upload function and fun it on each image
  const uploadImage = createUploadImage(resource, inspectionTemplate, prepObj);
  let cnt = 0;
  for (const field of imagesFields) {
    if ((spec.config as ConfigImagesChangeEvent)?.[field]?.length > 0) {
      const images = (spec.config as ConfigImagesChangeEvent)?.[field] ?? [];

      for (const image of images) {
        if (!(image.placeholder instanceof File) && image.placeholder?.ref) {
          await uploadImage(image, cnt);
          cnt++;
        }
      }
    }
  }

  if (spec?.spec?.length) {
    // If there are is a nested spec, call this function on each of the subspecs
    // If those nested specs have images they will then be uploaded from the above clause
    spec.spec.forEach((subSpec, index: number) => {
      uploadWithPrep(
        resource,
        inspectionTemplate,
        subSpec,
        prepObj?.prepObj ? prepObj.prepObj[index] : null,
      );
    });
  } else {
    // If there are no nested specs, return
    return;
  }
};

const removeNullStatus = ({ config, spec }: SpecComponentChangeEvent) => {
  const status: StatusInputValue[] | undefined = (
    config as StatusInputChangeEvent | undefined
  )?.status;

  if (status) {
    status.forEach((statusNote: StatusInputValue) => {
      Object.keys(statusNote).forEach((key: string) => {
        if (statusNote[key] === null) {
          delete statusNote[key];
          config = { ...config, status };
        }
      });
    });
  }

  if (spec) {
    spec.forEach((nestedSpec: SpecComponentChangeEvent) =>
      removeNullStatus(nestedSpec),
    );
  }
};

export default {
  ...dataProvider,
  create: async (
    resource: string,
    params: CreateParams,
  ): Promise<CreateResult> => {
    if (
      resource !== 'inspection-templates' ||
      !params.data ||
      !params.data.spec ||
      params.data.spec.length === 0
    ) {
      return dataProvider.create(resource, params);
    }

    const newSpecs: SpecComponentChangeEvent[] | undefined = [];
    const prepObjects: (PrepObject | null)[] = [];

    let flag = false;

    if (params.data?.spec) {
      params.data.spec.forEach((spec: SpecComponentChangeEvent) => {
        const [newSpec, prepObj] = prepareImageUpload(spec);
        flag = prepObj ? true : flag;
        newSpecs.push(newSpec);
        prepObjects.push(prepObj);
        removeNullStatus(spec);
      });
    }

    if (!flag) {
      return dataProvider.create(resource, params);
    }

    let inspectionTemplate = await dataProvider.create(resource, {
      ...params,
      data: {
        ...params.data,
        spec: newSpecs,
      },
    });
    inspectionTemplate = await dataProvider.getOne(resource, {
      id: inspectionTemplate.data.id,
    });

    if (inspectionTemplate.data?.spec) {
      inspectionTemplate.data.spec.forEach(
        (spec: SpecComponentChangeEvent, index: number) => {
          uploadWithPrep(
            resource,
            inspectionTemplate,
            spec,
            prepObjects[index],
          );
        },
      );
    }

    return inspectionTemplate;
  },
} as DataProvider;
