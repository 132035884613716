import React from 'react';
import {
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  useTranslate,
} from 'react-admin';
import { IconTextArray, IconTextField } from '../../../components';
import { dashboardConfig } from '../../../config';
import { MachinesToolbar } from '../machines-toolbar';
import { DetailTree } from './detail-tree';

export const MachineShow = (props: ShowProps) => {
  const { basePath, id, resource } = props;
  const translate = useTranslate();
  return (
    <Show
      actions={<MachinesToolbar basePath={basePath} id={id} type="show" />}
      className="machine-show-container"
      basePath={basePath}
      resource={resource}
      id={id}
    >
      <TabbedShowLayout className="show-custom-layout">
        <Tab label={translate('resources.machines.machine')}>
          <IconTextField iconType="manufacturer" source="manufacturer" />
          <IconTextField iconType="category" source="category" />
          <IconTextField iconType="model" source="model" />
          <IconTextField iconType="template" source="inspection_template_id" />
          <IconTextField iconType="date" fieldType="date" source="createdAt" />
          <IconTextField iconType="date" fieldType="date" source="updatedAt" />
          <IconTextArray iconType="allowed_serial" source="serials.allowed" />
          <IconTextArray iconType="template" source="labels" />
          <DetailTree source="machine_details" />
        </Tab>
        {dashboardConfig.machineContactFlag && (
          <Tab
            label={translate('resources.machines.fields.contact')}
            path="contact"
          >
            <IconTextField iconType="contact" source="contact" />
            <IconTextField iconType="company" source="organisation" />
            <IconTextField iconType="phone" source="phone" />
            <IconTextField iconType="email" source="email" />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
