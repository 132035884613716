import { createBrowserHistory as createHistory } from 'history';
import AdminConfig from './flags.json';

type ApiUrl =
  | 'https://codekauf.com/api'
  | 'https://tst.codekauf.com/api'
  | 'http://localhost:8000/api';

const API_URL_PROD: ApiUrl = 'https://codekauf.com/api';
const API_URL_TESTING: ApiUrl = 'https://tst.codekauf.com/api';
const API_URL_DEV: ApiUrl = 'http://localhost:8000/api';

const getApiUrl = (): ApiUrl => {
  switch (String(process.env.REACT_APP_ENV).trim().toLocaleLowerCase()) {
    case 'production':
      return API_URL_PROD;
    case 'testing':
      return API_URL_TESTING;
    default:
      return API_URL_DEV;
  }
};

export const INSPECTION_TEMPLATE_SERVER_URL = `${process.env.REACT_APP_S3BUCKET}/inspection-templates`;
export const INSPECTION_SERVER_URL = `${process.env.REACT_APP_S3BUCKET}/inspections/`;
export const API_URL = `${getApiUrl()}/v1`;
export const UTILITY_API_URL = `${getApiUrl()}/utility`;

export const history = createHistory();

export const checkEnv = (): void => {
  const env = process.env.REACT_APP_ENV;
  const msg =
    '%c.env%c file is not configurated correctly.\n' +
    'Use the %c.env.sample%c file as a starting point.\n' +
    'Supported %c.env%c files:\n' +
    '%c.env.development%c, %c.env.testing%c, %c.env.production%c\n' +
    'More info under: https://create-react-app.dev/docs/adding-custom-environment-variables/';
  const codeStyle = [
    'background-color: black; color: white; padding: 3px 5px; margin: 1px; border-radius: 2px;',
    '',
  ];
  const styles = [
    ...codeStyle,
    ...codeStyle,
    ...codeStyle,
    ...codeStyle,
    ...codeStyle,
    ...codeStyle,
  ];
  if (
    !['development', 'testing', 'production'].includes(env?.toLowerCase() || '')
  ) {
    console.error(
      'Environment variable %cREACT_APP_ENV%c has not been set.\n' + msg,
      ...codeStyle,
      ...styles,
    );
  } else if (
    [
      'REACT_APP_AWS_COGNITO_IDENTITY_POOL',
      'REACT_APP_AWS_COGNITO_REGION',
      'REACT_APP_AWS_COGNITO_IDENTITY_POOL_REGION',
      'REACT_APP_AWS_COGNITO_USER_POOL_ID',
      'REACT_APP_AWS_COGNITO_USER_POOL_WEBCLIENT_ID',
      'REACT_APP_S3BUCKET',
    ].some((k) => !Object.keys(process.env).includes(k))
  ) {
    console.error(msg, ...styles);
  }
};

export const dashboardConfig = {
  damageTypesFlag: AdminConfig.damageTypesFlag,
  statusInfoFlag: AdminConfig.statusInfoFlag,
  ratingFlag: AdminConfig.ratingFlag,
  partsFlag: AdminConfig.partsFlag,
  helperTextFlag: AdminConfig.helperTextFlag,
  signatureFlag: AdminConfig.signatureFlag,
  checkboxesFlag: AdminConfig.checkboxesFlag,
  qrCodeFlag: AdminConfig.qrCodeFlag,
  tutorialFlag: AdminConfig.tutorialFlag,
  statusPartsFlag: AdminConfig.statusPartsFlag,
  videosFlag: AdminConfig.videosFlag,
  importTemplateFlag: AdminConfig.importTemplateFlag,
  assignInspectionFlag: AdminConfig.assignInspectionFlag,
  machineContactFlag: AdminConfig.machineContactFlag,
  qrCodeResourceFlag: AdminConfig.qrCodeResourceFlag,
};
