import { Theme } from '@material-ui/core/styles';
import { applyOverrides } from './overrides';
import { ThemeMode, dashboardThemes } from './theme-modes';

type GetDashboardThemeArgs = {
  savedThemeMode: string;
  defaultMode: ThemeMode;
};

export const getDashboardTheme = ({
  savedThemeMode,
  defaultMode,
}: GetDashboardThemeArgs): Theme => {
  const savedMode: ThemeMode =
    savedThemeMode === 'DARK' || savedThemeMode === 'LIGHT'
      ? savedThemeMode
      : defaultMode;
  const savedTheme = dashboardThemes[savedMode];
  return applyOverrides(savedTheme);
};
