import React, { ReactNode, useMemo } from 'react';
import { Datagrid, List } from 'react-admin';
import { useSelector } from 'react-redux';
import { BulkArchiveToggleButton } from './bulk-archive-toggle-button';

const ArchiveBulkActions = ({ selectedIds, resource }: any) => (
  <BulkArchiveToggleButton
    areArchived={true}
    selectedIds={selectedIds}
    resource={resource}
  />
);

interface ArchiveProps {
  class: string;
  match: any;
  options: object;
  resource: string;
  children: ReactNode;
}

export const Archive = ({
  resource,
  match,
  children,
  class: className,
}: ArchiveProps): JSX.Element => {
  const resourceData = useSelector(
    (state: any) => state.admin.resources[resource],
  );
  const basePath = match ? match.path : '';
  
  return useMemo(() => {
    if (!resourceData) {
      return <></>;
    }
    return (
      <List
        filter={{ archived: true }}
        basePath={basePath.replace(/\/archive$/, '')}
        resource={resource}
        bulkActionButtons={<ArchiveBulkActions />}
        {...resourceData.props}
      >
        <Datagrid className={className || 'defaultDataGrid'}>
          {children}
        </Datagrid>
      </List>
    );
  }, [basePath, children, className, resource, resourceData]);
};
