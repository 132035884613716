import * as React from 'react';
import {
  Edit,
  EditProps,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const DepartmentEdit = ({
  id,
  basePath,
  resource,
  className,
}: EditProps): JSX.Element => {
  return (
    <Edit
      basePath={basePath}
      resource={resource}
      id={id}
    >
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
