/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl, InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import {
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate
} from 'react-admin';
import { DataObject } from '../../types.inspections';
import { statuses } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    inputLabel: {
      top: 5,
      left: 5,
    },
  }),
);

export function StatusSelect({
  record,
  resource,
}: {
  record: DataObject;
  resource: string;
}) {
  const classes = useStyles();
  const [status, setStatus] = React.useState(record.status);
  const notify = useNotify();
  const translate = useTranslate();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(Number(event.target.value));
  };

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  React.useEffect(() => {
    async function asyncBootstrap() {
      try {
        await dataProvider.update(resource, {
          id: record.id,
          data: { ...record, status },
          previousData: record,
        });
      } catch (err) {
        const error = err as any;
        notify(
          error?.body?.message ? translate(error.body.message) : error?.status,
        );
      }

      refresh();
    }
    asyncBootstrap();
  }, [status]);

  return (
    <FormControl className={classes.formControl} size="small">
      <InputLabel
        id="demo-simple-select-helper-label"
        className={classes.inputLabel}
      >
        {translate('dropdown.status')}
      </InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        value={status}
        onChange={handleChange}
        variant="filled"
      >
        <MenuItem value={0}>{translate('inspections.status.none')}</MenuItem>
        {statuses.map((status) => (
          <MenuItem key={status.toString()} value={status.value}>
            {translate(status.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
