import React from 'react';
import { Media as MediaType } from '../../../types.inspections';
import { MediaWithText } from './media-with-text';

interface MediaProps {
  id: number;
  images: MediaType[];
  damages: MediaType[];
  videos: MediaType[];
}

export const Media = ({ id, images, damages, videos }: MediaProps) => (
  <>
    {images?.map((image: MediaType) => (
      <MediaWithText image={image} id={id} />
    ))}
    {damages?.map((damage: MediaType) => (
      <MediaWithText image={damage} id={id} />
    ))}
    {videos?.map((video: MediaType) => (
      <div className="column">
        <MediaWithText image={video} id={id} isVideo />
      </div>
    ))}
  </>
);
