import { Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { Show, useGetOne, useReference } from 'react-admin';
import { Data } from './data';
import { Details } from './details';
import { Header } from './header';
import { defaultPdfState, PdfContext, PdfState } from './pdf/components';

export const InspectionShow = (props: object) => (
  <Show {...props} className="overview-wrapper">
    <Overview {...props} />
  </Show>
);

function Overview(props: any) {
  const {
    loading,
    loaded,
    data: machine,
  } = useGetOne('machines', props?.record?.machine_id);

  const machineName: string =
    machine && `${machine.manufacturer} ${machine.category} ${machine.model}`;

  const [PdfState, setPdfState] =
    useState<Omit<PdfState, 'setState'>>(defaultPdfState);

  return (
    <PdfContext.Provider value={{ ...PdfState, setState: setPdfState }}>
      <Header
        loading={loading}
        loaded={loaded}
        machine={machine}
        machineName={machineName}
        {...props}
      />
      <Details record={props.record} />
      <Divider />
      <Data record={props.record} />
    </PdfContext.Provider>
  );
}
