import { Typography, makeStyles } from '@material-ui/core';
import { INSPECTION_TEMPLATE_SERVER_URL } from 'config';
import { ImageConfig } from 'pages/inspections/types.inspections';
import PLACEHOLDER_IMAGE from '../../../img/no_picture_placeholder.png';
import React, { ReactNode } from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    width: 100,
    height: 100,
    display: 'block',
  },
}));

interface TitleTextProps {
  id: number;
  type: string;
  helperText?: string;
  helperType?: string;
  helperImages: ImageConfig[];
  children: ReactNode;
}

export function TitleText({
  id,
  type,
  helperText,
  children,
  helperType,
  helperImages,
}: TitleTextProps) {
  const translate = useTranslate();
  const classes = useStyles();
  let helperContent: JSX.Element | string | undefined = undefined;
  switch (helperType) {
    case 'link':
      helperContent = <a href={helperText}>{helperText}</a>;
      break;
    case 'image':
      if (!helperImages[0].placeholder) {
        helperContent = (
          <img
            className={classes.placeholder}
            src={PLACEHOLDER_IMAGE}
            alt={'none'}
          />
        );
      } else {
        const { ref, extension } = helperImages[0].placeholder;
        helperContent = (
          <img
            className={classes.placeholder}
            alt={`${helperImages[0].title ?? ''} - ${
              helperImages[0].subtitle ?? ''
            }`}
            src={`${INSPECTION_TEMPLATE_SERVER_URL}/${id}/${ref}.${extension}`}
          />
        );
      }
      break;
    default:
      helperContent = helperText;
  }
  return (
    <>
      <Typography variant="subtitle2">
        » {translate(`config.${type}`)}
      </Typography>
      {helperContent && (
        <Typography variant="subtitle2">
          {'\u00A0'}
          {translate('resources.inspection-templates.fields.helper_title')} :
          {'\u00A0'}
          {helperContent}
        </Typography>
      )}
      {children}
    </>
  );
}
