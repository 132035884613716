import React, { ChangeEvent, useState } from 'react';
import {
  Create,
  CreateProps,
  DateInput,
  FormTab,
  NumberInput,
  required,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
  useQuery,
  useTranslate
} from 'react-admin';
import { MachineDetail } from '../types.inspections';
import {
  createMachineChoices,
  createUserChoices,
  machineByIdQuery,
  machinesQuery,
  usersQuery
} from '../utils';
import { CreateToolbar } from './toolbar';

export function InspectionCreate(props: CreateProps) {
  const { basePath, resource } = props;
  const translate = useTranslate();
  const [selectedMachineId, setSelectedMachineId] = useState<number>(0);

  const { data: machines } = useQuery(machinesQuery);
  const { data: users } = useQuery(usersQuery);
  const { data: selectedMachine } = useQuery(
    machineByIdQuery(selectedMachineId),
  );

  const { machine_details = [] } = selectedMachine ?? {};

  const handleMachineSelect = (event: ChangeEvent<{ value: unknown }>) =>
    setSelectedMachineId(event.target.value as number);

  const showInspectionDetails = Boolean(machine_details?.length);
  return (
    <Create basePath={basePath} resource={resource}>
      <TabbedForm toolbar={<CreateToolbar selectedMachine={selectedMachine} />}>
        <FormTab label={translate('resources.inspections.fields.machine_id')}>
          <SelectInput
            onChange={handleMachineSelect}
            source="machine_id"
            choices={createMachineChoices(machines)}
            validate={required()}
          />
          <SelectArrayInput
            source="assignee"
            choices={createUserChoices(users)}
          />
        </FormTab>

        <FormTab
          label={translate('resources.inspections.fields.inspection_data')}
          path="details"
        >
          <TextInput source="serial" />
          <TextInput source="operating_hours" />
          <TextInput source="inspector" />
          <TextInput source="inspection_location" />
          {showInspectionDetails &&
            machine_details.map(({ label, type, example }: MachineDetail) => {
              switch (type) {
                case 'text':
                  return (
                    <TextInput
                      source={`inspection_details.${label}`}
                      label={label}
                      placeholder={example}
                      key={label}
                    />
                  );
                case 'number':
                  return (
                    <NumberInput
                      source={`inspection_details.${label}`}
                      label={label}
                      placeholder={example}
                      key={label}
                    />
                  );
                case 'date':
                  return (
                    <DateInput
                      source={`inspection_details.${label}`}
                      label={label}
                      key={label}
                    />
                  );
              }
              return null;
            })}
        </FormTab>
      </TabbedForm>
    </Create>
  );
}
