import { Typography } from '@material-ui/core';
import React from 'react';
import { ItemShowProps } from './types';

export const CheckboxesShow = ({
  config: { checkboxes = [] } = {},
}: ItemShowProps) => (
  <>
    {checkboxes.map((checkboxValue: string) => (
      <Typography variant="subtitle2">
        {'\u00A0\u00A0- '}
        {checkboxValue}
      </Typography>
    ))}
  </>
);
