import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { SinglePart } from '../../../types.inspections';
import { requestQuote, checkQuotePossible } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  partContainer: { paddingTop: '10px' },
  text: { paddingTop: '3px' },
  button: {
    marginTop: '10px',
    borderRadius: theme.spacing(0.5),
    height: '55px',
    borderWidth: '1px',
    borderColor: theme.palette.primary.dark,
    borderStyle: 'dashed',
  },
}));

interface JsonResponse {
  status: number;
  ok: boolean;
  redirected: boolean;
  statusText: string;
  url: string;
  type: string;
  bodyUsed: boolean;
}

export const Parts = ({
  parts,
  id,
}: {
  id: number;
  parts?: SinglePart[] | null;
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  const [quoteResponse, setQuoteResponse] = useState<JsonResponse | undefined>(
    undefined,
  );
  const [quotePossibleResponse, setQuotePossibleResponse] = useState<
    JsonResponse | undefined
  >(undefined);

  const [quoteSent, setQuoteSent] = useState<boolean>(false);

  useEffect(() => {
    checkQuotePossible({ id, setQuotePossibleResponse });
  }, [id]);

  const handleRequestQuote = () => {
    if (parts) {
      setQuoteSent(true);
      requestQuote({ parts, id, setQuoteResponse });
    }
  };

  const displaySendEmail = Boolean(
    quotePossibleResponse?.status === 200 && !quoteResponse,
  );

  const message =
    !displaySendEmail && quoteResponse
      ? quoteResponse.status === 200
        ? `parts.success`
        : `parts.error`
      : 'parts.toRequestQuote';
  return parts ? (
    <>
      {parts.map(({ number, description, quantity }: SinglePart) => (
        <div className={classes.partContainer}>
          <Typography variant="subtitle2" className={classes.text}>
            {translate(`parts.number`)} : {number}
          </Typography>
          <Typography variant="subtitle2" className={classes.text}>
            {translate(`parts.quantity`)} : {quantity}
          </Typography>
          <Typography variant="subtitle2" className={classes.text}>
            {translate(`parts.description`)} : {description}
          </Typography>
        </div>
      ))}

      {displaySendEmail ? (
        <Button
          disabled={quoteSent}
          className={classes.button}
          onClick={handleRequestQuote}
        >
          <Typography>{translate('parts.requestQuote')}</Typography>
        </Button>
      ) : (
        <Typography variant="subtitle2" className={classes.partContainer}>
          {translate(message)}
        </Typography>
      )}
    </>
  ) : null;
};
