import { Link, Typography } from '@material-ui/core';
import React from 'react';

export const QRCodes = ({ qrCodes }: { qrCodes?: string | null }) =>
  qrCodes ? (
    qrCodes.startsWith('http') ? (
      <Link target="_blank" rel="noopener" href={qrCodes}>
        - {qrCodes}
      </Link>
    ) : (
      <Typography variant="subtitle2">- {qrCodes}</Typography>
    )
  ) : null;
