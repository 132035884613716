import React from 'react';
import {
  Create,
  required,
  TextInput,
  SimpleForm,
  CreateProps,
} from 'react-admin';

export function QRCodeCreate({ basePath, resource }: CreateProps) {
  return (
    <Create basePath={basePath} resource={resource}>
      <SimpleForm>
        <TextInput source="serial" validate={required()} />
        <TextInput source="category" validate={required()} />
        <TextInput source="build_year" type={'number'} validate={required()} />
        <TextInput source="model" validate={required()} />
      </SimpleForm>
    </Create>
  );
}
