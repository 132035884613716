import React from 'react';
import {
  ArrayInput,
  Create,
  CreateProps,
  email,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import { dashboardConfig } from '../../../config';
import { MachinesToolbar } from '../machines-toolbar';
import { detailTypes, phoneValidation } from '../utils';

export function MachineCreate(props: CreateProps) {
  const { basePath, resource } = props;
  const translate = useTranslate();
  const choices = detailTypes.map((id) => ({
    id,
    name: translate(`resources.machines.fields.${id}`),
  }));
  return (
    <Create
      actions={<MachinesToolbar basePath={basePath} type="create" />}
      basePath={basePath}
      resource={resource}
    >
      <TabbedForm>
        <FormTab label={translate('resources.machines.fields.machine_details')}>
          <TextInput source="manufacturer" validate={required()} />
          <TextInput source="category" validate={required()} />
          <TextInput source="model" validate={required()} />
          <ReferenceInput
            source="inspection_template_id"
            reference="inspection-templates"
            validate={required()}
            filter={{ archived: false }}
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>
        </FormTab>

        <FormTab
          label={translate('resources.machines.fields.header_data')}
          path="details"
        >
          <ArrayInput source="machine_details" defaultValue={[]}>
            <SimpleFormIterator>
              <TextInput source="label" validate={required()} />
              <SelectInput
                source="type"
                choices={choices}
                validate={required()}
              />
              <TextInput source="example" defaultValue="" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        {dashboardConfig.machineContactFlag && (
          <FormTab
            label={translate('resources.machines.fields.contact')}
            path="contact"
          >
            <TextInput source="contact" />
            <TextInput source="organisation" />
            <TextInput
              source="phone"
              validate={phoneValidation(translate('ra.validation.phone'))}
            />
            <TextInput source="email" validate={email()} />
          </FormTab>
        )}
        {dashboardConfig.machineContactFlag && (
          <FormTab
            label={translate('resources.machines.fields.inspection_fields')}
            path="inspection_details"
          >
            <TextInput
              disabled
              source={translate('resources.inspections.fields.serial')}
            />
            <TextInput
              disabled
              source={translate(
                'resources.inspections.fields.operating_hours',
              )}
            />
            <TextInput
              disabled
              source={translate('resources.inspections.fields.inspector')}
            />
            <TextInput
              disabled
              source={translate(
                'resources.inspections.fields.inspection_location',
              )}
            />
            <TextInput
              disabled
              source={translate('resources.inspections.fields.inspection_date')}
            />
          </FormTab>
        )}
        {dashboardConfig.machineContactFlag && (
          <FormTab
            label={translate('resources.machines.fields.serials.allowed')}
            path="serials"
          >
            <ArrayInput source="serials.allowed">
              <SimpleFormIterator>
                <TextInput
                  source=""
                  label={translate('resources.machines.fields.serials.serial')}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        )}

        <FormTab
          label={translate('resources.machines.fields.labels.allowed')}
          path="labels"
        >
          <ArrayInput source="labels">
            <SimpleFormIterator>
              <TextInput
                source=""
                label={translate('resources.machines.fields.labels.label')}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}
