import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'ra-ui-materialui';
import { useTranslate, Record } from 'ra-core';

import { useResendPassword } from 'hooks/useResendPassword';
import { useCountDown } from 'hooks/useCountDown';

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginLeft: theme.spacing(1),
  },
}));

interface ResetPasswordButtonProps {
  record?: Record;
}

export const ResendPasswordButton = ({ record }: ResetPasswordButtonProps) => {
  const { mutateAsync, isLoading, isSuccess } = useResendPassword();
  const countdown = useCountDown(60);
  const translate = useTranslate();
  const classes = useStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (isSuccess ? countdown.start() : null), [isSuccess]);

  return (
    <Button
      label={
        countdown.isCounting
          ? translate('action.resend_password') + ` (${countdown.timeLeft}s)`
          : translate('action.resend_password')
      }
      onClick={() => mutateAsync(record?.email)}
      disabled={!record?.email || isLoading || countdown.isCounting}
    >
      {isLoading && (
        <>
          <CircularProgress
            size={16}
            thickness={3}
            className={classes.spinner}
          />
        </>
      )}
    </Button>
  );
};
