import { regex } from 'react-admin';

export interface MachinesWithInspection {
  [key: number]: number[];
}

export const makeMachinesWithInspections = (inspections: any) => {
  const machinesWithInspections: MachinesWithInspection = {};
  inspections?.forEach(({ machine_id, id }: any) => {
    if (Object.keys(machinesWithInspections).includes(machine_id.toString())) {
      machinesWithInspections[machine_id].push(id);
    } else {
      machinesWithInspections[machine_id] = [id];
    }
  });
  return machinesWithInspections;
};

export const detailTypes = ['text', 'number', 'date'];

export const phoneValidation = (message: string) =>
  regex(/^\+(?:[0-9] ?){6,14}[0-9]$|[0-9]{6,14}$/, message);
