import React, { FC } from 'react';
import { Layout, LayoutProps } from 'react-admin';
import { CustomAppBar } from './appbar';
import { BasicMenu } from './menu';

export const CustomLayout: FC<LayoutProps> = ({ theme, ...props }) => {
  return (
    <Layout {...props} appBar={CustomAppBar} menu={BasicMenu} theme={theme} />
  );
};
