import { Accordion, AccordionDetails } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
import {
  Config,
  DataInstance,
  DataObject,
  SpecificationInt,
} from '../../types.inspections';
import { AccordionCover } from './accordion-cover';
import { Popup } from './popup';
import { TableHeader } from './table-header';
import { TableRow } from './table-row';
import { Status } from './status';

interface DataTableProps {
  slug: string;
  label: string;
  type: string;
  spec: SpecificationInt[];
  record: DataObject;
  task_id: number;
  icon?: string;
  config: Config;
}

const determineDisplayButton = ({
  images,
  damages,
  videos,
  error_codes,
  status,
  parts,
  checkboxes,
  qr_codes,
  free_input,
}: DataInstance) =>
  Boolean(
    images ||
      damages ||
      videos ||
      error_codes?.length ||
      status === 2 ||
      parts?.length ||
      checkboxes?.length ||
      qr_codes?.length ||
      free_input?.length,
  );

export const DataTable = ({
  slug,
  label,
  type,
  spec,
  config,
  record,
  icon,
  record: { data },
}: DataTableProps) => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [pointer, selectPointer] = React.useState('');
  const [itemConfig, setItemConfig] = React.useState(config);
  const [title, setTitle] = React.useState('');

  const handleOpen = (
    pointer: string,
    title: string,
    nestedConfig?: Config,
  ) => {
    setTitle(`config.${title}`);
    selectPointer(pointer);
    setOpen(true);
    if (typeof nestedConfig !== 'undefined') {
      setItemConfig(nestedConfig);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const itemData = data?.[slug];
  const rows = spec
    ? spec.map((nestedSpec: SpecificationInt, index: number) => {
        const nestedSlug = [slug, nestedSpec.slug].filter(Boolean).join('.');
        const nestedData = data?.[nestedSlug];
        return nestedSpec.type === 'tutorial'
          ? null
          : TableRow({
              label: nestedSpec.label,
              onClick: () => {
                handleOpen(nestedSlug, nestedSpec.type, nestedSpec.config);
              },
              index,
              displayButton: nestedData && determineDisplayButton(nestedData),
              itemStatus: Status({ data: nestedData }),
            });
      })
    : TableRow({
        label,
        onClick: () => handleOpen(slug, type),
        displayButton: itemData && determineDisplayButton(itemData),
        itemStatus: Status({ data: itemData }),
      });
  const taskData = data?.[pointer];
  return type === 'tutorial' ? null : (
    <Accordion className="panel-container">
      <AccordionCover label={label} slug={slug} icon={icon} />
      <AccordionDetails>
        {spec || type ? (
          TableHeader({
            translate,
            rows,
          })
        ) : (
          <p>{translate('specification.nodata')}</p>
        )}
      </AccordionDetails>
      {taskData && (
        <Popup
          open={open}
          handleClose={handleClose}
          title={translate(title)}
          paperClass="gallery-popup"
          taskData={taskData}
          id={record.id}
          config={itemConfig}
        />
      )}
    </Accordion>
  );
};
