import React from 'react';
import { DamagesInput } from './damages';
import { ImagesInput } from './images';
import { ImagesDamagesInputProps } from '../../types';

export const ImagesDamagesInput = ({ source }: ImagesDamagesInputProps) => {

  return (
    <div>
      <ImagesInput source={source} />
      <DamagesInput source={source} />
    </div>
  );
};
