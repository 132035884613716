import { CRUD_UPDATE, Record, useNotify, useRefresh, useUpdate } from 'ra-core';
import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { LinearProgress } from 'ra-ui-materialui';
import React, { useCallback } from 'react';
import { useQuery } from 'react-admin';
import { groupsQuery } from 'pages/groups/utils';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 60,
  },
  select: {
    padding: theme.spacing(1),
  }
}));

interface GroupIdInputProps {
  resource?: string;
  record?: Record;
}

const useGroupIdController = ({
  resource,
  record,
}: GroupIdInputProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [_updateGroupId, { loading }] = useUpdate(
    resource as string,
    (record as Record)?.id,
    { ...record, group_id: null as number|null, serial: record.serial },
    record,
    {
      action: CRUD_UPDATE,
      onSuccess: () => {
        notify(
          'notification.ungrouped',
          'info',
          { smart_count: 1 },
        );
        refresh();
      },
      onFailure: (error: any) => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        );
      },
    },
  );

  const updateGroupId = useCallback((props: {group_id: number, serial: string}) => {
    _updateGroupId(resource as string, (record as Record)?.id, {
       ...record, group_id: props.group_id, serial: props.serial 
      },record);
  }, [_updateGroupId, record,resource]);

  return {
    updateGroupId,
    loading,
  };
};

export const GroupIdInput = ({
    record,
    resource,
  }: GroupIdInputProps) => {
    const classes = useStyles();
    const group_id = record?.group_id
    const { data: groups, loading: queryGroupsLoading } = useQuery(groupsQuery);
    const { updateGroupId, loading: updateGroupIdloading } = useGroupIdController({
      resource,
      record,
    });

    const loading = queryGroupsLoading || updateGroupIdloading;

    const handleChangeGroupId = (event: React.ChangeEvent<{
      name?: string;
      value: number;
    }>) => {
      const newGroupId = event.target.value as number;
      const newSerial = groups?.find((group) => group.id === newGroupId)?.serial
      updateGroupId({group_id: newGroupId, serial: newSerial});
    }
  
  
    return (
      <FormControl className={classes.formControl}>
        {loading?
          <LinearProgress/>:
          <Select
            defaultValue={group_id}
            value={group_id}
            id="group-id-select"
            onChange={handleChangeGroupId}
          >
            {groups?.map(({ id, serial }) => (
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            ))}
          </Select>}
      </FormControl>
         
    );
  };