import React from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
  ListProps,
} from 'react-admin';

export function QRCodeList({ basePath, resource, hasCreate }: ListProps) {
  return (
    <List
      basePath={basePath}
      resource={resource}
      bulkActionButtons={false}
      hasCreate={hasCreate}
    >
      <Datagrid className='defaultDataGrid'>
        <TextField source="serial" />
        <TextField source="category" />
        <TextField source="model" />
        <TextField source="build_year" />
        <ShowButton />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
