import React from 'react';
import { Config } from '../../inspections/types.inspections';
import { CheckboxesShow } from './checkboxes';
import { DamagesShow } from './damages';
import { FreeInputShow } from './free-input';
import { ImagesShow } from './images';
import { PartsShow } from './parts';
import { StatusShow } from './status';
import { TutorialShow } from './tutorial';

interface ConfigProps {
  id?: number;
  type?: string;
  config: Config;
}

export function ConfigShow({ id, type, config }: ConfigProps) {
  switch (type) {
    case 'images':
    case 'videos':
      return <ImagesShow id={id} config={config} />;
    case 'damages':
      return <DamagesShow config={config} />;
    case 'images-damages':
      return (
        <>
          <ImagesShow id={id} config={config} />
          <DamagesShow config={config} />
        </>
      );
    case 'status':
      return <StatusShow config={config} />;
    case 'parts':
      return <PartsShow config={config} />;
    case 'checkboxes':
      return <CheckboxesShow config={config} />;
    case 'tutorial':
      return <TutorialShow id={id} config={config} />;
    case 'status-parts':
      return (
        <>
          <PartsShow config={config} />
          <ImagesShow id={id} config={config} />
        </>
      );
    case 'free-input':
      return <FreeInputShow config={config} />;

    default:
      return null;
  }
}
