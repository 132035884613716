import React, { FC } from 'react';
import { Show, SimpleShowLayout, useGetOne, ShowProps } from 'react-admin';
import { IconTextField, QR } from '../../components';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';

export const QRCodeShow: FC<ShowProps> = ({ id, basePath, resource }) => {
  const { data, loading } = useGetOne('qr-codes', id);
  const styles = useStyles({ loading: loading });

  const getQRDownloadName = (qrData: {
    [key: string]: any;
  }): string | undefined => {
    if (qrData) {
      const { serial, model, category, build_year } = qrData;
      return `${serial}-${model}-${category}-${build_year}`;
    }
  };
  return (
    <Show basePath={basePath} resource={resource} id={id}>
      <SimpleShowLayout>
        <IconTextField iconType="serial" source="serial" />
        <IconTextField iconType="category" source="category" />
        <IconTextField iconType="model" source="model" />
        <IconTextField iconType="date" source="build_year" />
        <Box className={styles.qrContainer}>
          <Box className={styles.qr}>
            <QR
              id={`${id}-qr-code`}
              data={JSON.stringify(data)}
              downloadedFileName={getQRDownloadName(data)}
              allowDownload={true}
            />
          </Box>
          <Box className={styles.loader}>
            <CircularProgress size={75} thickness={5} />
          </Box>
        </Box>
      </SimpleShowLayout>
    </Show>
  );
};

const useStyles = makeStyles<Theme, { loading: boolean }>((theme) => ({
  qrContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    position: 'relative',
  },
  qr: {
    opacity: ({ loading }) => (loading ? 0.5 : 1),
  },
  loader: ({ loading }) => ({
    display: loading ? 'unset' : 'none',
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%,-50%)',
  }),
}));
