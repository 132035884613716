import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useCookies } from 'react-cookie';
import { dashboardThemes, ThemeMode } from 'themes/theme-modes';

const useStyles = makeStyles((_) => ({
  formControl: {
    width: '100%',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
}));

export const DarkLightModeToggle = () => {
  const [themeCookie, setThemeCookie] = useCookies();
  const currentTheme = dashboardThemes[themeCookie.themeMode as ThemeMode];
  const [themeMode, setThemeMode] = React.useState<'DARK' | 'LIGHT'>(
    currentTheme.palette.type === 'dark' ? 'DARK' : 'LIGHT',
  );

  const classes = useStyles();

  const translate = useTranslate();

  useEffect(() => {
    setThemeCookie('themeMode', themeMode, { path: '/' });
  }, [themeMode, setThemeCookie]);

  const handleModeChange = (_: any, checked: boolean) => {
    setThemeMode(checked ? 'DARK' : 'LIGHT');
  };

  return (
    <FormControlLabel
      className={classes.formControl}
      control={
        <Switch
          color="primary"
          checked={themeMode === 'DARK'}
          onChange={handleModeChange}
          name="darkmode-switch"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      }
      label={translate('theme.dark')}
    />
  );
};
