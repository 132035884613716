import dataProvider from 'data-provider';
import React, { useRef } from 'react';
import {
  ReferenceField,
  CreateButton,
  Datagrid,
  Button,
  List,
  ListActionsProps,
  ListProps,
  ShowButton,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
  useTranslate,
  useNotify,
  useRefresh,
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
  ArchiveToggleButton,
  BulkArchiveToggleButton,
} from '../../../components';
import { CloneButton } from '../clone-button';
import { exportInspections, getTemplateDraft, setTemplateDraft } from '../utils';

const TemplateBulkActions = ({ selectedIds, resource, bulkExportCallback }: any) => (
  <BulkArchiveToggleButton
    areArchived={false}
    selectedIds={selectedIds}
    resource={resource}
    bulkExportCallback={bulkExportCallback}
  />
);

const ImportButton = () => {
  const fileInput = useRef(null);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    fileInput.current.click();
  };

  const readJSONFromFile = (file:File) => {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(JSON.parse(reader.result as string));
      reader.onerror = reject;
    });
  };

  const handleChange = async (event:React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    try {
      const data = await readJSONFromFile(file);
      await dataProvider.create('inspection-templates-import', { data });
      notify(translate('action.import_success'), { type: 'success' });
      refresh();
    } catch (err) {
      notify(translate('action.import_failure'), { type: 'error' });
    }
    event.target.value = '';
  };
  return (<>
    <input
      type="file"
      accept="application/JSON"
      ref={fileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
    />
    <Button
        onClick={handleClick}
        label={translate('action.import')}
    />
  </>);
};

const ContinueButton = (props: { basePath: string }) => {
  const translate = useTranslate();
  const template = getTemplateDraft();
  return (template || null) && (
    <Button
    label={`${translate('action.continue_draft')} ${template.name || ''}`}
    component={Link}
    to={`${props.basePath}/create`}
    />
  );
};

const TemplateListActions = (props: ListActionsProps) => {
  const { className } = props;
  const { basePath } = useListContext(props);
  return (
    <TopToolbar className={className}>
      <ContinueButton basePath={basePath} />
      <ImportButton/>
      <CreateButton onClick={() => setTemplateDraft()} basePath={basePath} />
    </TopToolbar>
  );
};

export const TemplateList = (props: ListProps) => {
  const translate = useTranslate();
  return (
    <List
      filter={{ archived: false }}
      bulkActionButtons={<TemplateBulkActions
        bulkExportCallback={(ids:number[]) => exportInspections(dataProvider, ids)}
      />}
      actions={ <TemplateListActions /> }
      {...props}
    >
      <Datagrid className={'defaultDataGrid'}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField
          source="department_id"
          label={translate('resources.departments.department')}
          reference="departments"
          emptyText={translate('resources.departments.default')}
        >
          <TextField source="name" />
        </ReferenceField>
        <ShowButton />
        <CloneButton />
        <ArchiveToggleButton />
      </Datagrid>
    </List>
  );
};
