import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 120000,
  },
}));

export interface SpinnerModalProps {
  open: boolean;
}

export const SpinnerModal: React.FC<SpinnerModalProps> = (props) => {
  const { open } = props;
  const classes = useStyles();

  if (!open) return null;

  return (
    <div className={classes.container}>
      <CircularProgress color="primary" size={64} />
    </div>
  );
};
