import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeView } from '@material-ui/lab';
import get from 'lodash/get';
import { Record } from 'ra-core';
import * as React from 'react';
import { Detail } from './detail';

interface DetailTreeProps {
  record?: Record;
  source: string;
}

export const DetailTree = ({ source, record }: DetailTreeProps) => {
  const details = get(record ?? {}, source, []);
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {details?.map(Detail)}
    </TreeView>
  );
};
