import React from 'react';
import { FieldTitle, FunctionField } from 'react-admin';
import { IconWrapper } from './icon-wrapper';

interface IconTextArrayProps {
  record?: any;
  source: string;
  resource?: string;
  iconType: string;
}

export const IconTextArray = ({
  iconType,
  record,
  source,
  resource,
}: IconTextArrayProps) => {
  const sourceKeys = source.split('.');

  const textArray = sourceKeys.reduce(
    (acc: any, key: string) => acc?.[key],
    record,
  );
  return (
    <div className="icon-textfield-data">
      <div className="info-textfield-container">
        <IconWrapper iconName={iconType} />
        <FieldTitle source={source} resource={resource} />
      </div>
      <div className="array-textfield-container">
        {textArray?.map((textItem: string) => (
          <FunctionField render={() => textItem} className="data-textfield" />
        ))}
      </div>
    </div>
  );
};
