import React from 'react';
import { TextField, useQuery } from 'react-admin';
import { Archive, ArchiveToggleButton } from '../../../components';
import { inspectionsQuery, machinesQuery } from '../../utils';
import { makeTemplatesWith } from '../utils';
import { DeleteTemplateButton } from './delete-template-button';

interface TemplateArchiveProps {
  match: any;
  options: object;
  class: string;
  record: any;
}

export const TemplateArchive = (props: TemplateArchiveProps): JSX.Element => {
  const { data: inspections } = useQuery(inspectionsQuery);
  const { data: machines } = useQuery(machinesQuery);
  const templatesWithMachines = makeTemplatesWith(machines);
  const templatesWithInspections = makeTemplatesWith(inspections);
  return (
    <Archive resource="inspection-templates" {...props}>
      <TextField source="id" />
      <TextField source="name" />
      <ArchiveToggleButton />
      <DeleteTemplateButton
        templatesWithMachines={templatesWithMachines}
        templatesWithInspections={templatesWithInspections}
        record={props.record}
      />
    </Archive>
  );
};
