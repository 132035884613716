import { TopLevelAdmin } from 'auth/auth';
import { ResendPasswordButton } from 'components/resend-password-button';
import * as React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  ListProps,
  ShowButton,
  TextField,
  usePermissions,
  useTranslate,
} from 'react-admin';

const TranslatedTextField = ({
  record,
  source,
}: {
  record?: any;
  source?: string;
}) => {
  const translate = useTranslate();
  const translatedRecord = source
    ? { ...record, [source]: translate(record[source]) }
    : translate(record);

  return (
    <TextField
      source={source}
      record={translatedRecord}
      emptyText={translate('role.norole')}
    />
  );
};

export const UserList = ({
  basePath,
  resource,
  hasCreate,
  className,
}: ListProps): JSX.Element => {
  const { permissions } = usePermissions();
  const canCreateOrEdit = TopLevelAdmin.includes(permissions?.role);

  return (
    <List
      basePath={basePath}
      resource={resource}
      bulkActionButtons={false}
      hasCreate={canCreateOrEdit && hasCreate}
    >
      <Datagrid className={className || 'defaultDataGrid'}>
        <TextField source="id" />
        <TextField source="email" />
        <TranslatedTextField source="role" />
        <ShowButton />
        {canCreateOrEdit && <EditButton />}
        <ResendPasswordButton />
      </Datagrid>
    </List>
  );
};
