import heic2any from 'heic2any';
import { v4 as uuidv4 } from 'uuid';
import {
  ImagesInputChangeEvent,
  ImagesInputValue,
  StatusInputType,
} from '../../types';

export const MAX_DAMAGE_TYPES = 6;

export const StatusInputTypesEnum: { [key: string]: StatusInputType } = {
  Url: 'url',
  Note: 'note',
  Code: 'code',
};

const httpString = 'http://';
const httpsString = 'https://';

export const addHttpCondition = (
  noteType: StatusInputType,
  noteValue: string,
) =>
  Boolean(
    noteType === StatusInputTypesEnum.Url &&
      noteValue.length > httpString.length &&
      !(noteValue.startsWith(httpString) || noteValue.startsWith(httpsString)),
  );

export const addHttp = (noteValue: string) => httpString + noteValue;

export const removeHttpCondition = (
  noteType: StatusInputType,
  noteValue: string,
) =>
  Boolean(
    noteType !== StatusInputTypesEnum.Url &&
      (noteValue.startsWith(httpString) || noteValue.startsWith(httpsString)),
  );

export const removeHttp = (noteValue: string) =>
  noteValue.substring(
    noteValue.startsWith(httpsString) ? httpsString.length : httpString.length,
  );

const blobToFile = (blobInput: Blob, fileName: string): File => {
  var blobOutput: any = blobInput;
  blobOutput.lastModifiedDate = new Date();
  blobOutput.name = fileName;
  return blobOutput as File;
};

export async function convertHeicPlaceholders(
  inputImages: ImagesInputValue[],
  onChange: (arg: ImagesInputChangeEvent) => void,
) {
  const images = await Promise.all(
    inputImages?.map(async (image: ImagesInputValue) => {
      const { placeholder } = image;
      if (
        placeholder &&
        placeholder instanceof File &&
        placeholder.type === 'image/heif'
      ) {
        const placeholderBlob = await heic2any({
          blob: placeholder,
          toType: 'image/jpeg',
        });
        const placeholderFile = blobToFile(
          placeholderBlob as Blob,
          `${uuidv4()}.jpg`,
        );
        return { ...image, placeholder: placeholderFile };
      }
      return image;
    }),
  );

  onChange({ images });
}
