import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslate, useQuery } from 'react-admin';
import { departmentsQuery } from '../auth/auth';

export interface Department {
  id: number;
  role: string;
  name: string;
}

interface DepartmentSelectInputProps {
  value: number;
  onChange?: (value: number) => void;
  variant?: 'filled' | 'outlined' | 'standard';
  size?: 'small' | 'medium';
}

export const DepartmentSelectInput: React.FC<DepartmentSelectInputProps> = (
  props,
) => {
  const { onChange, value, variant = 'filled', size = 'medium' } = props;
  const translate = useTranslate();
  const departments = useQuery(departmentsQuery);

  useEffect(() => {
    if (!departments.loading && !departments.data) {
      departments.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments.data, departments.loading]);

  return (
    <FormControl variant={variant} size={size} fullWidth>
      <InputLabel id="department-select-label">
        {translate('resources.departments.department')}
      </InputLabel>
      <Select
        id="department-select"
        labelId="department-select-label"
        name="id"
        margin="dense"
        disabled={departments.loading}
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value as number)}
      >
        {(departments.data ?? []).map((d: Department) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
        <MenuItem value={0}>{'Default'}</MenuItem>
      </Select>
    </FormControl>
  );
};
