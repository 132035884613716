import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';

interface TableHeaderProps {
  rows: React.ReactNode;
  translate: (arg1: string) => string;
}

export const TableHeader = ({ rows, translate }: TableHeaderProps) => (
  <Table aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell className={'table-cell'} align="left">
          {translate('specification.function')}
        </TableCell>
        <TableCell className={'table-cell'} align="center">
          {translate('specification.status')}
        </TableCell>
        <TableCell className={'table-cell'} align="right">
          {translate('specification.appearance')}
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>{rows}</TableBody>
  </Table>
);
