import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogueContent: {
    width: '400px',
    maxWidth: '100%',
  },
  subTitle: {
    margin: '0',
    fontSize: '0.9em',
    marginBottom: '10px',
    marginTop: '-7px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface SharedDialogWrapperProps {
  open: boolean;
  title: string;
  subTitle: string;
  handleClose: () => void;
  children?: any;
}

export function SharedDialogWrapper({
  open,
  title,
  subTitle,
  children,
  handleClose,
}: SharedDialogWrapperProps) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        disableTypography
        className={classes.title}
        id="alert-dialog-title"
      >
        <Typography variant="h6">{title}</Typography>
        {handleClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <div className={classes.dialogueContent}>
          <p className={classes.subTitle}>{subTitle}</p>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
}
