import { useEffect, useRef, useState } from 'react';

export const useCountDown = (seconds: number) => {
  const [isCounting, setIsCounting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(seconds);
  let timer = useRef<ReturnType<typeof setInterval> | null>(null);

  const start = () => {
    stop();
    timer.current = setInterval(() => setTimeLeft((v) => v - 1), 1000);
    setIsCounting(true);
  };

  const stop = () => {
    if (timer.current !== null) {
      clearInterval(timer.current);
      timer.current = null;
    }
    setIsCounting(false);
  };

  const reset = () => {
    stop();
    setTimeLeft(seconds);
  };

  useEffect(() => {
    if (timeLeft <= 0) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return {
    timeLeft,
    isCounting,
    reset,
    stop,
    start,
  };
};
