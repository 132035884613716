import * as React from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const DepartmentCreate = (props: CreateProps) => {
    const { basePath, resource } = props;
    return (
      <Create basePath={basePath} resource={resource}>
        <SimpleForm>
          <TextInput source="name" />
        </SimpleForm>
      </Create>
    );
  };
  