export const statuses = [
  {
    label: 'inspections.status.started',
    value: 10,
  },
  {
    label: 'inspections.status.editing',
    value: 20,
  },
  {
    label: 'inspections.status.checked',
    value: 30,
  },
  {
    label: 'inspections.status.offer_sent',
    value: 40,
  },
  {
    label: 'inspections.status.offer_accepted',
    value: 50,
  },
];
