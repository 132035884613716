import React from 'react';
import {
  ArrayInput,
  Edit,
  EditProps,
  email,
  FormTab,
  number,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import { dashboardConfig } from '../../../config';
import { MachinesToolbar } from '../machines-toolbar';
import { detailTypes, phoneValidation } from '../utils';

export function MachineEdit({ basePath, resource, id }: EditProps) {
  const translate = useTranslate();
  const vRequired = required(translate('validation.required'));
  const vNumber = number(translate('validation.number'));
  const choices = detailTypes.map((id) => ({
    id,
    name: translate(`resources.machines.fields.${id}`),
  }));
  return (
    <Edit
      actions={<MachinesToolbar basePath={basePath} type="edit" />}
      basePath={basePath}
      resource={resource}
      id={id}
    >
      <TabbedForm>
        <FormTab label={translate('resources.machines.machine')}>
          <TextInput disabled source="id" />
          <TextInput source="manufacturer" validate={vRequired} />
          <TextInput source="category" validate={vRequired} />
          <TextInput source="model" validate={vRequired} />
          <TextInput
            disabled
            source="inspection_template_id"
            validate={[vRequired, vNumber]}
          />
        </FormTab>
        <FormTab
          label={translate('resources.machines.fields.machine_details')}
          path="details"
        >
          <ArrayInput source="machine_details">
            <SimpleFormIterator
              TransitionProps={{
                enter: false,
                exit: false,
                addEndListener: () => {},
              }}
            >
              <TextInput source="label" validate={required()} />
              <SelectInput
                source="type"
                choices={choices}
                validate={required()}
              />
              <TextInput source="example" defaultValue="" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        {dashboardConfig.machineContactFlag && (
          <FormTab
            label={translate('resources.machines.fields.contact')}
            path="contact"
          >
            <TextInput source="contact" />
            <TextInput source="organisation" />
            <TextInput
              source="phone"
              validate={phoneValidation(translate('ra.validation.phone'))}
            />
            <TextInput source="email" validate={email()} />
          </FormTab>
        )}

        {dashboardConfig.machineContactFlag && (
          <FormTab
            label={translate('resources.machines.fields.serials.allowed')}
            path="serials"
          >
            <ArrayInput source="serials.allowed">
              <SimpleFormIterator>
                <TextInput
                  source=""
                  label={translate('resources.machines.fields.serials.serial')}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        )}
        <FormTab
          label={translate('resources.machines.fields.labels.allowed')}
          path="labels"
        >
          <ArrayInput source="labels">
            <SimpleFormIterator>
              <TextInput
                source=""
                label={translate('resources.machines.fields.labels.label')}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
