import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useGetOne, useLocale, useNotify, useTranslate } from 'react-admin';
import {
  DataObject,
  InspectionTemplate,
  Machine,
} from '../../types.inspections';
import { PdfContext } from './components/context/pdf-context';
import saveAs from 'file-saver';
import { downloadPDF } from './download-pdf';
import { UTILITY_API_URL } from 'config';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    width: '100%',
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
}));

interface PdfButtonProps {
  record: DataObject;
  machine: Machine;
  machineName: string;
}

export const LazyDownloadPDFButton = ({
  record,
  machine,
  record: { inspection_template_id },
  machineName,
}: PdfButtonProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const locale = useLocale();
  const { data: template } = useGetOne<InspectionTemplate>(
    'inspection-templates',
    inspection_template_id,
  );

  const pdfState = useContext(PdfContext);

  const updateRenderingStatus = ({
    loading,
    status,
  }: {
    loading: boolean;
    status?: string;
  }) => pdfState.setState({ ...pdfState, renderingState: { loading, status } });

  const onDownloadClicked = async () => {
    if (!pdfState.renderingState.loading) {
      try {
        updateRenderingStatus({
          loading: true,
          status: translate('pdf.loading'),
        });
        const blob = await downloadPDF(
          `${UTILITY_API_URL}/pdf/generate/${
            record.id
          }?language=${locale}&compression=${
            pdfState.imageCompression
          }&includeImages=${String(pdfState.includeImages)}`,
        );
        saveAs(blob, 'document.pdf');
        updateRenderingStatus({ loading: false });
      } catch (error) {
        notify('pdf.pdf_download_error', 'error', { autoHideDuration: 2000 });
        updateRenderingStatus({
          loading: false,
          status: translate('pdf.pdf_download_error'),
        });
      }
    }
  };

  return template ? (
    <Box className={classes.container}>
      <Button
        variant="contained"
        onClick={onDownloadClicked}
        className={classes.button}
      >
        {!pdfState.renderingState.loading && translate('pdf.download')}
        {pdfState.renderingState.loading && (
          <>
            <CircularProgress
              color="secondary"
              size={25}
              className={classes.spinner}
            ></CircularProgress>
            <Typography>{pdfState.renderingState.status}</Typography>
          </>
        )}
      </Button>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Button variant="contained" onClick={() => {}} className={classes.button}>
        {translate('pdf.loading')}
      </Button>
    </Box>
  );
};
