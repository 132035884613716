import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  ArrayInput,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useInput,
  useTranslate,
} from 'react-admin';
import { SharedDialogWrapper } from 'components';
import { helperFieldNames, StepTypeEnum } from '../../types';
import { filterModules } from '../../utils';
import { CheckboxesInput } from './checkboxes';
import { DamagesInput } from './damages';
import { ImagesInput } from './images';
import { ImagesDamagesInput } from './images-damages';
import { pick } from 'lodash';
import { PartsInput } from './parts';
import { StatusInput } from './status';
import { StatusPartsInput } from './status-parts';
import { TutorialInput } from './tutorial';
import { screenshots } from 'img/screenshots';
import { CollapseHackStub } from '../collapse';
import { FreeInputsInput } from './free-input';

const useStyles = makeStyles((theme) => ({
  thumbnailWrapper: {
    position: 'relative',
  },
  thumbnail: {
    width: theme.spacing(13),
    position: 'absolute',
    marginTop: theme.spacing(1),
    left: theme.spacing(50),
    borderColor: theme.palette.primary.main,
    borderWidth: theme.spacing(0.2),
    borderStyle: 'solid',
    zIndex: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7',
    },
    [theme.breakpoints.down(1450)]: {
      display: 'none',
    },
  },
  image: {
    maxWidth: theme.spacing(50),
  },
  subcategory: {
    padding: `${theme.spacing(2)}px ${theme.spacing(14)}px ${theme.spacing(
      2,
    )}px ${theme.spacing(4)}px`,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
  },
  default: {
    width: theme.spacing(32),
  },
}));

const RESOURCE_PREFIX = 'resources.inspection-templates.fields';

interface TemplateFormProps {
  getSource: (arg: string) => string;
  resource: string;
  scopedFormData: { type?: string };
}

export const TemplateForm = ({
  getSource,
  resource,
  scopedFormData: { type: stepType } = {
    type: undefined,
  },
}: TemplateFormProps) => {
  const config = getSource('config');
  const spec = getSource('spec');
  const preIndex = Number(getSource('index').split('[')[1].split(']')[0]);
  const [touched, setTouched] = useState(false);

  const {
    input: { value, onChange },
  } = useInput({ source: config });

  const translate = useTranslate();
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [screenshotTypeInDialog, setScreenshotTypeInDialog] =
    useState<StepTypeEnum>(StepTypeEnum.Images);

  const types = Object.entries(StepTypeEnum)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ id: value, name: translate(`config.${value}`) }));
  const choices = filterModules(types).filter(
    ({ id }) => id !== StepTypeEnum.NestedSpec,
  );

  useEffect(() => {
    if (!touched) {
      setTouched(true);
    } else if (stepType) {
      onChange(pick(value, helperFieldNames));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepType]);

  const helperTypes = ['text', 'link', 'image'].reduce((prev, id) => {
    prev[id] = translate(`${RESOURCE_PREFIX}.helper_type.${id}`);
    return prev;
  }, {});
  const helperChoices = Object.keys(helperTypes).map((id) => ({
    id,
    name: helperTypes[id],
  }));

  const isRequired = required();

  switch (stepType) {
    case StepTypeEnum.Images:
      return <ImagesInput source={config} />;
    case StepTypeEnum.Videos:
      return <ImagesInput source={config} />;
    case StepTypeEnum.Damages:
      return <DamagesInput source={config} />;
    case StepTypeEnum.ImagesDamages:
      return <ImagesDamagesInput source={config} />;
    case StepTypeEnum.Status:
      return <StatusInput source={config} />;
    case StepTypeEnum.Parts:
      return <PartsInput source={config} />;
    case StepTypeEnum.Checkboxes:
      return <CheckboxesInput source={config} />;
    case StepTypeEnum.Tutorial:
      return <TutorialInput source={config} />;
    case StepTypeEnum.StatusParts:
      return <StatusPartsInput source={config} />;
    case StepTypeEnum.FreeInput:
      return <FreeInputsInput source={config} />;

    case StepTypeEnum.NestedSpec:
      return (
        <div
          className={
            stepType === StepTypeEnum.NestedSpec ? classes.subcategory : ''
          }
        >
          <SharedDialogWrapper
            title={translate(`config.${screenshotTypeInDialog}`)}
            subTitle={''}
            open={isDialogOpen}
            handleClose={() => setIsDialogOpen(false)}
          >
            <img
              alt="screenshot"
              src={screenshots(screenshotTypeInDialog)}
              className={classes.image}
            />
          </SharedDialogWrapper>
          <ArrayInput
            resource={resource}
            source={spec}
            label={translate('resources.inspection-templates.fields.sub_spec')}
          >
            <SimpleFormIterator
              getItemLabel={(index) => `${preIndex + 1}.${index + 1}`}
            >
              <CollapseHackStub />
              <FormDataConsumer>
                {({ scopedFormData }) =>
                  screenshots(scopedFormData?.type) && (
                    <div className={classes.thumbnailWrapper}>
                      <img
                        alt="screenshot"
                        src={screenshots(scopedFormData?.type)}
                        className={classes.thumbnail}
                        onClick={() => {
                          setIsDialogOpen(true);
                          setScreenshotTypeInDialog(scopedFormData?.type);
                        }}
                      />
                    </div>
                  )
                }
              </FormDataConsumer>
              <TextInput source="label" validate={isRequired} />
              <SelectInput
                source="type"
                choices={choices}
                validate={isRequired}
              />
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  const source = getSource('helper_text');
                  const config = getSource('config');
                  const type = scopedFormData?.config?.helperType;
                  let input: JSX.Element | undefined;
                  switch (type) {
                    case 'text':
                      input = (
                        <TextInput source={source} label={helperTypes[type]} />
                      );
                      break;
                    case 'link':
                      input = (
                        <TextInput source={source} label={helperTypes[type]} />
                      );
                      break;
                    case 'image':
                      input = (
                        <ImagesInput source={config} imagesKey="helperImages" />
                      );
                      break;
                  }
                  return (
                    <div
                      style={
                        type === 'image'
                          ? undefined
                          : {
                              display: 'flex',
                              flexDirection: 'row',
                              columnGap: 10,
                            }
                      }
                    >
                      <SelectInput
                        source={`${config}.helperType`}
                        label={translate(`${RESOURCE_PREFIX}.helper`)}
                        choices={helperChoices}
                        defaultValue="text"
                      />
                      {input}
                    </div>
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {(formProps: any) => <TemplateForm {...formProps} />}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </div>
      );
    default:
      return null;
  }
};
