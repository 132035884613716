import { makeStyles } from '@material-ui/core';
import { screenshots } from 'img/screenshots';
import React, { createContext, useEffect, useState } from 'react';
import {
  ArrayInput,
  Create,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  usePermissions,
  useQuery,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { IconAutocomplete, SharedDialogWrapper } from '../../../components';
import { dashboardConfig } from '../../../config';
import { ItemProps, PopulateSettingValues, StepTypeEnum } from '../types';
import {
  createCols,
  filterModules,
  getTemplateDraft,
  processData,
  setTemplateDraft,
} from '../utils';
import { CollapseHackStub } from './collapse';
import { TemplateForm } from './form';
import { ImagesInput } from './form/images';
import { useHistory } from 'react-router-dom';
import { DenseTable, ImportCsvButton, PopulateOptionSelect } from './import';
import { CreateToolbar } from './toolbar';
import { ScrollOnError } from './scroll';
import { FormSpy } from 'react-final-form';
import { TopLevelAdmin, departmentsQuery } from 'auth/auth';
import { Department } from 'components/department-select-input';

const useStyles = makeStyles((theme) => ({
  thumbnailWrapper: {
    position: 'relative',
  },
  thumbnail: {
    width: theme.spacing(13),
    position: 'absolute',
    marginTop: theme.spacing(1),
    left: theme.spacing(50),
    borderColor: theme.palette.primary.main,
    borderWidth: theme.spacing(0.2),
    borderStyle: 'solid',
    zIndex: 1,
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  collapse: {
    overflow: 'hidden',
    transition: 'height 200ms',
    marginBottom: theme.spacing(2),
  },
  image: {
    maxWidth: theme.spacing(50),
  },
  default: {
    width: theme.spacing(32),
  },
}));

const RESOURCE_PREFIX = 'resources.inspection-templates.fields';

export interface SubmitStrigger {
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  trigger: boolean;
}

export const SubmitTriggerContext = createContext<SubmitStrigger>({
  trigger: false,
  setTrigger: () => {},
});

export const SubmitTriggerContextProvider = ({ children }) => {
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  return (
    <SubmitTriggerContext.Provider
      value={{ trigger: submitTrigger, setTrigger: setSubmitTrigger }}
    >
      {children}
    </SubmitTriggerContext.Provider>
  );
};

export const TemplateCreate = (props: any): JSX.Element => {
  const translate = useTranslate();
  const classes = useStyles();
  const refresh = useRefresh();
  const history = useHistory<{ record: { spec: ItemProps[] } }>();
  const departments = useQuery(departmentsQuery);
  const { permissions } = usePermissions();

  const types = Object.entries(StepTypeEnum)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ id: value, name: translate(`config.${value}`) }));
  const choices = filterModules(types);

  const isRequired = required();
  const [currentData, setCurrentData] = useState<string[][]>([] as any[]);
  const [populateSetting, setPopulateSetting] = useState<
    PopulateSettingValues | undefined
  >(undefined);
  const [populateItems, setPopulateItems] = useState<ItemProps[]>(
    history.location.state?.record?.spec || [],
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [screenshotTypeInDialog, setScreenshotTypeInDialog] =
    useState<StepTypeEnum>(StepTypeEnum.Images);

  const helperTypes = ['text', 'link', 'image'].reduce((prev, id) => {
    prev[id] = translate(`${RESOURCE_PREFIX}.helper_type.${id}`);
    return prev;
  }, {});
  const helperChoices = Object.keys(helperTypes).map((id) => ({
    id,
    name: helperTypes[id],
  }));

  useEffect(() => {
    if (populateSetting) {
      const itemsList = processData(currentData, populateSetting);
      setPopulateItems(itemsList);
    }
  }, [currentData, populateSetting]);

  useEffect(() => {
    refresh();
  }, [populateItems, refresh]);

  const currentCols = createCols(currentData);

  const setCurrentDataOnConfirm = (data: string[][]) => {
    if (data.length > 0 && !data[data.length - 1][0]) {
      data.pop();
    }
    setCurrentData(data);
  };

  const setPopulateSettingOnConfirm = (setting: PopulateSettingValues) => {
    setPopulateSetting(setting);
  };

  const [formInitialValues] = useState(getTemplateDraft);
  const alwaysEnableSaveButton =
    formInitialValues && Object.values(formInitialValues).some((v) => v);
  const showPopulateOptions = Boolean(currentData.length > 0);

  return (
    <div>
      {dashboardConfig.importTemplateFlag && (
        <>
          <DenseTable data={currentData} cols={currentCols} />
          {showPopulateOptions && (
            <PopulateOptionSelect
              cols={currentCols}
              onConfirm={setPopulateSettingOnConfirm}
            />
          )}
          <ImportCsvButton onConfirm={setCurrentDataOnConfirm} />
        </>
      )}
      <SharedDialogWrapper
        title={translate(`config.${screenshotTypeInDialog}`)}
        subTitle={''}
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
      >
        <img
          alt="screenshot"
          src={screenshots(screenshotTypeInDialog)}
          className={classes.image}
        />
      </SharedDialogWrapper>
      <SubmitTriggerContextProvider>
        <Create {...props}>
          <SimpleForm
            initialValues={formInitialValues}
            toolbar={
              <CreateToolbar alwaysEnableSaveButton={alwaysEnableSaveButton} />
            }
          >
            <ScrollOnError source="name" />
            <FormSpy
              subscription={{
                dirty: true,
                values: true,
                submitSucceeded: true,
              }}
              onChange={({ dirty, values, submitSucceeded }) => {
                if (submitSucceeded) {
                  setTemplateDraft();
                } else if (dirty) {
                  setTemplateDraft(values);
                }
              }}
            />
            <TextInput source="name" validate={required()} />
            <SelectInput
              source="department_id"
              choices={[
                ...(departments.data?.map((item: Department) => ({
                  name: item.name,
                  id: item.id,
                })) ?? []),
                {
                  name: translate('resources.departments.default'),
                  id: 0,
                },
              ]}
              defaultValue={
                permissions?.departmentId ||
                (TopLevelAdmin.includes(permissions?.role) ? 'all' : undefined)
              }
              validate={required()}
            />
            <ArrayInput
              source="spec"
              defaultValue={populateItems.length ? populateItems : undefined}
              validate={required()}
            >
              <SimpleFormIterator>
                <CollapseHackStub />
                <FormDataConsumer>
                  {({ scopedFormData }) =>
                    screenshots(scopedFormData?.type) && (
                      <div className={classes.thumbnailWrapper}>
                        <img
                          alt="screenshot"
                          src={screenshots(scopedFormData?.type)}
                          className={classes.thumbnail}
                          onClick={() => {
                            setIsDialogOpen(true);
                            setScreenshotTypeInDialog(scopedFormData?.type);
                          }}
                        />
                      </div>
                    )
                  }
                </FormDataConsumer>
                <TextInput source="label" label="label" validate={required()} />
                {IconAutocomplete()}
                <SelectInput
                  source="type"
                  choices={choices}
                  validate={isRequired}
                />
                <FormDataConsumer>
                  {({ scopedFormData, getSource }) => {
                    const source = getSource('helper_text');
                    const config = getSource('config');
                    const type = scopedFormData?.config?.helperType;

                    let input: JSX.Element | undefined;
                    switch (type) {
                      case 'text':
                        input = (
                          <TextInput
                            source={source}
                            label={helperTypes[type]}
                          />
                        );
                        break;
                      case 'link':
                        input = (
                          <TextInput
                            source={source}
                            label={helperTypes[type]}
                          />
                        );
                        break;
                      case 'image':
                        input = (
                          <ImagesInput
                            source={config}
                            imagesKey="helperImages"
                          />
                        );
                        break;
                    }
                    return (
                      <div
                        style={
                          type === 'image'
                            ? undefined
                            : {
                                display: 'flex',
                                flexDirection: 'row',
                                columnGap: 10,
                              }
                        }
                      >
                        <SelectInput
                          source={`${config}.helperType`}
                          label={translate(`${RESOURCE_PREFIX}.helper`)}
                          choices={helperChoices}
                          defaultValue="text"
                        />
                        {input}
                      </div>
                    );
                  }}
                </FormDataConsumer>
                <FormDataConsumer>
                  {(formProps: any) => <TemplateForm {...formProps} />}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleForm>
        </Create>
      </SubmitTriggerContextProvider>
    </div>
  );
};
