import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AutocompleteInput, required, useTranslate } from 'react-admin';
import * as Icons from '../img/icons';

interface IconOption {
  label: keyof typeof Icons;
  icon: typeof SvgIcon;
  translation: string;
  alt: string;
}

export const iconLabelsToObjects = (allIconLabels: any, translate: any) =>
  allIconLabels.map((iconLabel: keyof typeof Icons) => {
    const iconLabelCamelCase = iconLabel.charAt(0).toLowerCase() + iconLabel.slice(1);
    return{
      label: iconLabelCamelCase,
      icon: Icons[iconLabelCamelCase],
      translation: translate(`icons.${iconLabelCamelCase}`),
      alt: translate(`icon_alts.${iconLabelCamelCase}`),
    }
  });

const useIconRendererStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      padding: theme.spacing(0.5, 0),
    },
    iconContainer: {
      display: 'flex',
      marginRight: theme.spacing(1.5),
    },
    icon: {},
  }),
  { name: 'IconRenderer' },
);

export const IconRenderer = (props: {
  filterValue?: string;
  record?: IconOption;
  noText?: boolean;
}) => {
  const translate = useTranslate();
  const classes = useIconRendererStyles(props);

  return (
    <div className={classes.root}>
      <span className={classes.iconContainer}>
        {props && props.record ? (
          <props.record.icon className="MuiSvgIcon-root" />
        ) : null}
      </span>
      {props.noText ? null : translate(`icons.${props?.record?.label ?? ''}`)}
    </div>
  );
};

const escapeRegExp = (value: string) =>
  value ? value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : ''; // $& means the whole matched string

const allIconLabels = (Object.keys(Icons) as (keyof typeof Icons)[]).sort();

export const IconAutocomplete = () => {
  const translate = useTranslate();
  const choices = iconLabelsToObjects(allIconLabels, translate);

  const matchSuggestion = (filterValue: string, suggestion: IconOption) =>
    suggestion.alt.match(new RegExp(escapeRegExp(filterValue.trim()), 'i'));
  return (
    <AutocompleteInput
      source="icon"
      choices={choices}
      optionText={<IconRenderer />}
      optionValue="label"
      inputText={(choice: IconOption) => choice.translation}
      matchSuggestion={matchSuggestion}
      validate={required()}
    />
  );
};
