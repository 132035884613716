import { Divider, Typography } from '@material-ui/core';
import React from 'react';

export const SingleDetail = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <>
    <Typography
      variant="body2"
      color="textSecondary"
      component="p"
      className="special-typography"
    >
      <span className="typo-strong">{label}:</span>
      <span className="typo-light">{value}</span>
    </Typography>
    <Divider className="typography-divider" />
  </>
);
