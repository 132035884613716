import { Box, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { DataObject, Machine } from '../../types.inspections';
import { PdfSettingsControls } from '../pdf/components';
import { LazyDownloadPDFButton } from '../pdf/lazy-download-button';
import { InspectionShowSectionTitle } from '../shared/section-title';
import { StatusSelect } from './status-select';

const useStyles = makeStyles((theme) => ({
  content: {
    marginRight: '12px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
  inspectionDropdowns: {
    flex: 1,
    width: 250,
    '& > *': {
      marginBottom: theme.spacing(1.5),
    },
  },
  inspectionTitle: {
    flex: 2,
  },
}));

interface HeaderProps {
  loading: boolean;
  loaded: boolean;
  machine: Machine;
  machineName: string;
  permissions: string;
  resource: string;
  record: DataObject;
}

export const Header = ({
  loading,
  loaded,
  machine,
  machineName,
  permissions,
  resource,
  record,
}: HeaderProps) => {
  const classes = useStyles();
  return (
    <CardContent className={classes.content}>
      <Box className={classes.headerContainer}>
        <InspectionShowSectionTitle
          className={classes.inspectionTitle}
          title={machineName}
          loading={loading}
          loaded={loaded}
        />
        <Box className={classes.inspectionDropdowns}>
          {permissions !== 'role.dealeruser' ? (
            <StatusSelect resource={resource} record={record} />
          ) : null}
          <PdfSettingsControls />
          <LazyDownloadPDFButton
            machineName={machineName}
            machine={machine}
            record={record}
          />
        </Box>
      </Box>
    </CardContent>
  );
};
