import { Typography } from '@material-ui/core';
import React from 'react';

export const Checkboxes = ({ checkboxes }: { checkboxes?: string[] | null }) =>
  checkboxes ? (
    <>
      {checkboxes.map((checkboxValue: string) => (
        <Typography variant="subtitle2">- {checkboxValue}</Typography>
      ))}
    </>
  ) : null;
