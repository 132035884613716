import de from './de';
import en from './en';
import ExtendedTranslationMessages from './extended-translation-messages';

const locales: Record<string, ExtendedTranslationMessages> = {
  de,
  en,
};

export default locales;
