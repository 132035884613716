import { StepTypeEnum } from '../../pages/templates/types';
import ChecklistImg from './checklist.png';
import DamageImagesImg from './damage-images.png';
import ErrorCodesImg from './error-codes.png';
import PartListImg from './part-list.png';
import QrCodeImg from './qr-code.png';
import RatingImg from './rating.png';
import SignatureImg from './signature.png';
import StatusDamageImagesImg from './status-damage-images.png';
import StatusImagesImg from './status-images.png';
import StatusPartsImg from './status-parts.png';
import ThumbEvaluationImg from './thumb-evaluation.png';
import TutorialImg from './tutorial.png';
import VideoImg from './videos.png';

export const screenshots = (type: StepTypeEnum) => {
  switch (type) {
    case StepTypeEnum.Checkboxes:
      return ChecklistImg;
    case StepTypeEnum.Damages:
      return DamageImagesImg;
    case StepTypeEnum.ErrorCodes:
      return ErrorCodesImg;
    case StepTypeEnum.Parts:
      return PartListImg;
    case StepTypeEnum.QRCodes:
      return QrCodeImg;
    case StepTypeEnum.Rating:
      return RatingImg;
    case StepTypeEnum.Signature:
      return SignatureImg;
    case StepTypeEnum.ImagesDamages:
      return StatusDamageImagesImg;
      case StepTypeEnum.Images:
        return StatusImagesImg;
    case StepTypeEnum.StatusParts:
      return StatusPartsImg;
    case StepTypeEnum.Status:
      return ThumbEvaluationImg;
    case StepTypeEnum.Tutorial:
      return TutorialImg;
    case StepTypeEnum.Videos:
      return VideoImg;
    default:
      return null;
  }
};
