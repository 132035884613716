import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FormEvent, useEffect, useState } from 'react';
import {
  Notification,
  useTranslate,
  useQuery,
  usePermissions,
} from 'react-admin';
import { departmentsQuery } from './auth';
import { DepartmentSelectInput } from 'components/department-select-input';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 250,
  },
  submitButton: {
    marginTop: theme.spacing(1),
    height: 45,
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
}));

interface SelectDepartFormProps {
  select: (department: { id: number | '' }) => Promise<void>;
  logout: () => Promise<void>;
}

export interface Department {
  id: number;
  role: string;
  name: string;
}

export const SelectDepartmentForm = ({
  select,
  logout,
}: SelectDepartFormProps) => {
  const [id, setId] = useState(0);
  const [notification, enableNotification] = useState(false);
  const [isSelectLoading, setSelectLoading] = useState(false);
  const [isLogoutLoading, setLogoutLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const departments = useQuery(departmentsQuery);
  const { permissions } = usePermissions();

  useEffect(() => {
    const departmentId = permissions?.departmentId;
    setId(departmentId);
  }, [permissions?.departmentId]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (Number.isInteger(id)) {
      await select({ id });
    }
    enableNotification(true);
  };

  return (
    <form
      onSubmit={async (event) => {
        setSelectLoading(true);
        await handleSubmit(event);
        setSelectLoading(false);
      }}
      className={classes.form}
    >
      <p>{translate('auth.select_department')}</p>
      <DepartmentSelectInput value={id} onChange={(v) => setId(v)} />
      <Button
        className={classes.submitButton}
        variant="contained"
        type="submit"
        disabled={isSelectLoading || departments.loading}
        fullWidth
      >
        {(isSelectLoading || departments.loading) && (
          <CircularProgress
            size={25}
            thickness={2}
            className={classes.spinner}
          />
        )}
        {translate('action.select')}
      </Button>
      <Button
        className={classes.submitButton}
        variant="contained"
        type="button"
        disabled={isLogoutLoading}
        fullWidth
        onClick={async () => {
          setLogoutLoading(true);
          await logout();
          setLogoutLoading(false);
        }}
      >
        {isLogoutLoading && (
          <CircularProgress
            size={25}
            thickness={2}
            className={classes.spinner}
          />
        )}
        {translate('ra.auth.logout')}
      </Button>
      {notification && <Notification />}
    </form>
  );
};
