import { ParseError } from 'papaparse';
import React, { useEffect, useState } from 'react';
import { useNotify, useRefresh, useTranslate } from 'react-admin';
import { ImportButton } from '../../../../components/import-button';
import { ImportCsvDropzoneDialog } from './import-csv-dropzone';

interface ImportCsvButtonProps {
  onConfirm: (data: string[][]) => void;
  variant?: 'text' | 'outlined' | 'contained';
}

interface ParseItem {
  errors: string | ParseError[];
  data: string[];
}

export const ImportCsvButton = ({
  onConfirm,
  variant = 'text',
}: ImportCsvButtonProps) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([] as string[][]);
  const [file, setFile] = useState<File | null>();
  const fileName = file?.name + '';
  const [disableConfirm, setDisableConfirm] = useState(false);

  useEffect(() => {
    return () => {
      onConfirm([]);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data.length === 0) {
      setDisableConfirm(true);
      return;
    }
    setDisableConfirm(false);
  }, [data]);

  function resetVars() {
    setOpen(false);
    setData([]);
    setFile(null);
  }

  function clickImportButton() {
    resetVars();
    setOpen(true);
  }

  const notify = useNotify();

  const handleClose = () => {
    resetVars();
    refresh();
  };

  const handleOnDrop = async (
    resArray: ParseItem[],
    fileUploaded: File | undefined,
  ) => {
    const resData = [] as string[][];
    resArray.forEach(({ data }: ParseItem) => {
      resData.push(data);
    });
    setData(resData);
    setFile(fileUploaded);
  };

  const handleOnRemoveFile = async () => {
    setData([]);
    setFile(null);
  };

  const handleOnConfirm = async () => {
    onConfirm(data);
    notify('csv_import.notification.imported', 'info', { file: fileName });
    resetVars();
  };

  return (
    <div>
      <ImportButton
        variant={variant}
        label={translate('csv_import.label')}
        clickImportButton={clickImportButton}
      />

      <ImportCsvDropzoneDialog
        title={translate('csv_import.dropzone.title')}
        subTitle={translate('csv_import.dropzone.subtitle')}
        open={open}
        disableConfirm={disableConfirm}
        handleClose={handleClose}
        handleOnDrop={handleOnDrop}
        handleOnRemoveFile={handleOnRemoveFile}
        handleOnConfirm={handleOnConfirm}
      />
    </div>
  );
};
