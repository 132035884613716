import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import { Button, useTranslate } from 'react-admin';

const useStyles = makeStyles(() => ({
  tooltip: {
    textAlign: 'right',
  },
  button: {
    display: 'inline-block',
  },
  icon: {
    transform: 'rotate(180deg)',
    fontSize: '20',
  },
}));

interface ImportButtonProps {
  variant: 'text' | 'outlined' | 'contained';
  label: string;
  clickImportButton: () => void;
}

export function ImportButton({
  variant,
  label,
  clickImportButton,
}: ImportButtonProps) {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <div className={classes.tooltip}>
      <Tooltip title={translate('csv_import.tooltip')} placement="bottom">
        <div className={classes.button}>
          <Button
            color="primary"
            component="span"
            variant={variant}
            label={label}
            onClick={clickImportButton}
          >
            <GetAppIcon className={classes.icon} />
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}
