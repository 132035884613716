import enDefaults from 'ra-language-english';

import ExtendedTranslationMessages from './extended-translation-messages';

const en: ExtendedTranslationMessages = {
  ...enDefaults,
  ra: {
    ...enDefaults.ra,
    validation: {
      ...enDefaults.ra.validation,
      phone: 'Must be a valid phone number',
    },
  },
  action: {
    archive: 'Archive',
    unarchive: 'Unarchive',
    ungroup: 'Ungroup',
    select: 'Select',
    import: 'Import',
    export: 'Export',
    import_success: 'Import success',
    import_failure: 'Import failure',
    continue_draft: 'Continue draft',
    resend_password: 'Resend password',
  },
  app: {
    title: 'Audit App Dashboard',
  },
  auth: {
    no_auth: 'You need to sign in to access that page.',
    reset_password_error: 'New password could not be set',
    select_department: 'Please select a department',
    resend_password_error: 'Password could not be resent',
    password_resent: 'Password resent',
    invalid_email: 'Invalid email',
    permission_denied: 'Permission Denied',
  },
  checkboxes: {
    value: 'Checklist Item',
    addCheckbox: 'Add Checklist Item',
  },
  'free-input': {
    value: 'Free Text',
    addFreeInput: 'Add Free Text',
  },
  config: {
    'error-codes': 'Error codes',
    'images-damages': 'Status + Damage images',
    'nested-spec': 'Subcategory',
    choose: 'Choose',
    damages: 'Damage images',
    images: 'Status images',
    status: 'Thumb evaluation',
    rating: 'Rating',
    parts: 'Parts List',
    checkboxes: 'Checklist',
    signature: 'Signature',
    tutorial: 'Tutorial',
    'qr-codes': 'QR-Code',
    'status-parts': 'Status + Parts',
    videos: 'Videos',
    'free-input': 'Free Text',
  },
  damages: {
    max_damages: 'Max. amount of damages',
    comment: 'Comment',
    type_options: 'Damage Types',
  },
  dropdown: {
    accept: 'Client accepts',
    notaccept: 'Client does not accept',
    status: 'Status selected',
    statushelper: 'Select inspection report status',
  },
  empty: {
    subtitle: 'Create one or navigate to a different menu item',
    title: 'No products available',
  },
  error: {},
  errors: {
    'inspection-templates': {
      unauthorized:
        'You do not have the rights to edit this inspection template',
      invalid: 'The form is not valid. Please check following sections:',
    },
    inspections: {
      unauthorized: 'You do not have the rights to edit this inspection',
    },
    machines: {
      unauthorized: 'You do not have the rights to edit this machine',
    },
    users: {
      unauthorized: 'You do not have the rights to add this role',
    },
    title: 'Oops',
    subtitle: 'Sorry, something went wrong',
    back: 'Go Back?',
  },
  icons: {
    accountStar: 'Account',
    archive: 'Archive',
    beakerCheck: 'Checked inspection',
    barcode: 'Barcode',
    barcodeScan: 'Barcode Scan',
    checkDecagram: 'Active',
    clipboardListOutline: 'Template',
    collapseRight: 'Collapse Right',
    collapseDown: 'Collapse Down',
    idCard: 'ID',
    qrCode: 'QR-Code',
    tractorVariant: 'Tractor',
    brakes: 'Brakes',
    chain: 'Chain',
    crank: 'Crank',
    derailleur: 'Derailleur',
    frame: 'Frame',
    pedal: 'Pedals',
    shape: 'Shape',
    saddle: 'Saddle',
    wheel: 'Wheels',
    accessories: 'Accessories',
    auger: 'Auger',
    cabin: 'Cabin',
    compactor: 'Refuse Compactor',
    date: 'Date',
    electric: 'Electric System',
    engine: 'Diesel Engine',
    heating: 'Heating Coil',
    hopper: 'Hopper',
    hours: 'Operating Hours',
    hydraulic: 'Hydraulic System',
    joint: 'Articulated Joint/Pivot Bearing',
    last_service: 'Last Service',
    liquids: 'Liquids',
    machine: 'Machine',
    milling: 'Milling Drum',
    next_service: 'Next Service',
    options: 'Options',
    parts: 'Recommended Spare Parts',
    paver: 'Paver',
    planer: 'Cold Planer',
    pressure: 'Pressure and Rotary Speed',
    recycler: 'Recycler',
    repairs: 'Recommended Repairs',
    screed: 'Screed',
    serial_no: 'Serial Number',
    steering: 'Steering Wheel',
    textBoxCheckOutline: 'Text Box CheckOutline',
    transfer_box: 'Transfer Box',
    tyres: 'Drum/Tyres/Axles',
    vibration: 'Vibration',
    visual: 'Visual',
    year: 'Year',
  },
  icon_alts: {
    accountStar: 'Account',
    archive: 'Archive',
    beakerCheck: 'Checked inspection',
    barcode: 'Barcode',
    barcodeScan: 'Barcode Scan',
    checkDecagram: 'Active',
    clipboardListOutline: 'Template',
    collapseRight: 'Collapse Right',
    collapseDown: 'Collapse Down',
    idCard: 'ID',
    qrCode: 'QR-Code',
    tractorVariant: 'Tractor',
    brakes: 'Brakes',
    chain: 'Chain',
    crank: 'Crank',
    derailleur: 'Derailleur',
    frame: 'Frame',
    pedal: 'Pedals',
    shape: 'Shape',
    saddle: 'Saddle',
    wheel: 'Wheels',
    accessories: 'Accessories',
    auger: 'Auger',
    cabin: 'Cabin',
    compactor: 'Refuse Compactor',
    date: 'Date',
    electric: 'Electric System',
    engine: 'Diesel Engine',
    heating: 'Heating Coil',
    hopper: 'Hopper',
    hours: 'Operating Hours',
    hydraulic: 'Hydraulic System',
    joint: 'Articulated Joint/Pivot Bearing',
    last_service: 'Last Service',
    liquids: 'Liquids',
    machine: 'Machine',
    milling: 'Milling Drum',
    next_service: 'Next Service',
    options: 'Options',
    parts: 'Recommended Spare Parts',
    paver: 'Paver',
    planer: 'Cold Planer',
    pressure: 'Pressure and Rotary Speed',
    recycler: 'Recycler',
    repairs: 'Recommended Repairs',
    screed: 'Screed',
    serial_no: 'Serial Number',
    steering: 'Steering Wheel',
    textBoxCheckOutline: 'Text Box CheckOutline',
    transfer_box: 'Transfer Box',
    tyres: 'Drum/Tyres/Axles',
    vibration: 'Vibration',
    visual: 'Visual',
    year: 'Year',
  },
  images: {
    edit_image: 'Edit image',
    no_subtitle: 'No subtitle',
    no_title: 'No title',
    subtitle: 'Subtitle',
    title: 'Title',
    upload: 'Select image',
  },
  inspections: {
    basicdata: 'Basic data',
    fotos: 'Photos',
    inspectiondata: 'Inspecton report data',
    maindata: 'Main data',
    nodata: 'No data',
    overview: 'Overview',
    quickcheck: 'Quickcheck',
    status: {
      checked: 'Checked',
      editing: 'Editing',
      none: 'None',
      offer_accepted: 'Purchase completed',
      offer_sent: 'Offer made to client',
      started: 'Received',
    },
  },
  languages: {
    english: 'English',
    german: 'German',
  },
  menu: {
    active: 'Active',
    archived: 'Archived',
    inspectionTemplates: 'Inspection templates',
    inspections: 'Inspection reports',
    logo_alt: 'schmiede.ONE',
    machines: 'Machines',
    profile: 'Settings',
    published: 'Published',
    roles: 'Roles',
    user_management: 'User management',
    users: 'Users',
    groups: 'Groups',
    departments: 'Departments',
  },
  notification: {
    archived: 'Archived',
    unarchived: 'Unarchived',
    ungrouped: 'Ungrouped',
    regrouped: 'Group changed',
  },
  parts: {
    number: 'Article Number',
    description: 'Description',
    quantity: 'Quantity',
    empty: 'Empty',
    addContact: 'Add E-mail',
    addPart: 'Add Part',
    requestQuote: 'Request a Quote',
    toRequestQuote: 'To request a quote please set the machine contact e-mail',
    error: 'Sorry, something went wrong while requesting a quote',
    success: 'A request for a quote has been sent',
  },
  profile: {
    profile: 'Profile',
    general: {
      dealer: 'Your Dealer',
      email: 'E-mail',
      name: 'Frist Name',
      password: 'Password',
      password_2: 'Repeat Password',
      phone: 'Phone Number',
      region: 'Your Region',
      role: 'Your Role',
      save: 'Save',
      surname: 'Last Name',
      upload_picture: 'Upload Picture',
    },
    placeholders: {
      your_email: 'Your e-mail',
      your_name: 'Your name',
      your_password: 'Your password',
      your_password_2: 'Your password',
      your_phone: 'Your phone number',
      your_surname: 'Your surname',
    },
  },
  resources: {
    machines: {
      machine: 'Machine',
      name: 'Machine |||| Machines',
      fields: {
        id: 'ID',
        manufacturer: 'Category',
        category: 'Subcategory',
        model: 'Inspection Name',
        inspection_template_id: 'Inspection template',
        inspection_details: 'Inspection Details',
        label: 'Label',
        type: 'Type',
        text: 'Text',
        number: 'Number',
        date: 'Date',
        example: 'Example',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        contact: 'Contact',
        organisation: 'Organisation',
        phone: 'Phone',
        email: 'E-Mail',
        machine_details: 'Machine Details',
        header_data: 'Custom Labels',
        inspection_fields: 'Inspection Fields',
        serials: {
          allowed: 'Allowed Serial Numbers',
          serial: 'Serial Number',
          min: 'Minimum',
          max: 'Maximum',
        },
        labels: {
          allowed: 'Allowed Labels',
          label: 'Label',
        },
      },
      deletion: {
        confirmation: 'Are you sure you want to delete machine number %{id}?',
        hasInspection:
          'Machine number %{id} cannot be deleted because it is used by the following Inspection(s): %{ids}',
      },
    },
    'inspection-templates': {
      deletion: {
        hasInspection:
          'Template number %{id} cannot be deleted because it is used by the following Inspection(s): %{inspections}',
        hasMachine:
          'Template number %{id} cannot be deleted because it is used by the following Machine(s): %{machines}',
        hasBoth:
          'Template number %{id} cannot be deleted because it is used by the following Inspection(s): %{inspections} and the following Machine(s): %{machines}',
        confirmation: 'Are you sure you want to delete template number %{id}?',
      },
      name: 'Inspection template |||| Inspection templates',
      fields: {
        company: 'Company',
        config: 'Module',
        createdAt: 'Created at',
        helper_title: 'Helper',
        helper: 'Helper',
        helper_type: {
          text: 'Text',
          link: 'Link',
          image: 'Image',
        },
        helper_text: 'Helper text',
        icon: 'Icon',
        id: 'ID',
        label: 'Label',
        name: 'Name',
        region: 'Region',
        spec: 'Specifications',
        sub_spec: 'Sub Specification',
        title: 'New Specification',
        type: 'Type',
        updatedAt: 'Updated at',
        version: 'Version',
        sub_type: 'Sub Type',
        sub_label: 'Sub Label',
      },
    },
    inspections: {
      name: 'Inspection report |||| Inspection reports',
      fields: {
        id: 'ID',
        inspection_template_id: 'Inspection template',
        serial: 'Serial No.',
        inspector: 'Inspector',
        inspection_location: 'Inspection Location',
        operating_hours: 'Operating hours',
        inspection_date: 'Inspection Date',
        machine_id: 'Machine',
        assignee: 'Assignee',
        inspection_data: 'Inspections Data',
        region: 'Region',
        company: 'Company',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        label: 'Label',
        lastUpdate: 'Saved',
      },
    },
    users: {
      name: 'User |||| Users',
      fields: {
        company: 'Company',
        email: 'Email',
        id: 'ID',
        region: 'Region',
        role: 'Role',
      },
    },
    groups: {
      name: 'Group |||| Groups',
      fields: {
        id: 'ID',
        serial: 'Serial No.',
      },
      group: 'Group',
    },
    'qr-codes': {
      fields: {
        build_year: 'Build year',
        category: 'Category',
        model: 'Model',
        serial: 'Serial',
      },
      qr: {
        download: 'Download',
      },
    },
    departments: {
      department: 'Department',
      default: 'Default',
    },
  },
  role: {
    centraladmin: 'Central-Admin',
    dealeradmin: 'Dealer-Admin',
    dealeruser: 'Dealer-User',
    devadmin: 'Developer-Admin',
    norole: 'No role',
    regionadmin: 'Regional-Admin',
    regionuser: 'Regional-User',
    departmentadmin: 'Department-Admin',
    departmentuser: 'Department-User',
  },
  specification: {
    appearance: 'Details',
    function: 'Function',
    gallery_codes: 'Gallery / Error Codes',
    nodata: 'No data',
    not_available: 'Not available',
    not_complete: 'Not checked',
    no_damage: 'No damage',
    not_okay: 'Damages',
    okay: 'OK',
    parts_needed: 'Parts Needed',
    status: 'Status',
    comment: 'Comment',
    tutorial_text: 'Tutorial Text',
  },
  status: {
    code: 'Item Code',
    note: 'Note',
    url: 'Url',
  },
  pdf: {
    cumulative_score: 'Cumulative Score',
    includeImages: 'Include Images',
    yes: 'Yes',
    no: 'No',
    comment: 'Comment',
    quality_check: 'Quality Check',
    serial: 'Serienummer',
    inspection_date: 'Inspection Date',
    inspection_data: 'Inspection Data',
    inspection_location: 'Inspection Location',
    operating_hours: 'Operating hours',
    inspection_id: 'Inspection ID',
    machine_id: 'Machine ID',
    template_name: 'Template Name',
    template_id: 'Template ID',
    id: 'ID',
    machine_location: 'Machine Location',
    operating_hours_return: 'Operational Hours Return',
    operating_hours_service: 'Operational Hours Service',
    owner: 'Owner',
    return_date: 'Return Date',
    salesperson: 'Salesperson',
    inspection_details: 'Inspection details',
    checked: 'Checked',
    data_missing: 'Data Missing',
    damages: 'Damages',
    skipped: 'Skipped',
    inspection_pictures: 'Inspection Pictures',
    loading: 'Loading...',
    report: 'PDF',
    not_available: 'Not available',
    no_damage: 'No damage',
    download: 'Download PDF',
    close: 'Close',
    imageQuality: 'Image quality',
    low: 'Low',
    high: 'High',
    medium: 'Medium',
    pdf_download_error: 'Downloading PDF file failed',
    page: 'Page',
    of: 'of',
  },
  theme: {
    theme: 'Theme',
    random: 'Random',
    full_random: 'Full Random',
    custom: 'Custom Theme',
    main: 'Main Color',
    background: 'Background Color',
    apply: 'Apply',
    clear: 'Clear History',
    dark: 'Dark',
    light: 'Light',
    mono: 'Monochrome',
    history: 'History',
  },
  csv_import: {
    dropzone: {
      title: 'CSV Dropzone',
      subtitle: 'Please upload a CSV file to populate the template.',
      csv_reader: 'Drop CSV file here or click to upload.',
    },
    notification: {
      populated: 'Populated',
      imported: 'Imported %{file}',
    },
    empty: 'Empty',
    tooltip: 'Must be a ".csv" file',
    label: 'Import checklists',
    confirm: 'Confirm',
    cancel: 'Cancel',
    rows_per_page: 'Rows per page',
    of: 'of',
  },
  undefined: '-',
};

export default en;
