import { Button, makeStyles, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import PLACEHOLDER_IMAGE from '../../../../img/no_picture_placeholder.png';
import { ImageButton } from './image-button';
import { ImageDialog } from './image-dialog';
import { TutorialInputProps, ImagesInputValue } from '../../types';

const useStyles = makeStyles((theme) => ({
  images: {
    display: 'column',
    gridTemplateColumns: 'repeat(auto-fill, 10rem)',
    gap: theme.spacing(2, 2),
  },
  addLink: {
    borderRadius: theme.spacing(0.5),
    height: '10rem',
    width: '10rem',
    borderWidth: '1px',
    borderColor: theme.palette.primary.dark,
    borderStyle: 'dashed',
  },
  text: {
    width: '100%',
    borderWidth: '1px',
    borderColor: theme.palette.primary.dark,
  },
}));

interface Current {
  index: number;
  value: ImagesInputValue;
}

export const TutorialInput = (props: TutorialInputProps) => {
  const {
    input: { onChange, value },
  } = useInput(props as any);

  const updateValue = (updatedValue: {
    [key: string]: string | ImagesInputValue[];
  }) => {
    onChange({ ...value, ...updatedValue });
  };

  const classes = useStyles();
  const translate = useTranslate();

  const [placeholders, setPlaceholders] = useState<(string | undefined)[]>([]);
  const [current, setCurrent] = useState<Current | undefined>();

  useEffect(() => {
    onChange({
      ...value,
      tutorial: value.tutorial ?? '',
      images: value.images ?? [{}],
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newPlaceholders = value.images?.map(({ placeholder }) =>
      placeholder ? URL.createObjectURL(placeholder) : PLACEHOLDER_IMAGE,
    );
    setPlaceholders(newPlaceholders);
  }, [setPlaceholders, value]);

  const handleAdd = () => {
    const images: ImagesInputValue[] = [...(value.images || []), {}];
    updateValue({ images });
  };

  const handleEditClick = (index: number, selectedValue: ImagesInputValue) => {
    setCurrent({ index, value: selectedValue });
  };

  const handleDeleteClick = (index: number) => {
    const images = [...value.images];
    images.splice(index, 1);
    updateValue({ images });
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const tutorial = event.target.value;
    updateValue({ tutorial });
  };

  const saveAndClose = (current?: Current) => {
    if (current) {
      const images = [...value.images];
      images[current.index] = current.value;
      updateValue({ images });
    }
    setCurrent(undefined);
  };

  const makeSrcFromIndex = (index: number) =>
    placeholders && placeholders[index] !== ''
      ? placeholders[index]
      : PLACEHOLDER_IMAGE;

  const makeAltFromImage = (image: ImagesInputValue) =>
    `${image.title ? image.title : translate('images.no_title')} - ${
      image.subtitle ? image.subtitle : translate('images.no_subtitle')
    }`;
  return (
    <div>
      <TextField
        multiline
        className={classes.text}
        variant="outlined"
        defaultValue={value.tutorial}
        value={value.tutorial}
        onChange={handleTextChange}
        label={translate('specification.tutorial_text')}
      />
      <div className={classes.images}>
        {value.images?.map((image: ImagesInputValue, index: number) => (
          <ImageButton
            handleEdit={() => handleEditClick(index, image)}
            handleDelete={(event) => {
              event.stopPropagation();
              handleDeleteClick(index);
            }}
            alt={makeAltFromImage(image)}
            src={makeSrcFromIndex(index)}
          />
        ))}
        <Button className={classes.addLink} onClick={handleAdd}>
          <AddIcon />
        </Button>
      </div>
      <ImageDialog initial={current} saveAndClose={saveAndClose} />
    </div>
  );
};
