import { Button } from 'ra-ui-materialui';
import React, { useState } from 'react';
import { useMutation, useRefresh, useTranslate } from 'react-admin';
import { DeleteDialog } from '../../../components';
import { MachinesWithInspection } from '../utils';

interface DeleteMachineButtonProps {
  machinesWithInspections: MachinesWithInspection;
  record: { id: number };
}

export const DeleteMachineButton = ({
  machinesWithInspections,
  record: { id },
}: DeleteMachineButtonProps) => {
  const [deleteMachine] = useMutation({
    type: 'delete',
    resource: 'machines',
    payload: { id },
  });
  const refresh = useRefresh();

  const translate = useTranslate();

  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);

  const handleDelete = async () => {
    await deleteMachine();
    toggleOpen();
    refresh();
  };

  const deletePossible = !Object.keys(machinesWithInspections).includes(
    id?.toString(),
  );

  const textHandle = deletePossible
    ? 'resources.machines.deletion.confirmation'
    : 'resources.machines.deletion.hasInspection';

  const textVariables = {
    id: id.toString(),
    ids: machinesWithInspections[id]?.join(', '),
  };
  return (
    <>
      <Button label="ra.action.delete" onClick={toggleOpen} />
      <DeleteDialog
        hideDelete={!deletePossible}
        handleClose={toggleOpen}
        handleDelete={handleDelete}
        open={open}
        text={translate(textHandle, textVariables)}
      />
    </>
  );
};
