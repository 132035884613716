import { Typography } from '@material-ui/core';
import React from 'react';

export const FreeInput = ({ freeInputPairs }: { freeInputPairs?:{title: string, value: string}[] | null }) =>
freeInputPairs ? (
    <>
      {freeInputPairs.map((freeInputPair) => (
        <Typography variant="subtitle2">{freeInputPair.title} : {freeInputPair.value}</Typography>
      ))}
    </>
  ) : null;