import React, { cloneElement, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/Save';
import classnames from 'classnames';
import {
  useTranslate,
  useNotify,
  useSaveContext,
  useFormContext,
} from 'ra-core';

import { sanitizeButtonRestProps } from 'ra-ui-materialui/esm/button/Button';
import { SaveButtonProps, Toolbar } from 'react-admin';
import { useFormState } from 'react-final-form';
import { SubmitTriggerContext } from './create';

const RESOURCE_PREFIX = 'resources.inspection-templates.fields';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: 'RaSaveButton' },
);

const defaultIcon = <ContentSave />;

const SaveButton = (props: SaveButtonProps) => {
  const {
    className,
    classes: classesOverride,
    invalid,
    label = 'ra.action.save',
    disabled,
    redirect,
    saving,
    submitOnEnter,
    variant = 'contained',
    icon = defaultIcon,
    onClick,
    handleSubmitWithRedirect,
    onSave,
    onSuccess,
    onFailure,
    transform,
    ...rest
  } = props;
  const classes = useStyles(props);
  const notify = useNotify();
  const translate = useTranslate();
  const formContext = useFormContext();
  const { setOnSuccess, setOnFailure, setTransform } = useSaveContext(props);

  const { errors: formErrors } = useFormState();

  const errors = [
    formErrors.name && translate(`${RESOURCE_PREFIX}.name`),
    formErrors.spec?.['FINAL_FORM/array-error'] &&
      translate(`${RESOURCE_PREFIX}.spec`),
    ...(formErrors.spec ?? []).map(
      (e, i) => e && `${translate(`${RESOURCE_PREFIX}.spec`)} ${i + 1}`,
    ),
  ].filter((e) => e);

  const handleClick = (event) => {
    // deprecated: use onSuccess and transform instead of onSave
    if (typeof onSave === 'function') {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(
          '<SaveButton onSave> prop is deprecated, use the onSuccess prop instead.',
        );
        if (!formContext || !formContext.setOnSave) {
          console.warn(
            'Using <SaveButton> outside a FormContext is deprecated.',
          );
        }
      }
      if (formContext && formContext.setOnSave) {
        formContext.setOnSave(onSave);
      }
    } else {
      if (
        process.env.NODE_ENV !== 'production' &&
        (!formContext || !formContext.setOnSave)
      ) {
        console.warn('Using <SaveButton> outside a FormContext is deprecated.');
      }

      if (formContext && formContext.setOnSave) {
        // we reset to the Form default save function
        formContext.setOnSave();
      }
    }
    if (onSuccess) {
      setOnSuccess(onSuccess);
    }
    if (onFailure) {
      setOnFailure(onFailure);
    }
    if (transform) {
      setTransform(transform);
    }
    if (saving) {
      // prevent double submission
      event.preventDefault();
    } else {
      if (invalid) {
        notify(
          `${translate('errors.inspection-templates.invalid')} ${errors.join(
            ', ',
          )}`,
          { type: 'warning' },
        );
      }
      // always submit form explicitly regardless of button type
      if (event) {
        event.preventDefault();
      }
      handleSubmitWithRedirect(redirect);
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const type = submitOnEnter ? 'submit' : 'button';
  const displayedLabel = label && translate(label, { _: label });
  return (
    <Button
      className={classnames(classes.button, className)}
      variant={variant}
      type={type}
      onClick={handleClick}
      color={saving ? 'default' : 'primary'}
      aria-label={displayedLabel}
      disabled={disabled}
      {...sanitizeButtonRestProps(rest)}
    >
      {saving ? (
        <CircularProgress
          size={18}
          thickness={2}
          className={classes.leftIcon}
        />
      ) : (
        cloneElement(icon, {
          className: classnames(classes.leftIcon, classes.icon),
        })
      )}
      {displayedLabel}
    </Button>
  );
};

export const CreateToolbar = ({ ...rest }) => {
  const { trigger, setTrigger } = useContext(SubmitTriggerContext);

  return (
    <Toolbar {...rest}>
      <SaveButton onClick={() => setTrigger(!trigger)} />
    </Toolbar>
  );
};
