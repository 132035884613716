import { Button, makeStyles, MenuItem, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { required, TextInput, useInput, useTranslate } from 'react-admin';
import { dashboardConfig } from '../../../../config';
import {
  StatusInputProps,
  StatusInputType,
  StatusInputValue,
} from '../../types';
import {
  addHttp,
  addHttpCondition,
  removeHttp,
  removeHttpCondition,
  StatusInputTypesEnum,
} from './utils';

const useStyles = makeStyles((theme) => ({
  addLink: {
    borderRadius: theme.spacing(0.5),
    height: '3rem',
    width: '3rem',
    borderWidth: '1px',
    borderColor: theme.palette.primary.dark,
    borderStyle: 'dashed',
  },
  textContainer: {
    flexDirection: 'row',
  },
  text: {
    width: '70%',
  },
  typeDropdown: {
    padding: 12,
    width: '15%',
  },
}));

export const StatusInput = ({ source }: StatusInputProps) => {
  const {
    input: { onChange, value },
  } = useInput({ source });

  const classes = useStyles();
  const translate = useTranslate();
  const [notes, setNotes] = useState<StatusInputValue[]>([]);

  useEffect(() => {
    setNotes(value.status ?? []);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const composeNoteFromIndex = useCallback(
    (index: number) => {
      const noteData = Object.entries(notes[index]);
      const [noteType, noteValue]: [string, unknown] = noteData[0] ?? [
        StatusInputTypesEnum.Note,
        '',
      ];
      return [noteType, noteValue] as [StatusInputType, string];
    },
    [notes],
  );

  const setSpecificNote = useCallback(
    ({
      noteType,
      noteValue,
      index,
    }: {
      noteType: StatusInputType;
      noteValue: string;
      index: number;
    }) => {
      const newNote: StatusInputValue = {};
      newNote[noteType] = noteValue;
      const allNotes = [...notes];
      allNotes[index] = newNote;
      setNotes(allNotes);
    },
    [notes],
  );

  useEffect(() => {
    notes.forEach((_, index) => {
      const [noteType, noteValue] = composeNoteFromIndex(index);

      if (addHttpCondition(noteType, noteValue)) {
        setSpecificNote({
          noteType,
          noteValue: addHttp(noteValue),
          index,
        });
      } else if (removeHttpCondition(noteType, noteValue)) {
        setSpecificNote({
          noteType,
          noteValue: removeHttp(noteValue),
          index,
        });
      }
    });
  }, [notes, composeNoteFromIndex, setSpecificNote]);

  useEffect(() => {
    onChange({ ...value, status: notes });
  }, [notes]); // eslint-disable-line react-hooks/exhaustive-deps

  const addNote = () => {
    const newNotes = [...notes];
    newNotes.push({});
    setNotes(newNotes);
  };

  const deleteNote = (index: number) => {
    const newNotes = [...notes];
    newNotes.splice(index, 1);
    setNotes(newNotes);
  };

  const handleTextChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const noteType = composeNoteFromIndex(index)[0];
    setSpecificNote({
      noteType,
      noteValue: event.target.value as string,
      index,
    });
  };

  const handleTypeChange = (
    event: ChangeEvent<{ value: unknown }>,
    index: number,
  ) => {
    const noteValue = composeNoteFromIndex(index)[1];
    setSpecificNote({
      noteType: event.target.value as StatusInputType,
      noteValue,
      index,
    });
  };

  return dashboardConfig.statusInfoFlag ? (
    <>
      {notes?.map((note: StatusInputValue, index: number) => {
        const noteInfo = Object.entries(note);
        const [type, value] = noteInfo[0] ?? ['', ''];
        return (
          <div className={classes.textContainer}>
            <Button
              className={classes.addLink}
              onClick={() => deleteNote(index)}
            >
              <CloseIcon />
            </Button>
            <Select
              className={classes.typeDropdown}
              onChange={(event: ChangeEvent<{ value: unknown }>) =>
                handleTypeChange(event, index)
              }
              value={type}
            >
              {Object.values(StatusInputTypesEnum).map((type) => (
                <MenuItem value={type}>{translate(`status.${type}`)}</MenuItem>
              ))}
            </Select>
            <TextInput
              source={`${source}.status[${index}].${type}`}
              label={type ?? ''}
              className={classes.text}
              variant="outlined"
              defaultValue={value}
              value={value}
              validate={required()}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleTextChange(event, index)
              }
            />
          </div>
        );
      })}
      <Button className={classes.addLink} onClick={addNote}>
        <AddIcon />
      </Button>
    </>
  ) : null;
};
