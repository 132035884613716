import { API_URL } from 'config';
import { useNotify } from 'ra-core';
import { useState } from 'react';

const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const useResendPassword = () => {
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const mutateAsync = async (email: string) => {
    if (!emailRegex.test(email)) {
      notify('auth.invalid_email', 'error');
      return;
    }
    try {
      setIsLoading(true);
      setIsSuccess(false);
      const response = await fetch(`${API_URL}/auth/resend-password`, {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        setIsSuccess(true);
        notify('auth.password_resent', 'info');
      } else {
        notify('auth.resend_password_error', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { mutateAsync, isLoading, isSuccess };
};
