import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@material-ui/lab/TreeView';
import get from 'lodash/get';
import { Record } from 'ra-core';
import * as React from 'react';
import { SpecsShow } from './specs';

interface DetailsShowProps {
  className?: string;
  record?: Record;
  source: string;
}

export const DetailsShow: React.FC<DetailsShowProps> = React.memo<
  DetailsShowProps
>(({ className, source, record, ...rest }) => (
  <TreeView
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpandIcon={<ChevronRightIcon />}
    {...rest}
  >
    <SpecsShow
      specs={get(record, source, [])}
      id={(record?.id as number) ?? 0}
    />
  </TreeView>
));
