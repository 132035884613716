import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface TableProps {
  data: string[][];
  cols: string[];
}

interface PaginationValues {
  from: number;
  to: number;
  count: number;
}

export const DenseTable = ({ data, cols }: TableProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const makeDisplayedRowsString = ({ from, to, count }: PaginationValues) =>
    `${from}-${to} ${translate('csv_import.of')} ${count}`;

  const paginatedData =
    rowsPerPage > 0
      ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data;
  const rowsPerPageOptions = [5, 10, 25, { label: 'All', value: -1 }];

  let key = 0;

  return paginatedData.length > 0 ? (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {cols.map((col) => (
              <TableCell key={key++} align="left">
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row: string[]) => (
            <TableRow key={key++}>
              {row.map((cell: string) => (
                <TableCell key={key++} align="left">
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              colSpan={cols.length}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={translate('csv_import.rows_per_page')}
              labelDisplayedRows={makeDisplayedRowsString}
              onPageChange={() => {}}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  ) : null;
};
