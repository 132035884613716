import { Auth } from 'aws-amplify';

export const downloadPDF = async (url) => {
  const session = await Auth.currentSession();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch PDF');
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};
