import { createSvgIcon } from '@material-ui/core';
import React from 'react';

import {
  mdiBookOpenBlankVariant,
  mdiScrewMachineFlatTop,
  mdiCarDoor,
  mdiTrashCan,
  mdiCalendar,
  mdiFlash,
  mdiEngine,
  mdiRadiator,
  mdiFilter,
  mdiTimer,
  mdiWaterPump,
  mdiAngleObtuse,
  mdiCalendarArrowLeft,
  mdiCupWater,
  mdiTractor,
  mdiBuddhism,
  mdiArchive,
  mdiClipboardListOutline,
  mdiCalendarArrowRight,
  mdiTune,
  mdiTools,
  mdiRake,
  mdiFireTruck,
  mdiGauge,
  mdiRecycleVariant,
  mdiWrench,
  mdiGarageVariant,
  mdiQrcode,
  mdiNumeric,
  mdiSteering,
  mdiCogs,
  mdiCarTireAlert,
  mdiCarBrakeAlert,
  mdiEyeOutline,
  mdiCalendarMultiple,
  mdiBeakerCheck,
  mdiTextBoxCheckOutline,
  mdiTractorVariant,
  mdiCheckDecagram,
  mdiBarcode,
  mdiBarcodeScan,
  mdiCardAccountDetails,
  mdiAccountStar,
  mdiShape,
  mdiChevronDownCircleOutline,
  mdiChevronRightCircleOutline,
} from '@mdi/js';

export const accessories = createSvgIcon(
  <path d={mdiBookOpenBlankVariant} />,
  'accessories',
);
export const auger = createSvgIcon(
  <path d={mdiScrewMachineFlatTop} />,
  'auger',
);
export const cabin = createSvgIcon(<path d={mdiCarDoor} />, 'cabin');
export const compactor = createSvgIcon(<path d={mdiTrashCan} />, 'compactor');
export const date = createSvgIcon(<path d={mdiCalendar} />, 'date');
export const electric = createSvgIcon(<path d={mdiFlash} />, 'electric');
export const engine = createSvgIcon(<path d={mdiEngine} />, 'engine');
export const heating = createSvgIcon(<path d={mdiRadiator} />, 'heating');
export const hopper = createSvgIcon(<path d={mdiFilter} />, 'hopper');
export const hours = createSvgIcon(<path d={mdiTimer} />, 'hours');
export const hydraulic = createSvgIcon(<path d={mdiWaterPump} />, 'hydraulic');
export const joint = createSvgIcon(<path d={mdiAngleObtuse} />, 'joint');
export const last_service = createSvgIcon(
  <path d={mdiCalendarArrowLeft} />,
  'last_service',
);
export const liquids = createSvgIcon(<path d={mdiCupWater} />, 'liquids');
export const machine = createSvgIcon(<path d={mdiTractor} />, 'machine');
export const milling = createSvgIcon(<path d={mdiBuddhism} />, 'milling');
export const next_service = createSvgIcon(
  <path d={mdiCalendarArrowRight} />,
  'next_service',
);
export const options = createSvgIcon(<path d={mdiTune} />, 'options');
export const parts = createSvgIcon(<path d={mdiTools} />, 'parts');
export const paver = createSvgIcon(<path d={mdiRake} />, 'paver');
export const planer = createSvgIcon(<path d={mdiFireTruck} />, 'planer');
export const pressure = createSvgIcon(<path d={mdiGauge} />, 'pressure');
export const recycler = createSvgIcon(
  <path d={mdiRecycleVariant} />,
  'recycler',
);
export const repairs = createSvgIcon(<path d={mdiWrench} />, 'repairs');
export const screed = createSvgIcon(<path d={mdiGarageVariant} />, 'screed');
export const serial_no = createSvgIcon(<path d={mdiNumeric} />, 'serial_no');
export const steering = createSvgIcon(<path d={mdiSteering} />, 'steering');
export const transfer_box = createSvgIcon(<path d={mdiCogs} />, 'transfer_box');
export const tyres = createSvgIcon(<path d={mdiCarTireAlert} />, 'tyres');
export const vibration = createSvgIcon(
  <path d={mdiCarBrakeAlert} />,
  'vibration',
);
export const visual = createSvgIcon(<path d={mdiEyeOutline} />, 'visual');
export const year = createSvgIcon(<path d={mdiCalendarMultiple} />, 'year');
export const qrCode = createSvgIcon(<path d={mdiQrcode} />, 'qrCode');
export const beakerCheck = createSvgIcon(
  <path d={mdiBeakerCheck} />,
  'factCheck',
);
export const textBoxCheckOutline = createSvgIcon(
  <path d={mdiTextBoxCheckOutline} />,
  'textBoxOutline',
);
export const archive = createSvgIcon(<path d={mdiArchive} />, 'archive');

export const checkDecagram = createSvgIcon(
  <path d={mdiCheckDecagram} />,
  'checkDecagram',
);

export const clipboardListOutline = createSvgIcon(
  <path d={mdiClipboardListOutline} />,
  'clipboardListOutline',
);

export const tractorVariant = createSvgIcon(
  <path d={mdiTractorVariant} />,
  'tractorVariant',
);

export const barcode = createSvgIcon(<path d={mdiBarcode} />, 'barcode');
export const idCard = createSvgIcon(
  <path d={mdiCardAccountDetails} />,
  'idCard',
);

export const barcodeScan = createSvgIcon(
  <path d={mdiBarcodeScan} />,
  'barcodeScan',
);

export const accountStar = createSvgIcon(
  <path d={mdiAccountStar} />,
  'accountStar',
);

export const shape = createSvgIcon(<path d={mdiShape}/>, 'shape');


export const collapseRight = createSvgIcon(<path d={mdiChevronRightCircleOutline}/>, 'collapseRight');
export const collapseDown = createSvgIcon(<path d={mdiChevronDownCircleOutline}/>, 'collapseDown');

export {
  brakes,
  chain,
  crank,
  derailleur,
  frame,
  pedal,
  saddle,
  wheel,
} from './icons/';
