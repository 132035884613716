import { List, makeStyles } from '@material-ui/core';
import { Done, Undo } from '@material-ui/icons';
import { ParseResult } from 'papaparse';
import React from 'react';
import { useTranslate } from 'react-admin';
import { CSVReader } from 'react-papaparse';
import {
  SharedDialogButton,
  SharedDialogWrapper,
} from '../../../../components';

const useStyles = makeStyles((theme: any) => ({
  csvReader: {
    backgroundColor: theme.palette.primary.constractText,
    color: theme.palette.primary.main,
  },
}));

interface ImportCsvDropzoneDialogProps {
  title: string;
  subTitle: string;
  open: boolean;
  disableConfirm: boolean;
  handleClose: () => void;
  handleOnDrop: (data: ParseResult<string>[], file?: File) => Promise<void>;
  handleOnRemoveFile: () => void;
  handleOnConfirm: () => void;
}

export const ImportCsvDropzoneDialog = ({
  title,
  subTitle,
  open,
  disableConfirm,
  handleClose,
  handleOnDrop,
  handleOnRemoveFile,
  handleOnConfirm,
}: ImportCsvDropzoneDialogProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <SharedDialogWrapper
      title={title}
      subTitle={subTitle}
      open={open}
      handleClose={handleClose}
    >
      <div className={classes.csvReader}>
        <CSVReader
          onDrop={handleOnDrop}
          addRemoveButton
          onRemoveFile={handleOnRemoveFile}
        >
          <span>{translate('csv_import.dropzone.csv_reader')}</span>
        </CSVReader>
      </div>

      <List>
        <SharedDialogButton
          onClick={handleOnConfirm}
          icon={<Done />}
          label={translate('csv_import.confirm')}
          disabled={disableConfirm}
        />
        <SharedDialogButton
          onClick={handleClose}
          icon={<Undo />}
          label={translate('csv_import.cancel')}
        />
      </List>
    </SharedDialogWrapper>
  );
};
