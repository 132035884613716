import { Dialog, Typography } from '@material-ui/core';
import React from 'react';
import { Config, DataInstance } from '../../../types.inspections';
import { Checkboxes } from './checkboxes';
import { ErrorCodes } from './error-codes';
import { Media } from './media';
import { Parts } from './parts';
import { Status } from './status';
import { QRCodes } from './qr-codes';
import { FreeInput } from './free-input';

interface PopupProps {
  handleClose: () => void;
  open: boolean;
  paperClass: string;
  title: string;
  taskData: DataInstance;
  id: number;
  config: Config;
}

export const Popup = ({
  handleClose,
  open,
  paperClass,
  title,
  taskData: {
    comment,
    rating,
    images,
    damages,
    videos,
    error_codes: errorCodes,
    parts,
    checkboxes,
    qr_codes: qrCodes,
    free_input: freeInput,
  },
  id,
  config,
}: PopupProps) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="simple-dialog-title"
    open={open}
    PaperProps={{ className: paperClass }}
  >
    <div className="padded-container">
      <Typography gutterBottom variant="h5" component="h5">
        {title}
        {rating && ` : ${rating}/5`}
      </Typography>
      <p>{comment}</p>
      {config?.status && <Status status={config?.status} />}
      <Media id={id} images={images} damages={damages} videos={videos} />
      <ErrorCodes errorCodes={errorCodes} />
      <Parts id={id} parts={parts} />
      <Checkboxes checkboxes={checkboxes} />
      <QRCodes qrCodes={qrCodes} />
      <FreeInput
        freeInputPairs={config.free_input?.map((title, index) => ({
          title,
          value: freeInput?.[index] || '-',
        }))}
      />
    </div>
  </Dialog>
);
