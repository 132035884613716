import { Divider, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';

export const Assignee = ({
  assigneeEmails,
}: {
  assigneeEmails: (string | undefined)[];
}) => {
  const translate = useTranslate();
  return (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className="special-typography"
      >
        <span className="typo-strong">
          {translate('resources.inspections.fields.assignee')}:
        </span>
        <div className="assignee">
          {assigneeEmails?.map((singleAssignee: string | undefined) =>
            singleAssignee ? (
              <span className="typo-light">{singleAssignee}</span>
            ) : null,
          )}
        </div>
      </Typography>
      <Divider className="typography-divider" />
    </>
  );
};
