import {
  Button,
  TableCell,
  TableRow as EmptyTableRow,
} from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
interface StatusSpan {
  type: string;
  key?: string | number | null;
  ref?: string | number | null;
  props: {
    className: string;
    children: string;
  };
}

interface TableRowProps {
  label: string;
  onClick: () => void;
  index?: number;
  displayButton: boolean;
  itemStatus: StatusSpan;
}

export const TableRow = ({
  label,
  onClick,
  index,
  displayButton,
  itemStatus,
}: TableRowProps) => {
  const translate = useTranslate();
  return (
    <EmptyTableRow key={`${label}-${index}`}>
      <TableCell
        className={'table-cell'}
        component="th"
        scope="row"
        align="left"
      >
        {label}
      </TableCell>
      <TableCell className={'table-cell'} align="center">
        {itemStatus}
      </TableCell>
      <TableCell className={'table-cell'} align="right">
        {displayButton ? (
          <Button onClick={onClick} variant="contained">
            {translate('ra.action.show')}
          </Button>
        ) : (
          <strong>-</strong>
        )}
      </TableCell>
    </EmptyTableRow>
  );
};
