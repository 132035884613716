import { Link, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
import { StatusConfig } from '../../../types.inspections';

interface StatusProps {
  status: StatusConfig[] | undefined;
}

export const Status = ({ status }: StatusProps) => {
  const translate = useTranslate();
  return (
    <>
      {status?.map((statusNote: StatusConfig) => {
        const [type, value]: [string, unknown] = Object.entries(statusNote)[0];
        const newValue = value as string;
        return (
          <Typography variant="subtitle2">
            {'\u00A0 \u00A0'}- {translate(`status.${type}`)}:{'\u00A0'}
            {type === 'url' ? (
              <Link target="_blank" rel="noopener" href={newValue}>
                {newValue}
              </Link>
            ) : (
              newValue
            )}
          </Typography>
        );
      })}
    </>
  );
};
