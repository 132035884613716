import { Theme, createTheme, ThemeOptions } from '@material-ui/core/styles';

export type ThemeMode = 'LIGHT' | 'DARK';

const makeThemeFromTemplate = (template: ThemeOptions): Theme => {
  return createTheme({
    ...template,
    shape: {
      borderRadius: 10,
    },
    subtleShadow: '0px 1.5px 7.5px rgba(0,0,0,0.1)',
    sidebar: {
      width: 300,
      closedWidth: 80,
    },
  });
};

export const dashboardThemes: { [key in ThemeMode]: Theme } = {
  LIGHT: makeThemeFromTemplate({
    palette: {
      type: 'light',
      primary: {
        main: '#C22',
        contrastText: '#fff',
      },
      secondary: {
        main: '#fff',
        contrastText: '#000',
      },
      text: {
        primary: '#000',
      },
      background: {
        default: '#ddd',
      },
    },
    inputAutoFillColor: '#e8e8e8',
    inputAutoFillTextColor: '#000',
  }),
  DARK: makeThemeFromTemplate({
    palette: {
      type: 'dark',
      primary: {
        main: '#C22',
        contrastText: '#fff',
      },
      secondary: {
        main: '#222',
        contrastText: '#fff',
      },
      text: {
        primary: '#fff',
      },
      background: {
        default: '#171717',
      },
    },
    inputAutoFillColor: '#363636',
    inputAutoFillTextColor: '#fff',
  }),
};

declare module '@material-ui/core/styles' {
  interface Theme {
    subtleShadow?: string;
    sidebar?: { width: number; closedWidth: number };
    inputAutoFillColor?: string;
    inputAutoFillTextColor?: string;
  }
  interface ThemeOptions {
    subtleShadow?: string;
    sidebar?: { width: number; closedWidth: number };
    inputAutoFillColor?: string;
    inputAutoFillTextColor?: string;
  }
}
