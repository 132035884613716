import { Button } from 'ra-ui-materialui';
import React from 'react';
import { ListButton, TopToolbar } from 'react-admin';
import { Link } from 'react-router-dom';

export const MachinesToolbar = ({
  basePath,
  type,
  id,
}: {
  basePath: string | undefined;
  type?: string;
  id?: string;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    {type !== 'create' && (
      <>
        <Button
          component={Link}
          to={{ pathname: `${basePath}/create` }}
          label="ra.action.create"
          onClick={(event: any) => event.stopPropagation()}
        />
        {type !== 'edit' && (
          <Button
            component={Link}
            to={{ pathname: `${basePath}/${id}` }}
            label="ra.action.edit"
            onClick={(event: any) => event.stopPropagation()}
          />
        )}
      </>
    )}
  </TopToolbar>
);
